import classNames from 'classnames';
import React, { useState } from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

export type ControlledTextInputType =
  | 'text'
  | 'email'
  | 'number'
  | 'hidden'
  | 'date'
  | 'datetime-local'
  | 'image'
  | 'month'
  | 'password'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week';

export type ControlledTextInputModeProps =
  | 'none'
  | 'text'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | 'search';

export type ControlledTextInputDirection = 'rtl' | 'ltr';
export const VALID_VARIANTS = ['default'] as const;
type ControlledTextInputVariantTuple = typeof VALID_VARIANTS;
type ControlledTextInputVariant = ControlledTextInputVariantTuple[number];

type variantMapStyles<T> = {
  [x in ControlledTextInputVariant]: T;
};

export interface ControlledTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  disabled?: boolean;
  variant?: ControlledTextInputVariant;
  numberOfLines?: number;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  scrollEnabled?: boolean;
  maxLength?: number;
  placeholder?: string;
  type?: ControlledTextInputType;
  inputMode?: ControlledTextInputModeProps;
  direction?: ControlledTextInputDirection;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  hideErrorMessage?: boolean;
  isRequired?: boolean;
  helperText?: string;
  disableCopyPaste?: boolean;
  isPassword?: boolean;
  autoComplete?: string | undefined;
}

const ControlledTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  disabled = false,
  variant = 'default',
  numberOfLines,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  scrollEnabled = true,
  maxLength,
  placeholder,
  label,
  subLabel,
  type = 'text',
  inputMode = 'text',
  direction = 'ltr',
  readOnly,
  noBorder,
  hideErrorMessage = false,
  helperText,
  isRequired = false,
  disableCopyPaste = false,
  isPassword,
  autoComplete,
  ...controllerProps
}: ControlledTextInputProps<TFieldValues, TName>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    field: { value, onChange, name, onBlur },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  const isShowLabel = !!label && (isFocused || !!value);

  const placeholderText = isFocused ? '' : placeholder;

  const variantBorderStylesMap: variantMapStyles<string> = {
    default: classNames([
      'flex flex-row items-center justify-start overflow-hidden border rounded-lg',
      isFocused ? 'border-black' : 'border-silver',
    ]),
  };

  const variantViewStylesMap: variantMapStyles<string> = {
    default: classNames([
      'flex flex-row justify-start items-center w-full',
      readOnly || disabled ? 'bg-whitesmoke' : 'bg-transparent',
    ]),
  };

  const textInputStylesMap: variantMapStyles<string> = {
    default:
      'flex items-center justify-center flex-grow font-inter-regular text-base px-2 py-3 leading-4 text-black bg-transparent appearance-none border-none focus:outline-none focus:ring-0',
  };

  const renderIconOrEndAdornment = (): React.ReactElement => {
    if (isPassword || ((isFocused || !value) && endAdornment)) {
      return <div className='pr-2'>{endAdornment}</div>;
    }

    return <div />;
  };

  const handleOnBlur = () => {
    if (typeof value === 'string') {
      onChange(value.trim());
    }
    onBlur();
    setIsFocused(false);
  };

  return (
    <div>
      {isShowLabel && <ControlledInputLabelComponent label={label!} />}
      <div className={variantBorderStylesMap[variant]}>
        <div
          data-testid='input-parent'
          className={variantViewStylesMap[variant]}
        >
          {startAdornment && <div className='pl-2'>{startAdornment}</div>}
          <input
            name={name}
            className={classNames([
              textInputStylesMap[variant],
              {
                textAlignVertical:
                  !numberOfLines || numberOfLines === 1 ? 'auto' : 'top',
              },
            ])}
            type={type}
            inputMode={inputMode}
            dir={direction}
            readOnly={readOnly}
            disabled={disabled}
            onCopy={(e) => {
              if (disableCopyPaste) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              if (disableCopyPaste) {
                e.preventDefault();
              }
            }}
            onFocus={() => setIsFocused(true)}
            onChange={onChange}
            onBlur={handleOnBlur}
            placeholder={placeholderText!}
            value={value}
            autoComplete={autoComplete}
            data-testid={`input-${label}`}
          />
          {renderIconOrEndAdornment()}
        </div>
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledTextInput;
