import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import BankIcon from '../assets/icons/bank.png';
import DollarIcon from '../assets/icons/dollar.png';
import Button from '../components/Button';
import ConfigDrivenTitle from '../components/ConfigDriven/Misc/ConfigDrivenTitle';
import ConfigDrivenContainerLayout from '../components/Layout/ConfigDrivenContainerLayout';
import ControlledSingleSelectCardInput from '../components/inputs/ControlledSingleSelectCardInput';
import { ApplyLoanLoanTypeEnum } from '../openapi/atlantis';
import { applyForLoan, getLoanBorrowerId } from '../slices/LoanSlice';
import { useAppDispatch } from '../slices/store';

interface FormData {
  loanType: ApplyLoanLoanTypeEnum;
}

interface LoanTypeRouteProps {}

const LoanTypeRoute: React.FC<LoanTypeRouteProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>();

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);

    const loan = await dispatch(applyForLoan(data.loanType));
    const borrowerId = await dispatch(getLoanBorrowerId(loan));

    if (borrowerId) {
      navigate(`/pre-approval-form/${borrowerId}`, { replace: true });
    }

    setIsSubmitting(false);
  };

  return (
    <ConfigDrivenContainerLayout hideChat>
      <div className='mb-10'>
        <ConfigDrivenTitle
          title='Start a new <skyblue>application</skyblue>'
          textStyle='lg:!text-4xl text-2xl'
        />
      </div>
      <ControlledSingleSelectCardInput<FormData, 'loanType'>
        name='loanType'
        control={control}
        options={[
          {
            value: ApplyLoanLoanTypeEnum.Purchase,
            label: 'Buy a Home',
            image: BankIcon,
            subLabel:
              'House hunting? Get pre-approved quickly and find the right mortgage for you.',
          },
          {
            value: ApplyLoanLoanTypeEnum.Refinance,
            label: 'Refinance',
            image: DollarIcon,
            subLabel:
              'Lower your monthly payment, take cash out, or pay off your mortgage sooner.',
          },
        ]}
        rules={{ required: 'Please select a loan type' }}
      />
      <div className='flex w-full flex-row-reverse mt-3'>
        <Button
          label='Next'
          type='submit'
          fullWidth={isMobile}
          rightIcon={
            <FontAwesomeIcon
              icon={faChevronsRight}
              fontSize={16}
              className='text-white'
            />
          }
          gradientVariant='mintyfresh'
          isDisabled={!isValid}
          isSubmitting={isSubmitting}
          onPress={handleSubmit(onSubmit)}
        />
      </div>
    </ConfigDrivenContainerLayout>
  );
};

export default LoanTypeRoute;
