import {
  faCircleXmark,
  faLocationDot,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete } from '@react-google-maps/api';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useGoogleMapsAPILoader } from '../../hooks/useGoogleMapsAPILoader';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

export interface GooglePlaceLocationType {
  formatted_address: string;
  place_id: string;
  geometry: {
    location: {
      lat: number | undefined;
      lng: number | undefined;
    };
  };
  address_components?:
    | { long_name: string; short_name: string; types: string[] }[]
    | undefined;
}

export type GooglePlaceType = Record<string, GooglePlaceLocationType>;

interface ControlledGoogleAutocompleteSearchBar<
  TFieldValues extends FieldValues | GooglePlaceType =
    | FieldValues
    | GooglePlaceType,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  placeholder?: string;
  shouldUnregister?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  label?: string;
}

const ControlledGoogleAutoCompleteSearchInput = <
  TFieldValues extends FieldValues | GooglePlaceType =
    | FieldValues
    | GooglePlaceType,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  placeholder,
  readOnly = false,
  disabled = false,
  shouldUnregister = true,
  ...controllerProps
}: ControlledGoogleAutocompleteSearchBar<TFieldValues, TName>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const isLoaded = useGoogleMapsAPILoader();

  const onLoad = useCallback(
    (autocomplete: any) => {
      setAutocomplete(autocomplete);
    },
    [setAutocomplete],
  );

  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  useEffect(() => {
    if (!value?.formatted_address) {
      setIsEditable(true);
    }
  }, [value]);

  return (
    <div className='w-full space-y-1'>
      {!!label && <ControlledInputLabelComponent label={label} />}
      <div
        className={classNames([
          'w-full flex flex-row items-center justify-start overflow-hidden border rounded-lg',
          isFocused ? 'border-black' : 'border-silver',
        ])}
      >
        {isLoaded && (
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={() => {
              const selectedPlace = autocomplete?.getPlace();
              onChange({
                formatted_address: selectedPlace?.formatted_address,
                place_id: selectedPlace?.place_id,
                geometry: {
                  location: {
                    lat: selectedPlace?.geometry?.location?.lat(),
                    lng: selectedPlace?.geometry?.location?.lng(),
                  },
                },
                address_components: selectedPlace?.address_components,
              });
              setIsEditable(false);
            }}
            restrictions={{
              country: ['us'],
            }}
            options={{
              fields: [
                'place_id',
                'formatted_address',
                'geometry.location',
                'address_components',
              ],
            }}
            className='w-full'
          >
            <div className='flex flex-row items-center px-2'>
              <span>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  fontSize={20}
                  className='text-primary-skyblue'
                />
              </span>
              <input
                type='text'
                ref={inputRef}
                value={value?.formatted_address}
                placeholder={placeholder}
                onChange={onChange}
                className={classNames(
                  [
                    'appearance-none bg-white focus:outline-none focus:ring-0 w-full text-base px-2 py-3 leading-4 truncate font-inter-regular',
                  ],
                  readOnly || disabled
                    ? 'border-gray-3'
                    : isFocused
                    ? 'border-black'
                    : 'border-primary-gray',
                )}
                onBlur={() => {
                  onBlur();
                  setIsFocused(false);
                }}
                onFocus={() => setIsFocused(true)}
                readOnly={readOnly || !isEditable}
                disabled={disabled || !isEditable}
              />
              {!!value?.formatted_address && (
                <FontAwesomeIcon
                  onClick={() => {
                    onChange(null);
                    inputRef.current!.value = '';
                  }}
                  icon={faCircleXmark}
                  fontSize={20}
                  className='text-primary-skyblue'
                />
              )}
            </div>
          </Autocomplete>
        )}
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledGoogleAutoCompleteSearchInput;
