import classNames from 'classnames';
import { FC, ReactNode } from 'react';

interface ConfigDrivenSubtitleProps {
  subtitle: string;
  textStyle?: string;
}

const ConfigDrivenSubtitle: FC<ConfigDrivenSubtitleProps> = ({
  subtitle,
  textStyle,
}) => {
  const commonStyle = [
    'font-inter-regular md:text-2xl text-lg text-black',
    textStyle,
  ];

  const formatText = (str: string): ReactNode[] => {
    const regex = /<(\w+)>(.*?)<\/\1>/g;
    const parts = str.split(regex);

    return parts.reduce((acc: ReactNode[], part, index) => {
      const isTagContent = index % 3 === 2;

      if (isTagContent) {
        const tagName = parts[index - 1];
        acc.push(
          <p
            key={[index].toString()}
            className={classNames([commonStyle, `text-primary-${tagName}`])}
          >
            {part}
          </p>,
        );
      }

      if (index % 3 === 0) {
        acc.push(
          <p key={[index].toString()} className={classNames(commonStyle)}>
            {part}
          </p>,
        );
      }

      return acc;
    }, []);
  };

  return <p>{formatText(subtitle)}</p>;
};

export default ConfigDrivenSubtitle;
