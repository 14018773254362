import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AnswerApplicationQuestionRequest,
  Question,
} from '../../../openapi/atlantis';
import AnalyticsService from '../../../services/AnalyticsService.ts';
import ErrorService from '../../../services/ErrorService.ts';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { useAppDispatch } from '../../../slices/store';
import { AnalyticsEventEnum } from '../../../types.ts';
import {
  ApplicationStepWithNewContent,
  getFormQuestionAnswers,
} from '../../../utils/ConfigDrivenFormUtils';
import Logger from '../../../utils/Logger';
import { removeHtmlTagsFromString } from '../../../utils/StringUtils.ts';
import ConfigDrivenFields from './ConfigDrivenFields.tsx';
import ConfigDrivenSegments from './ConfigDrivenSegments.tsx';

interface ConfigDrivenFormProps {
  config: ApplicationStepWithNewContent<Question>;
  onNext: () => void;
  onSubmit: (formData: AnswerApplicationQuestionRequest) => void;
  defaultValues: Record<string, any>;
}

const ConfigDrivenForm: React.FC<ConfigDrivenFormProps> = ({
  config,
  onNext,
  onSubmit,
  defaultValues,
}) => {
  const checklistItemId = config?.checklistItemId;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (!isSubmitting) {
      methods.reset(defaultValues);
    }
  }, [defaultValues, isSubmitting, methods]);

  const handleOnSubmit = async (formData: any) => {
    setIsSubmitting(true);
    try {
      await onSubmit(getFormQuestionAnswers(config, formData));
      await onNext();

      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.QUESTION_ANSWERED,
        {
          section: removeHtmlTagsFromString(config?.sectionName!),
          question: removeHtmlTagsFromString(config?.content?.title!),
        },
      );
    } catch (e: any) {
      Logger.error('Failed to submit answer:', e);
      ErrorService.notify('Failed to submit answer', e);
      dispatch(
        showErrorToast(
          'Failed to submit the answer. Please try again in sometime',
        ),
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <ConfigDrivenSegments
        segments={config.content.segments}
        isSubmitting={isSubmitting}
        onSubmit={handleOnSubmit}
        checklistItemId={checklistItemId}
      />
      <ConfigDrivenFields
        fields={config.content.fields}
        isSubmitting={isSubmitting}
        onSubmit={handleOnSubmit}
        checklistItemId={checklistItemId}
      />
    </FormProvider>
  );
};

export default ConfigDrivenForm;
