import classNames from 'classnames';
import React, { useState } from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

export const VALID_VARIANTS = ['default'] as const;
type ControlledTextInputVariantTuple = typeof VALID_VARIANTS;
type ControlledTextInputVariant = ControlledTextInputVariantTuple[number];

type variantMapStyles<T> = {
  [x in ControlledTextInputVariant]: T;
};

export interface ControlledMaskTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  mask: string;
  label?: string;
  readOnly?: boolean;
  variant?: ControlledTextInputVariant;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  placeholder?: string;
  disableCopyPaste?: boolean;
  autoComplete?: string | undefined;
  disabled?: boolean;
}

const ControlledMaskTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  mask,
  label,
  readOnly = false,
  disabled = false,
  variant = 'default',
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  placeholder,
  disableCopyPaste = false,
  autoComplete,
  ...controllerProps
}: ControlledMaskTextInputProps<TFieldValues, TName>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    field: { value, onChange, name, onBlur },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  const isShowLabel = !!label && (isFocused || !!value);

  const placeholderText = isFocused ? '' : placeholder;

  const variantBorderStylesMap: variantMapStyles<string> = {
    default: classNames([
      'flex flex-row items-center justify-start overflow-hidden border rounded-lg',
      isFocused ? 'border-black' : 'border-silver',
    ]),
  };

  const variantViewStylesMap: variantMapStyles<string> = {
    default: classNames([
      'flex flex-row justify-start items-center w-full',
      readOnly || disabled ? 'bg-whitesmoke' : 'bg-transparent',
    ]),
  };

  const textInputStylesMap: variantMapStyles<string> = {
    default:
      'flex items-center justify-center flex-grow font-inter-regular text-base px-2 py-3 leading-4 text-black bg-transparent appearance-none border-none focus:outline-none focus:ring-0',
  };

  const renderIconOrEndAdornment = (): React.ReactElement => {
    if ((isFocused || !value) && endAdornment) {
      return <div className='pr-2'>{endAdornment}</div>;
    }

    return <div />;
  };

  const handleOnBlur = () => {
    if (typeof value === 'string') {
      onChange(value.trim());
    }
    onBlur();
    setIsFocused(false);
  };

  return (
    <div>
      {isShowLabel && <ControlledInputLabelComponent label={label} />}
      <div className={variantBorderStylesMap[variant]}>
        {startAdornment && <div className='pl-2'>{startAdornment}</div>}
        <div
          data-testid='input-parent'
          className={variantViewStylesMap[variant]}
        >
          <InputMask
            id={name}
            value={value}
            onChange={onChange}
            name={name}
            mask={mask}
            maskChar=''
            onFocus={() => setIsFocused(true)}
            onBlur={handleOnBlur}
            onCopy={(e) => {
              if (disableCopyPaste) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              if (disableCopyPaste) {
                e.preventDefault();
              }
            }}
            placeholder={placeholderText}
            className={textInputStylesMap[variant]}
            autoComplete={autoComplete}
            readOnly={readOnly}
          />
          {renderIconOrEndAdornment()}
        </div>
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledMaskTextInput;
