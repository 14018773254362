import { faDownToBracket } from '@fortawesome/pro-regular-svg-icons';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { PHONE_NUMBER } from '../../../constants/OneRealMortgageConstants.ts';
import { SectionOutro } from '../../../openapi/atlantis';
import { FileApi } from '../../../openapi/dropbox';
import ErrorService from '../../../services/ErrorService.ts';
import { fetchLoanProgress } from '../../../slices/ApplicationSlice';
import { useAppDispatch, useAppSelector } from '../../../slices/store';
import { ApplicationStepWithNewContent } from '../../../utils/ConfigDrivenFormUtils';
import Logger from '../../../utils/Logger';
import { getDropboxConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { formatPhoneNumber } from '../../../utils/StringUtils.ts';
import Button from '../../Button';
import Confetti from '../../Confetti';
import DefaultSplash from '../../DefaultSplash';
import ConfigDrivenContainerLayout from '../../Layout/ConfigDrivenContainerLayout';
import Step from '../../StepProgress/Step';

interface ConfigDrivenOutroContainerProps {
  config: ApplicationStepWithNewContent<SectionOutro>;
}

const ConfigDrivenOutroContainer = ({
  config,
}: ConfigDrivenOutroContainerProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loanProgress } = useAppSelector((state) => state.application);
  const { borrowerDetail } = useAppSelector((state) => state.loan);
  const { id } = config.content || {};

  const steps = loanProgress?.items || [];

  const isLoanPreApproved = !!borrowerDetail?.preApprovalFileId;

  const getLoanProgress = useCallback(
    async (roadToSuccessId: string) => {
      setIsLoading(true);
      await dispatch(fetchLoanProgress(roadToSuccessId));
      setIsLoading(false);
    },
    [dispatch],
  );

  const handleDownloadPreApprovalLetter = async () => {
    try {
      const { data } = await new FileApi(
        await getDropboxConfiguration(),
      ).getFileUrl(borrowerDetail?.preApprovalFileId!);

      await window.open(data, '_blank');
    } catch (e: any) {
      Logger.error('Failed to download pre-approved letter:', e);
      ErrorService.notify('Failed to download pre-approved letter', e);
    }
  };

  useEffect(() => {
    if (borrowerDetail?.roadToSuccessId!) {
      getLoanProgress(borrowerDetail?.roadToSuccessId);
    }
  }, [borrowerDetail?.roadToSuccessId, getLoanProgress]);

  if (isLoading) {
    return (
      <div className='h-screen w-screen'>
        <DefaultSplash />
      </div>
    );
  }

  return (
    <ConfigDrivenContainerLayout>
      <div key={id}>
        <div>
          <div className='mt-2'>
            <p className='font-poppins-medium text-primary-skyblue text-4xl'>
              Your application is in!
            </p>
          </div>
          <div className='bg-whitesmoke p-2 rounded-lg mt-5'>
            <div className='flex flex-row items-center border-b border-b-silver p-2'>
              <FontAwesomeIcon
                icon={faCircleQuestion}
                fontSize={16}
                className='text-aqua'
              />
              <p className='font-poppins-medium text-base ml-2'>Questions?</p>
            </div>
            <div className='p-2'>
              <div className='flex flex-row items-center'>
                <p className='font-inter-regular text-base text-black'>
                  Call us at{' '}
                </p>
                <a href={`tel:${PHONE_NUMBER}`} className='pl-2'>
                  <p className='font-inter-medium text-base text-primary-skyblue'>
                    {formatPhoneNumber(PHONE_NUMBER)}
                  </p>
                </a>
              </div>
              <p className='font-inter-regular text-sm text-primary-graphite'>
                Monday - Friday, 9am - 9pm Eastern.
              </p>
            </div>
          </div>
          {isLoanPreApproved && (
            <div className='flex-row mt-8'>
              <Button
                label='Download Pre-Approval Letter'
                gradientVariant='mintyfresh'
                leftIcon={
                  <FontAwesomeIcon
                    icon={faDownToBracket}
                    fontSize={16}
                    className='text-white'
                  />
                }
                buttonSize='sm'
                containerStyle='border'
                onPress={handleDownloadPreApprovalLetter}
              />
            </div>
          )}
        </div>
        <div className='bg-white rounded-3xl mt-10'>
          <div>
            {steps.map((step, index) => {
              const isLastStep = index === steps.length - 1;
              const isStepCompleted = !!step.complete;

              return (
                <div key={[index].toString()} className='flex flex-row'>
                  <div className='flex flex-col items-center'>
                    <Step
                      key={[index].toString()}
                      inProgressPercentage={10}
                      isFinal={isLastStep}
                      isCompleted={isStepCompleted}
                      isInProgress={false}
                      isVertical
                    />
                  </div>
                  <div className='flex flex-col pl-4'>
                    <div className='flex flex-row items-center justify-between'>
                      <div className='flex flex-row items-center'>
                        <p className='font-poppins-medium text-lg text-black'>
                          {step.name}
                        </p>
                      </div>
                    </div>
                    <div className='flex'>
                      <p className='font-inter-medium text-sm mb-4 text-primary-gray'>
                        {step.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Confetti />
      </div>
    </ConfigDrivenContainerLayout>
  );
};

export default ConfigDrivenOutroContainer;
