import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { EnumMap } from '../types';
import { cn } from '../utils/classUtils';

export type SimpleModalSizeVariantType =
  | 'default'
  | 'small'
  | 'large'
  | 'extraLarge'
  | 'fixed';

export interface SimpleModalProps {
  title?: string;
  titleComponent?: React.ReactElement;
  isOpen: boolean;
  subtitle?: string;
  hideIcon?: boolean;
  size?: SimpleModalSizeVariantType;
  onClose(): void;
  children: React.ReactNode;
}

const SimpleModal: React.FC<SimpleModalProps> = ({
  title,
  titleComponent,
  isOpen,
  subtitle,
  children,
  onClose,
  hideIcon = false,
  size = 'default',
}) => {
  const sizeVariant: EnumMap<SimpleModalSizeVariantType, string> = {
    default: 'md:w-1/2 lg:w-1/4',
    large: 'md:w-3/5 lg:w-1/3',
    small: 'md:w-2/5 lg:w-1/5',
    extraLarge: 'md:w-8/12 lg:w-5/12',
    fixed: 'w-[420px]',
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      title='simple-modal'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className={cn(
            'w-full max-h-[85%] bg-white rounded-2xl z-10  shadow-zen-web scrollbar overflow-y-auto',
            sizeVariant[size],
          )}
          aria-label='simple-modal'
        >
          <div
            className={cn(
              'flex flex-row items-center bg-zen-light-blue py-1.5 rounded-t-xl px-10',
            )}
          >
            {titleComponent ? (
              titleComponent
            ) : (
              <div className='flex flex-grow flex-shrink flex-col justify-center gap-y-2 my-1'>
                {title && (
                  <p className='font-inter font-semibold text-zen-dark-9 text-lg'>
                    {title}
                  </p>
                )}
                {subtitle && (
                  <p className='text-base text-center text-gray-500 font-inter px-4'>
                    {subtitle}
                  </p>
                )}
              </div>
            )}
            {!hideIcon && (
              <div>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className='text-zen-dark-5 cursor-pointer'
                  size='xl'
                  onClick={onClose}
                />
              </div>
            )}
          </div>
          <div className='w-full'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SimpleModal;
