import { times } from 'lodash';
import React from 'react';

interface BlankSpaceProps {
  number: number;
}

const BlankSpace: React.FC<BlankSpaceProps> = ({ number }) => {
  return times(number, () => <>&nbsp;</>);
};

export default BlankSpace;
