import { LoanDto } from '../../openapi/atlantis';
import RenderThemeComponent from '../RenderThemeComponent';
import LoanCardGemini from './Gemini/LoanCardGemini';
import LoanCardReskin from './Reskin/LoanCardReskin';

export interface LoanCardProps {
  loan: LoanDto;
  onPress: (loan: LoanDto) => void;
}

const LoanCard: React.FC<LoanCardProps> = (props) => {
  return (
    <RenderThemeComponent<LoanCardProps>
      GeminiComponent={LoanCardGemini}
      ReskinComponent={LoanCardReskin}
      {...props}
    />
  );
};

export default LoanCard;
