import { faCircle, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { Fragment } from 'react';

interface StepProps {
  isInProgress: boolean;
  isCompleted: boolean;
  isFinal: boolean;
  inProgressPercentage?: number;
  isVertical?: boolean;
}

const Step: React.FC<StepProps> = ({
  isInProgress,
  isCompleted,
  isFinal,
  inProgressPercentage,
  isVertical = false,
}) => {
  return (
    <Fragment>
      <div className='flex flex-row m-1 items-center justify-center'>
        {isCompleted ? (
          <FontAwesomeIcon
            icon={faCircleCheck}
            fontSize={24}
            className='text-aqua'
          />
        ) : (
          <FontAwesomeIcon
            icon={faCircle}
            fontSize={24}
            className={classNames(
              isInProgress ? 'text-aqua' : 'text-primary-graphite',
            )}
          />
        )}
      </div>
      <div className='flex flex-1 relative'>
        {!isFinal && (
          <div
            className={classNames([
              'flex flex-1 items-center',
              isVertical ? 'flex-col' : 'flex-row',
            ])}
          >
            <div
              className={classNames([
                'flex',
                'flex-1',
                'border',
                isCompleted ? 'border-aqua' : 'border-primary-graphite',
              ])}
            />
          </div>
        )}
        {!isFinal && isInProgress && (
          <div
            className='absolute top-0 border border-primary-skyblue'
            style={
              isVertical
                ? {
                    height: `${inProgressPercentage}%`,
                  }
                : {
                    width: `${inProgressPercentage}%`,
                  }
            }
          />
        )}
      </div>
    </Fragment>
  );
};

export default Step;
