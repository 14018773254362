import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import ConfigDrivenContainerLayout from '../components/Layout/ConfigDrivenContainerLayout';
import LoanCard from '../components/Loans/LoanCard';
import { LoanDto } from '../openapi/atlantis';
import { getLoanBorrowerId } from '../slices/LoanSlice';
import { useAppDispatch } from '../slices/store';
import { RootState } from '../types';
import LoansTitle from '../components/Loans/LoansTitle';

interface LoansRouteProps {}

const LoansRoute: React.FC<LoansRouteProps> = () => {
  const {
    loan: { loans },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onNewApplication = () => {
    navigate('/loan-type');
  };

  const onLoanClick = async (loan: LoanDto) => {
    const borrowerId = await dispatch(getLoanBorrowerId(loan));

    if (borrowerId) {
      navigate(`/pre-approval-form/${borrowerId}`);
    }
  };

  return (
    <ConfigDrivenContainerLayout hideMyLoans hideChat>
      <div>
        <div className='lg:my-10 mb-10'>
          <LoansTitle />
        </div>
        {(loans || []).map((loan) => (
          <div key={loan.id} className='mb-5'>
            <LoanCard loan={loan} onPress={onLoanClick} />
          </div>
        ))}
        <div className='mt-10 flex justify-center'>
          <Button
            label='New Application'
            variant='primary'
            gradientVariant='mintyfresh'
            onPress={onNewApplication}
            rightIcon={
              <FontAwesomeIcon
                icon={faPlusCircle}
                size='sm'
                className='text-white'
              />
            }
          />
        </div>
      </div>
    </ConfigDrivenContainerLayout>
  );
};
export default LoansRoute;
