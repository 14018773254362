import { FC } from 'react';
import {
  AnswerApplicationQuestionRequest,
  FieldObject,
} from '../../../openapi/atlantis';
import ConfigDrivenFormInputs from './ConfigDrivenFormInputs';

interface ConfigDrivenFieldsProps {
  fields?: FieldObject[];
  checklistItemId?: string;
  isSubmitting: boolean;
  onSubmit: (e: AnswerApplicationQuestionRequest) => void;
}

const ConfigDrivenFields: FC<ConfigDrivenFieldsProps> = ({
  fields = [],
  checklistItemId,
  isSubmitting,
  onSubmit,
}) => {
  return (
    <div>
      {fields.map((field: any) => (
        <ConfigDrivenFormInputs
          key={field.id}
          field={field}
          checklistItemId={checklistItemId!}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
        />
      ))}
    </div>
  );
};

export default ConfigDrivenFields;
