import classNames from 'classnames';
import React from 'react';
import { Config, usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { EnumMap } from '../types';

export type ToolTipVariant = 'dark' | 'light';

export interface HoverProps {
  hoverComponent: React.ReactElement;
  config?: Config;
  variant?: ToolTipVariant;
  children?: React.ReactNode;
}

const Hover: React.FC<HoverProps> = ({
  hoverComponent,
  children,
  config = {
    trigger: 'click',
    placement: 'auto',
  },
  variant = 'light',
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(config);

  const variantToStyleMap: EnumMap<ToolTipVariant, string> = {
    dark: 'bg-black text-white border-black',
    light: 'bg-white text-black border-gray-300',
  };

  return (
    <div ref={setTriggerRef}>
      <div>
        {visible && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: variant === 'dark' ? 'tooltip' : 'tooltip-container',
            })}
          >
            <div {...getArrowProps()} />
            <div
              className={classNames(
                '-m-2 p-1 max-w-sm border inline-flex rounded-md',
                variantToStyleMap[variant],
              )}
            >
              {hoverComponent}
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default Hover;
