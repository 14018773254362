import { FC } from 'react';
import useValidateVisibleCondition from '../../../hooks/useValidateVisibleCondition';
import {
  AnswerApplicationQuestionRequest,
  FieldObject,
} from '../../../openapi/atlantis';
import ConfigDrivenFormFieldArrayInput from './ConfigDrivenFormFieldArrayInput';

interface ConfigDrivenConditionalFormFieldArrayInputProps {
  field: FieldObject;
  onSubmit: (e: AnswerApplicationQuestionRequest) => void;
  isSubmitting: boolean;
  checklistItemId: string;
}

const ConfigDrivenConditionalFormFieldArrayInput: FC<
  ConfigDrivenConditionalFormFieldArrayInputProps
> = ({ field, onSubmit, isSubmitting, checklistItemId }) => {
  const { validateFieldVisibleCondition } = useValidateVisibleCondition();
  const isVisible = validateFieldVisibleCondition(field);

  if (!isVisible) {
    return null;
  }

  return (
    <ConfigDrivenFormFieldArrayInput
      fieldArray={field}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      checklistItemId={checklistItemId}
    />
  );
};
export default ConfigDrivenConditionalFormFieldArrayInput;
