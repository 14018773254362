import Button from '../../components/Button';
import ForceReportingAxiosError from '../../errors/ForceReportingAxiosError';
import { AgentControllerApi } from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { isAxiosError } from '../../utils/TypeUtils';

interface ErrorIndexProps {}

const ErrorIndex: React.FC<ErrorIndexProps> = () => {
  return (
    <div className='max-w-xl mx-auto'>
      <h1 className='font-primary-medium mt-10 text-xl mb-1 flex'>
        Error Services
      </h1>
      <div className='flex flex-col items-start gap-4 mt-10'>
        <Button
          label='Test Error (Handled)'
          onPress={() =>
            ErrorService.notify(
              'Test Error (Handled)',
              new Error('Test Error!'),
            )
          }
        />
        <Button
          label='Test Error with Data (Handled)'
          onPress={() =>
            ErrorService.notify(
              'Test Error with Data (Handled)',
              new Error('Test Error!'),
              {
                data: { key: 'value' },
              },
            )
          }
        />
        <Button
          label='Test Error (Un-handled)'
          onPress={() => {
            throw new Error('Test Error (Un-handled)');
          }}
        />

        <Button
          label='Test Error (Axios)'
          onPress={async () => {
            try {
              await new AgentControllerApi(
                await getYentaConfiguration(),
              ).getAgentById('blah');
            } catch (e: any) {
              const forceReportedError = isAxiosError(e)
                ? new ForceReportingAxiosError(e)
                : e;

              ErrorService.notify(
                '[Test] Unable to fetch agent by id',
                forceReportedError,
                { transaction: { id: 'blah' } },
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default ErrorIndex;
