import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';

interface CopyToClipboardProps {
  label?: string;
  value: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ value, label }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [text] = useState(value);
  const onCopyText = () => {
    if (navigator.clipboard && window.isSecureContext)
      navigator.clipboard.writeText(text);
    else {
      // text area method
      let textArea = document.createElement('textarea');
      textArea.value = text;
      // make the textarea out of viewport
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className='flex flex-row items-center my-1 space-x-2'>
      <button
        className='flex items-center text-primary space-x-1 p-0.5 focus:outline-none'
        onClick={() => onCopyText()}
        type='button'
      >
        <FontAwesomeIcon
          icon={faCopy}
          fontSize={16}
          className='text-primary-gray ml-2'
        />
        <p className=''>{label}</p>
      </button>
      {isCopied && (
        <div className='flex items-center justify-center my-1 space-x-1'>
          <p className='text-xs'>Copied</p>
        </div>
      )}
    </div>
  );
};

export default CopyToClipboard;
