import React from 'react';
import classNames from 'classnames';
import {
  isStringPresent,
  parseInitials,
  stringToHexCode,
} from '../utils/StringUtils';
import { getYentaImageUrl } from '../utils/ImageUtils.ts';

export const VALID_SIZE_VARIANTS = [
  'sm',
  '2xs',
  'xs',
  'xxs',
  'md',
  'lg',
  'xl',
  '2xl',
] as const;
type SizeVariantTuple = typeof VALID_SIZE_VARIANTS;
export type SizeVariant = SizeVariantTuple[number];

export const VALID_BORDER_VARIANTS = ['circle', 'rounded'] as const;
type BorderVariantTuple = typeof VALID_BORDER_VARIANTS;
export type BorderVariant = BorderVariantTuple[number];

export const VALID_STATUS_VARIANTS = ['online', 'offline', 'away'] as const;
type StatusVariantTuple = typeof VALID_STATUS_VARIANTS;
export type StatusVariant = StatusVariantTuple[number];

export const VALID_STATUS_POSITION_VARIANTS = ['top', 'bottom'] as const;
type StatusPositionVariantTuple = typeof VALID_STATUS_POSITION_VARIANTS;
export type StatusPositionVariant = StatusPositionVariantTuple[number];

export interface ZenAvatarProps {
  name: string;
  imageUrl?: string;
  variant?: BorderVariant;
  size?: SizeVariant;
  status?: StatusVariant;
  statusPosition?: StatusPositionVariant;
  showPlaceholder?: boolean;
  containerStyle?: string;
  textStyle?: string;
}

const Avatar: React.FC<ZenAvatarProps> = ({
  size = 'md',
  imageUrl,
  name,
  variant = 'circle',
  status,
  statusPosition = 'top',
  showPlaceholder = false,
  containerStyle,
  textStyle,
}) => {
  const sizeVariantClassNameMap: {
    [type in SizeVariant]: string;
  } = {
    xxs: 'w-3 h-3',
    '2xs': 'w-4 h-4',
    xs: 'w-5 h-5',
    sm: 'w-7 h-7',
    md: 'w-8 h-8',
    lg: 'w-10 h-10',
    xl: 'w-12 h-12',
    '2xl': 'w-14 h-14',
  };

  const statusSizeVariantClassNameMap: {
    [type in SizeVariant]: string;
  } = {
    xxs: 'w-1.5 h-1.5',
    '2xs': 'w-3 h-3',
    xs: 'w-2 h-2',
    sm: 'w-2.5 h-2.5',
    md: 'w-2.5 h-2.5',
    lg: 'w-3 h-3',
    xl: 'w-3.5 h-3.5',
    '2xl': 'w-4 h-4',
  };

  const fontSizeVariantClassNameMap: {
    [type in SizeVariant]: string;
  } = {
    xxs: 'text-white text-2xxs',
    '2xs': 'text-white text-2xxs',
    xs: 'text-white text-2xs',
    sm: 'text-white text-xs',
    md: 'text-white text-sm',
    lg: 'text-white text-lg',
    xl: 'text-white text-xl',
    '2xl': 'text-white text-2xl',
  };

  const getStatusPosition = (sizeAndVariant: string = 'circle-lg'): string => {
    let positionClass: string = '';
    switch (sizeAndVariant) {
      case 'top-rounded-xs':
        positionClass = 'top-1 -right-1';
        break;
      case 'bottom-rounded-xs':
        positionClass = 'bottom-1 -right-1';
        break;
      case 'top-rounded-sm':
        positionClass = 'top-1 -right-1';
        break;
      case 'bottom-rounded-sm':
        positionClass = 'bottom-1 -right-1';
        break;
      case 'top-rounded-md':
        positionClass = 'top-1 -right-1';
        break;
      case 'bottom-rounded-md':
        positionClass = 'bottom-1 -right-1';
        break;
      case 'top-rounded-lg':
        positionClass = 'top-1.5 -right-1.5';
        break;
      case 'bottom-rounded-lg':
        positionClass = 'bottom-1.5 -right-1.5';
        break;
      case 'top-rounded-xl':
        positionClass = 'top-1.5 -right-1.5';
        break;
      case 'bottom-rounded-xl':
        positionClass = 'bottom-1.5 -right-1.5';
        break;
      case 'top-rounded-2xl':
        positionClass = 'top-2 -right-2';
        break;
      case 'bottom-rounded-2xl':
        positionClass = 'bottom-2 -right-2';
        break;
      case 'top-circle-xs':
        positionClass = 'top-0.5 -right-0.5';
        break;
      case 'bottom-circle-xs':
        positionClass = 'bottom-0.5 -right-0.5';
        break;
      case 'top-circle-sm':
        positionClass = 'top-0.5 -right-0.5';
        break;
      case 'bottom-circle-sm':
        positionClass = 'bottom-0.5 -right-0.5';
        break;
      case 'top-circle-md':
        positionClass = 'top-0.5 -right-0.5';
        break;
      case 'bottom-circle-md':
        positionClass = 'bottom-0.5 -right-0.5';
        break;
      case 'top-circle-lg':
        positionClass = 'top-0.5 -right-0.5';
        break;
      case 'bottom-circle-lg':
        positionClass = 'bottom-0.5 -right-0.5';
        break;
      case 'top-circle-xl':
        positionClass = 'top-0.5 -right-0';
        break;
      case 'bottom-circle-xl':
        positionClass = 'bottom-0.5 -right-0';
        break;
      case 'top-circle-2xl':
        positionClass = 'top-0.5 -right-0';
        break;
      case 'bottom-circle-2xl':
        positionClass = 'bottom-0.5 -right-0';
        break;
    }
    return positionClass;
  };

  const statusVariantClassNameMap: {
    [type in StatusVariant]: string;
  } = {
    online: 'bg-green-400',
    offline: 'bg-red-500',
    away: 'bg-gray-300',
  };

  const borderVariantClassNameMap: {
    [type in BorderVariant]: string;
  } = {
    circle: 'rounded-full',
    rounded: 'rounded-md',
  };

  const childStyle: string[] = [
    'relative',
    sizeVariantClassNameMap[size],
    borderVariantClassNameMap[variant],
  ];

  return (
    <div
      className={classNames([
        'flex flex-row items-center justify-center opacity-100 self-center border-0 p-0 m-1 relative',
        sizeVariantClassNameMap[size],
        borderVariantClassNameMap[variant],
        containerStyle,
      ])}
      style={{
        backgroundColor:
          !imageUrl && !showPlaceholder ? stringToHexCode(name) : undefined,
      }}
    >
      {isStringPresent(imageUrl) || showPlaceholder ? (
        <img
          src={
            imageUrl
              ? getYentaImageUrl(imageUrl)
              : require('../assets/img/avatar-placeholder.png')
          }
          className={classNames([...childStyle, containerStyle])}
          alt='avatar'
        />
      ) : (
        <p
          className={classNames([
            'font-inter-medium',
            fontSizeVariantClassNameMap[size],
            textStyle,
          ])}
        >
          {parseInitials(name)}
        </p>
      )}
      {status && (
        <div
          className={classNames([
            'absolute -right-1 rounded-full border border-white',
            statusVariantClassNameMap[status],
            statusSizeVariantClassNameMap[size],
            getStatusPosition(`${statusPosition}-${variant}-${size}`),
          ])}
        />
      )}
    </div>
  );
};

export default Avatar;
