import { DropboxResponse, FileResponse } from '../openapi/dropbox';
import { FileReferenceDto } from '../openapi/sherlock';

export const getDropboxFilesFromFileReferences = (
  fileReferences: FileReferenceDto[],
  dropbox: DropboxResponse,
) => {
  const allDropboxFiles = dropbox?.files;
  let dropboxFiles: FileResponse[] = [];
  fileReferences?.forEach((fileReference) => {
    const dropboxFile = allDropboxFiles?.find(
      (file) => file.id === fileReference.fileId,
    );
    if (dropboxFile) {
      dropboxFiles.push(dropboxFile);
    }
  });

  return dropboxFiles;
};
