/* tslint:disable */
/* eslint-disable */
/**
 * Yada API
 * Yada Main Application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthorizedUser
 */
export interface AuthorizedUser {
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUser
     */
    'yentaId': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUser
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface AuthorizedUsers
 */
export interface AuthorizedUsers {
    /**
     * 
     * @type {Array<AuthorizedUser>}
     * @memberof AuthorizedUsers
     */
    'users': Array<AuthorizedUser>;
}
/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface BatchComment
 */
export interface BatchComment {
    /**
     * 
     * @type {PostCommentParams}
     * @memberof BatchComment
     */
    'params'?: PostCommentParams;
    /**
     * 
     * @type {PostCommentBody}
     * @memberof BatchComment
     */
    'body'?: PostCommentBody;
}
/**
 * 
 * @export
 * @interface BatchCommentRequest
 */
export interface BatchCommentRequest {
    /**
     * 
     * @type {Array<BatchComment>}
     * @memberof BatchCommentRequest
     */
    'comments'?: Array<BatchComment>;
}
/**
 * 
 * @export
 * @interface BatchCommentResponse
 */
export interface BatchCommentResponse {
    /**
     * 
     * @type {Array<CommentDto>}
     * @memberof BatchCommentResponse
     */
    'comments'?: Array<CommentDto>;
}
/**
 * 
 * @export
 * @interface Block
 */
export interface Block {
    /**
     * 
     * @type {string}
     * @memberof Block
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Block
     */
    'preview'?: string;
}
/**
 * 
 * @export
 * @interface CallParticipantDto
 */
export interface CallParticipantDto {
    /**
     * 
     * @type {YentaId}
     * @memberof CallParticipantDto
     */
    'userId': YentaId;
    /**
     * 
     * @type {string}
     * @memberof CallParticipantDto
     */
    'name'?: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof CallParticipantDto
     */
    'phone'?: PhoneNumber;
    /**
     * 
     * @type {EmailAddress}
     * @memberof CallParticipantDto
     */
    'email'?: EmailAddress;
}
/**
 * 
 * @export
 * @interface CommentCountRs
 */
export interface CommentCountRs {
    /**
     * 
     * @type {Array<TargetCommentCount>}
     * @memberof CommentCountRs
     */
    'counts'?: Array<TargetCommentCount>;
}
/**
 * 
 * @export
 * @interface CommentDto
 */
export interface CommentDto {
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'id'?: string;
    /**
     * 
     * @type {RezenObject}
     * @memberof CommentDto
     */
    'container'?: RezenObject;
    /**
     * 
     * @type {RezenObject}
     * @memberof CommentDto
     */
    'refersTo'?: RezenObject;
    /**
     * 
     * @type {number}
     * @memberof CommentDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'createdById'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'createdByGroupId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommentDto
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'updatedById'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommentDto
     */
    'edited'?: boolean;
    /**
     * 
     * @type {RichText}
     * @memberof CommentDto
     */
    'richContent'?: RichText;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    'readerRole'?: CommentDtoReaderRoleEnum;
    /**
     * 
     * @type {Array<ReadReceiptDto>}
     * @memberof CommentDto
     */
    'readReceipts'?: Array<ReadReceiptDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentDto
     */
    'rolesToNotify'?: Array<CommentDtoRolesToNotifyEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum CommentDtoReaderRoleEnum {
    User = 'USER',
    Admin = 'ADMIN',
    Public = 'PUBLIC'
}
/**
    * @export
    * @enum {string}
    */
export enum CommentDtoRolesToNotifyEnum {
    Agent = 'AGENT',
    AgentExperience = 'AGENT_EXPERIENCE',
    DesignatedBroker = 'DESIGNATED_BROKER',
    RegionalBroker = 'REGIONAL_BROKER',
    ManagingBroker = 'MANAGING_BROKER',
    FinanceSpecialist = 'FINANCE_SPECIALIST',
    SettlementSpecialist = 'SETTLEMENT_SPECIALIST',
    LoanOfficer = 'LOAN_OFFICER',
    Borrower = 'BORROWER',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface CommentsByOwner
 */
export interface CommentsByOwner {
    /**
     * 
     * @type {RezenObject}
     * @memberof CommentsByOwner
     */
    'owner'?: RezenObject;
    /**
     * 
     * @type {Array<CommentDto>}
     * @memberof CommentsByOwner
     */
    'comments'?: Array<CommentDto>;
}
/**
 * 
 * @export
 * @interface CreateVirtualNumber
 */
export interface CreateVirtualNumber {
    /**
     * 
     * @type {PhoneNumber}
     * @memberof CreateVirtualNumber
     */
    'phone': PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof CreateVirtualNumber
     */
    'groupName': CreateVirtualNumberGroupNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateVirtualNumber
     */
    'direction': CreateVirtualNumberDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateVirtualNumberGroupNameEnum {
    BrokerTeam = 'BROKER_TEAM',
    FinanceTeam = 'FINANCE_TEAM',
    SupportTeam = 'SUPPORT_TEAM',
    TransactionTeam = 'TRANSACTION_TEAM'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateVirtualNumberDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface CreateVoiceCall
 */
export interface CreateVoiceCall {
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCall
     */
    'targetId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCall
     */
    'targetType': CreateVoiceCallTargetTypeEnum;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof CreateVoiceCall
     */
    'targetPhone'?: PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCall
     */
    'targetOfficeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCall
     */
    'containerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCall
     */
    'containerType'?: CreateVoiceCallContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCall
     */
    'direction': CreateVoiceCallDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateVoiceCall
     */
    'callerGroupId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateVoiceCallTargetTypeEnum {
    Group = 'GROUP',
    User = 'USER'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateVoiceCallContainerTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateVoiceCallDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageClientTypeEnum {
    Producer = 'PRODUCER',
    Consumer = 'CONSUMER'
}
/**
    * @export
    * @enum {string}
    */
export enum FailedMessageStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageUpdateStatusRequestStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FileBlock
 */
export interface FileBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof FileBlock
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBlock
     */
    'altText'?: string;
}
/**
 * 
 * @export
 * @interface FileBlockAllOf
 */
export interface FileBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof FileBlockAllOf
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBlockAllOf
     */
    'altText'?: string;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

/**
    * @export
    * @enum {string}
    */
export enum GenericSearchResponseFailedMessageSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface HashtagBlock
 */
export interface HashtagBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof HashtagBlock
     */
    'hashtag'?: string;
}
/**
 * 
 * @export
 * @interface HashtagBlockAllOf
 */
export interface HashtagBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof HashtagBlockAllOf
     */
    'hashtag'?: string;
}
/**
 * 
 * @export
 * @interface HyperlinkBlock
 */
export interface HyperlinkBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof HyperlinkBlock
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof HyperlinkBlock
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface HyperlinkBlockAllOf
 */
export interface HyperlinkBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof HyperlinkBlockAllOf
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof HyperlinkBlockAllOf
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ImageBlock
 */
export interface ImageBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof ImageBlock
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageBlock
     */
    'altText'?: string;
}
/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface LatestCommentsResponse
 */
export interface LatestCommentsResponse {
    /**
     * 
     * @type {Array<CommentsByOwner>}
     * @memberof LatestCommentsResponse
     */
    'comments'?: Array<CommentsByOwner>;
}
/**
 * 
 * @export
 * @interface MentionBlock
 */
export interface MentionBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof MentionBlock
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentionBlock
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MentionBlockAllOf
 */
export interface MentionBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof MentionBlockAllOf
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentionBlockAllOf
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MessageCountRes
 */
export interface MessageCountRes {
    /**
     * 
     * @type {string}
     * @memberof MessageCountRes
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageCountRes
     */
    'unreadMessageCount'?: number;
}
/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'messageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {RezenObject}
     * @memberof MessageDto
     */
    'target'?: RezenObject;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'unreadComments'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'unreadFileUploads'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDto
     */
    'read'?: boolean;
}
/**
 * 
 * @export
 * @interface MessageQueryResDto
 */
export interface MessageQueryResDto {
    /**
     * 
     * @type {string}
     * @memberof MessageQueryResDto
     */
    'nextPage'?: string;
    /**
     * 
     * @type {Array<MessageDto>}
     * @memberof MessageQueryResDto
     */
    'messages'?: Array<MessageDto>;
}
/**
 * 
 * @export
 * @interface NotifiableUser
 */
export interface NotifiableUser {
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'keymakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'yentaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'yentaUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'yentaEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'yentaFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifiableUser
     */
    'role'?: NotifiableUserRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum NotifiableUserRoleEnum {
    Agent = 'AGENT',
    AgentExperience = 'AGENT_EXPERIENCE',
    DesignatedBroker = 'DESIGNATED_BROKER',
    RegionalBroker = 'REGIONAL_BROKER',
    ManagingBroker = 'MANAGING_BROKER',
    FinanceSpecialist = 'FINANCE_SPECIALIST',
    SettlementSpecialist = 'SETTLEMENT_SPECIALIST',
    LoanOfficer = 'LOAN_OFFICER',
    Borrower = 'BORROWER',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface NotifiableUsers
 */
export interface NotifiableUsers {
    /**
     * 
     * @type {Array<NotifiableUser>}
     * @memberof NotifiableUsers
     */
    'users': Array<NotifiableUser>;
}
/**
 * 
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface PostCommentBody
 */
export interface PostCommentBody {
    /**
     * 
     * @type {RichText}
     * @memberof PostCommentBody
     */
    'richContent'?: RichText;
    /**
     * 
     * @type {string}
     * @memberof PostCommentBody
     */
    'readerRole': PostCommentBodyReaderRoleEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostCommentBody
     */
    'rolesToNotify'?: Array<PostCommentBodyRolesToNotifyEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum PostCommentBodyReaderRoleEnum {
    User = 'USER',
    Admin = 'ADMIN',
    Public = 'PUBLIC'
}
/**
    * @export
    * @enum {string}
    */
export enum PostCommentBodyRolesToNotifyEnum {
    Agent = 'AGENT',
    AgentExperience = 'AGENT_EXPERIENCE',
    DesignatedBroker = 'DESIGNATED_BROKER',
    RegionalBroker = 'REGIONAL_BROKER',
    ManagingBroker = 'MANAGING_BROKER',
    FinanceSpecialist = 'FINANCE_SPECIALIST',
    SettlementSpecialist = 'SETTLEMENT_SPECIALIST',
    LoanOfficer = 'LOAN_OFFICER',
    Borrower = 'BORROWER',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface PostCommentParams
 */
export interface PostCommentParams {
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'containerType': PostCommentParamsContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'containerId': string;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'refersToType'?: PostCommentParamsRefersToTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'refersToId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'refersToName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostCommentParams
     */
    'triggerActivity'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'authorType'?: PostCommentParamsAuthorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostCommentParams
     */
    'callerGroupId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostCommentParamsContainerTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum PostCommentParamsRefersToTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum PostCommentParamsAuthorTypeEnum {
    User = 'USER',
    System = 'SYSTEM'
}

/**
 * 
 * @export
 * @interface QueryCommentsRs
 */
export interface QueryCommentsRs {
    /**
     * 
     * @type {number}
     * @memberof QueryCommentsRs
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryCommentsRs
     */
    'nextPage'?: string;
    /**
     * 
     * @type {Array<CommentDto>}
     * @memberof QueryCommentsRs
     */
    'comments'?: Array<CommentDto>;
}
/**
 * 
 * @export
 * @interface ReadReceiptDto
 */
export interface ReadReceiptDto {
    /**
     * 
     * @type {string}
     * @memberof ReadReceiptDto
     */
    'readerId': string;
    /**
     * 
     * @type {number}
     * @memberof ReadReceiptDto
     */
    'readAt': number;
}
/**
 * 
 * @export
 * @interface ReferenceBlock
 */
export interface ReferenceBlock extends Block {
    /**
     * 
     * @type {RezenObject}
     * @memberof ReferenceBlock
     */
    'entity'?: RezenObject;
}
/**
 * 
 * @export
 * @interface ReferenceBlockAllOf
 */
export interface ReferenceBlockAllOf {
    /**
     * 
     * @type {RezenObject}
     * @memberof ReferenceBlockAllOf
     */
    'entity'?: RezenObject;
}
/**
 * 
 * @export
 * @interface RezenObject
 */
export interface RezenObject {
    /**
     * 
     * @type {string}
     * @memberof RezenObject
     */
    'type': RezenObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RezenObject
     */
    'id': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RezenObject
     */
    'metadata'?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum RezenObjectTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}

/**
 * 
 * @export
 * @interface RichText
 */
export interface RichText {
    /**
     * 
     * @type {Array<RichTextBlocksInner>}
     * @memberof RichText
     */
    'blocks'?: Array<RichTextBlocksInner>;
    /**
     * 
     * @type {string}
     * @memberof RichText
     */
    'preview'?: string;
}
/**
 * @type RichTextBlocksInner
 * @export
 */
export type RichTextBlocksInner = FileBlock | HashtagBlock | HyperlinkBlock | ImageBlock | MentionBlock | ReferenceBlock | TextBlock | UnknownBlock | VoiceRecordingBlock;

/**
 * 
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    'end'?: number;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'speaker'?: string;
}
/**
 * 
 * @export
 * @interface Speaker
 */
export interface Speaker {
    /**
     * 
     * @type {string}
     * @memberof Speaker
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speaker
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Speaker
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface Speakers
 */
export interface Speakers {
    /**
     * 
     * @type {Array<Speaker>}
     * @memberof Speakers
     */
    'speakers'?: Array<Speaker>;
}
/**
 * 
 * @export
 * @interface SpeechOutline
 */
export interface SpeechOutline {
    /**
     * 
     * @type {string}
     * @memberof SpeechOutline
     */
    'title'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpeechOutline
     */
    'segments'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SseEmitter
 */
export interface SseEmitter {
    /**
     * 
     * @type {number}
     * @memberof SseEmitter
     */
    'timeout'?: number;
}
/**
 * 
 * @export
 * @interface Summary
 */
export interface Summary {
    /**
     * 
     * @type {string}
     * @memberof Summary
     */
    'abstractSummary'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Summary
     */
    'actionItems'?: Array<string>;
    /**
     * 
     * @type {Array<SpeechOutline>}
     * @memberof Summary
     */
    'speechOutlines'?: Array<SpeechOutline>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Summary
     */
    'keywords'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TargetCommentCount
 */
export interface TargetCommentCount {
    /**
     * 
     * @type {RezenObject}
     * @memberof TargetCommentCount
     */
    'target'?: RezenObject;
    /**
     * 
     * @type {number}
     * @memberof TargetCommentCount
     */
    'commentCount'?: number;
}
/**
 * 
 * @export
 * @interface TextBlock
 */
export interface TextBlock extends Block {
    /**
     * 
     * @type {string}
     * @memberof TextBlock
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface TextBlockAllOf
 */
export interface TextBlockAllOf {
    /**
     * 
     * @type {string}
     * @memberof TextBlockAllOf
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface Transcript
 */
export interface Transcript {
    /**
     * 
     * @type {string}
     * @memberof Transcript
     */
    'text'?: string;
    /**
     * 
     * @type {Array<Segment>}
     * @memberof Transcript
     */
    'segments'?: Array<Segment>;
}
/**
 * 
 * @export
 * @interface UnknownBlock
 */
export interface UnknownBlock extends Block {
}
/**
 * 
 * @export
 * @interface UpdateCommentBody
 */
export interface UpdateCommentBody {
    /**
     * 
     * @type {RichText}
     * @memberof UpdateCommentBody
     */
    'richContent'?: RichText;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentBody
     */
    'readerRole'?: UpdateCommentBodyReaderRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateCommentBodyReaderRoleEnum {
    User = 'USER',
    Admin = 'ADMIN',
    Public = 'PUBLIC'
}

/**
 * 
 * @export
 * @interface UpdateVirtualNumberRequest
 */
export interface UpdateVirtualNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateVirtualNumberRequest
     */
    'groupName': UpdateVirtualNumberRequestGroupNameEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateVirtualNumberRequest
     */
    'direction': UpdateVirtualNumberRequestDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateVirtualNumberRequestGroupNameEnum {
    BrokerTeam = 'BROKER_TEAM',
    FinanceTeam = 'FINANCE_TEAM',
    SupportTeam = 'SUPPORT_TEAM',
    TransactionTeam = 'TRANSACTION_TEAM'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateVirtualNumberRequestDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface VoiceCallDto
 */
export interface VoiceCallDto {
    /**
     * 
     * @type {VoiceCallId}
     * @memberof VoiceCallDto
     */
    'id': VoiceCallId;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof VoiceCallDto
     */
    'virtualNumber': PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callCode': string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'dialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'containerType'?: VoiceCallDtoContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'containerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'direction': VoiceCallDtoDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callStatus'?: VoiceCallDtoCallStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callSid'?: string;
    /**
     * 
     * @type {CallParticipantDto}
     * @memberof VoiceCallDto
     */
    'caller': CallParticipantDto;
    /**
     * 
     * @type {VoiceCallTarget}
     * @memberof VoiceCallDto
     */
    'target': VoiceCallTarget;
    /**
     * 
     * @type {VoiceCallTargetMembers}
     * @memberof VoiceCallDto
     */
    'targetMembers': VoiceCallTargetMembers;
    /**
     * 
     * @type {CallParticipantDto}
     * @memberof VoiceCallDto
     */
    'callee'?: CallParticipantDto;
    /**
     * 
     * @type {Array<VoiceCalleeDto>}
     * @memberof VoiceCallDto
     */
    'callees'?: Array<VoiceCalleeDto>;
    /**
     * 
     * @type {VoiceRecordingDto}
     * @memberof VoiceCallDto
     */
    'recording'?: VoiceRecordingDto;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callerGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'callerGroupName'?: VoiceCallDtoCallerGroupNameEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'queueName'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallDto
     */
    'queueSid'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoContainerTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoCallStatusEnum {
    Pending = 'PENDING',
    Dialing = 'DIALING',
    Queued = 'QUEUED',
    Initiated = 'INITIATED',
    Ringing = 'RINGING',
    Connected = 'CONNECTED',
    InProgress = 'IN_PROGRESS',
    Busy = 'BUSY',
    Failed = 'FAILED',
    Noanswer = 'NOANSWER',
    Canceled = 'CANCELED',
    Answered = 'ANSWERED',
    Undialed = 'UNDIALED',
    Completed = 'COMPLETED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceCallDtoCallerGroupNameEnum {
    BrokerTeam = 'BROKER_TEAM',
    FinanceTeam = 'FINANCE_TEAM',
    SupportTeam = 'SUPPORT_TEAM',
    TransactionTeam = 'TRANSACTION_TEAM'
}

/**
 * 
 * @export
 * @interface VoiceCallId
 */
export interface VoiceCallId {
    /**
     * 
     * @type {string}
     * @memberof VoiceCallId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface VoiceCallTarget
 */
export interface VoiceCallTarget {
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTarget
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTarget
     */
    'type': VoiceCallTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTarget
     */
    'name'?: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof VoiceCallTarget
     */
    'phone'?: PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTarget
     */
    'officeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallTarget
     */
    'holdTimeSecs'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCallTargetTypeEnum {
    Group = 'GROUP',
    User = 'USER'
}

/**
 * 
 * @export
 * @interface VoiceCallTargetMember
 */
export interface VoiceCallTargetMember {
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTargetMember
     */
    'name'?: string;
    /**
     * 
     * @type {YentaId}
     * @memberof VoiceCallTargetMember
     */
    'userId': YentaId;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof VoiceCallTargetMember
     */
    'phone'?: PhoneNumber;
    /**
     * 
     * @type {EmailAddress}
     * @memberof VoiceCallTargetMember
     */
    'email'?: EmailAddress;
    /**
     * 
     * @type {string}
     * @memberof VoiceCallTargetMember
     */
    'groupRole'?: VoiceCallTargetMemberGroupRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCallTargetMemberGroupRoleEnum {
    DesignatedBroker = 'DESIGNATED_BROKER',
    RegionalBroker = 'REGIONAL_BROKER',
    ManagingBroker = 'MANAGING_BROKER',
    SettlementSpecialist = 'SETTLEMENT_SPECIALIST',
    FinanceSpecialist = 'FINANCE_SPECIALIST',
    ContractSpecialist = 'CONTRACT_SPECIALIST',
    AgentExperience = 'AGENT_EXPERIENCE',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface VoiceCallTargetMembers
 */
export interface VoiceCallTargetMembers {
    /**
     * 
     * @type {Array<VoiceCallTargetMember>}
     * @memberof VoiceCallTargetMembers
     */
    'members'?: Array<VoiceCallTargetMember>;
}
/**
 * 
 * @export
 * @interface VoiceCalleeDto
 */
export interface VoiceCalleeDto {
    /**
     * 
     * @type {VoiceCalleeId}
     * @memberof VoiceCalleeDto
     */
    'id': VoiceCalleeId;
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeDto
     */
    'callSid'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeDto
     */
    'callStatus'?: VoiceCalleeDtoCallStatusEnum;
    /**
     * 
     * @type {CallParticipantDto}
     * @memberof VoiceCalleeDto
     */
    'callee': CallParticipantDto;
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeDto
     */
    'dequeueEntry'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceCalleeDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceCalleeDtoCallStatusEnum {
    Pending = 'PENDING',
    Dialing = 'DIALING',
    Queued = 'QUEUED',
    Initiated = 'INITIATED',
    Ringing = 'RINGING',
    Connected = 'CONNECTED',
    InProgress = 'IN_PROGRESS',
    Busy = 'BUSY',
    Failed = 'FAILED',
    Noanswer = 'NOANSWER',
    Canceled = 'CANCELED',
    Answered = 'ANSWERED',
    Undialed = 'UNDIALED',
    Completed = 'COMPLETED'
}

/**
 * 
 * @export
 * @interface VoiceCalleeId
 */
export interface VoiceCalleeId {
    /**
     * 
     * @type {string}
     * @memberof VoiceCalleeId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface VoiceRecordingBlock
 */
export interface VoiceRecordingBlock extends Block {
    /**
     * 
     * @type {VoiceRecordingId}
     * @memberof VoiceRecordingBlock
     */
    'recordingId'?: VoiceRecordingId;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlock
     */
    'recordingType'?: VoiceRecordingBlockRecordingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlock
     */
    'direction'?: VoiceRecordingBlockDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlock
     */
    'callerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlock
     */
    'calleeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingBlock
     */
    'durationSecs'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlock
     */
    'recordingUrl'?: string;
    /**
     * 
     * @type {VoiceRecordingTranscription}
     * @memberof VoiceRecordingBlock
     */
    'transcription'?: VoiceRecordingTranscription;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingBlockRecordingTypeEnum {
    Call = 'CALL',
    Voicemail = 'VOICEMAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingBlockDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface VoiceRecordingBlockAllOf
 */
export interface VoiceRecordingBlockAllOf {
    /**
     * 
     * @type {VoiceRecordingId}
     * @memberof VoiceRecordingBlockAllOf
     */
    'recordingId'?: VoiceRecordingId;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlockAllOf
     */
    'recordingType'?: VoiceRecordingBlockAllOfRecordingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlockAllOf
     */
    'direction'?: VoiceRecordingBlockAllOfDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlockAllOf
     */
    'callerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlockAllOf
     */
    'calleeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingBlockAllOf
     */
    'durationSecs'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingBlockAllOf
     */
    'recordingUrl'?: string;
    /**
     * 
     * @type {VoiceRecordingTranscription}
     * @memberof VoiceRecordingBlockAllOf
     */
    'transcription'?: VoiceRecordingTranscription;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingBlockAllOfRecordingTypeEnum {
    Call = 'CALL',
    Voicemail = 'VOICEMAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingBlockAllOfDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface VoiceRecordingDto
 */
export interface VoiceRecordingDto {
    /**
     * 
     * @type {VoiceRecordingId}
     * @memberof VoiceRecordingDto
     */
    'id': VoiceRecordingId;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'callSid'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'recordingSid'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'recordingUrl': string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'signedRecordingUrl': string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingDto
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingDto
     */
    'errorCode'?: number;
    /**
     * 
     * @type {VoiceCallId}
     * @memberof VoiceRecordingDto
     */
    'voiceCallId': VoiceCallId;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'source'?: VoiceRecordingDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'status'?: VoiceRecordingDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingDto
     */
    'type': VoiceRecordingDtoTypeEnum;
    /**
     * 
     * @type {Summary}
     * @memberof VoiceRecordingDto
     */
    'summary'?: Summary;
    /**
     * 
     * @type {Speakers}
     * @memberof VoiceRecordingDto
     */
    'speakers'?: Speakers;
    /**
     * 
     * @type {Transcript}
     * @memberof VoiceRecordingDto
     */
    'transcript'?: Transcript;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecordingDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingDtoSourceEnum {
    Dialverb = 'DIALVERB',
    Conference = 'CONFERENCE',
    Outboundapi = 'OUTBOUNDAPI',
    Trunking = 'TRUNKING',
    Recordverb = 'RECORDVERB',
    Startcallrecordingapi = 'STARTCALLRECORDINGAPI',
    Startconferencerecordingapi = 'STARTCONFERENCERECORDINGAPI'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingDtoStatusEnum {
    InProgress = 'IN_PROGRESS',
    Paused = 'PAUSED',
    Stopped = 'STOPPED',
    Processing = 'PROCESSING',
    Completed = 'COMPLETED',
    Absent = 'ABSENT'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceRecordingDtoTypeEnum {
    Call = 'CALL',
    Voicemail = 'VOICEMAIL'
}

/**
 * 
 * @export
 * @interface VoiceRecordingId
 */
export interface VoiceRecordingId {
    /**
     * 
     * @type {string}
     * @memberof VoiceRecordingId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface VoiceRecordingTranscription
 */
export interface VoiceRecordingTranscription {
    /**
     * 
     * @type {Array<Speaker>}
     * @memberof VoiceRecordingTranscription
     */
    'speakers'?: Array<Speaker>;
    /**
     * 
     * @type {Summary}
     * @memberof VoiceRecordingTranscription
     */
    'summary'?: Summary;
    /**
     * 
     * @type {Transcript}
     * @memberof VoiceRecordingTranscription
     */
    'transcript'?: Transcript;
}
/**
 * 
 * @export
 * @interface VoiceVirtualNumberDto
 */
export interface VoiceVirtualNumberDto {
    /**
     * 
     * @type {VoiceVirtualNumberId}
     * @memberof VoiceVirtualNumberDto
     */
    'id': VoiceVirtualNumberId;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof VoiceVirtualNumberDto
     */
    'phone': PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof VoiceVirtualNumberDto
     */
    'groupName': VoiceVirtualNumberDtoGroupNameEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceVirtualNumberDto
     */
    'direction': VoiceVirtualNumberDtoDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof VoiceVirtualNumberDto
     */
    'deactivatedAt'?: number;
    /**
     * 
     * @type {YentaId}
     * @memberof VoiceVirtualNumberDto
     */
    'deactivatedBy'?: YentaId;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceVirtualNumberDtoGroupNameEnum {
    BrokerTeam = 'BROKER_TEAM',
    FinanceTeam = 'FINANCE_TEAM',
    SupportTeam = 'SUPPORT_TEAM',
    TransactionTeam = 'TRANSACTION_TEAM'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceVirtualNumberDtoDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface VoiceVirtualNumberId
 */
export interface VoiceVirtualNumberId {
    /**
     * 
     * @type {string}
     * @memberof VoiceVirtualNumberId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}
/**
 * 
 * @export
 * @interface YentaId
 */
export interface YentaId {
    /**
     * 
     * @type {string}
     * @memberof YentaId
     */
    'id': string;
}

/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('deleteComment', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('deleteComment', 'containerId', containerId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (commentId !== undefined) {
                localVarQueryParameter['commentId'] = commentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find the authorized users that can see the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedUsersForComment: async (commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getAuthorizedUsersForComment', 'commentId', commentId)
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('getAuthorizedUsersForComment', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('getAuthorizedUsersForComment', 'containerId', containerId)
            const localVarPath = `/api/v1/comments/{commentId}/authorized-users`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find the notifiable users for the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentListeners: async (commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getCommentListeners', 'commentId', commentId)
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('getCommentListeners', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('getCommentListeners', 'containerId', containerId)
            const localVarPath = `/api/v1/comments/{commentId}/notifiable-users`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all comments within a particular Rezen container entity (such as a Transaction), including any comments on Rezen reference entities owned by the container (such as Checklist Items). Comments are returned in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByContainer: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('getCommentsByContainer', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('getCommentsByContainer', 'containerId', containerId)
            const localVarPath = `/api/v1/commentsByContainer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageStart !== undefined) {
                localVarQueryParameter['pageStart'] = pageStart;
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all comments on a particular Rezen reference entity (such as a Checklist Item) owned by the container object. This endpoint queries on Dynamo\'s secondary index and returns comments in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} refersToType 
         * @param {string} refersToId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByReference: async (refersToType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refersToType' is not null or undefined
            assertParamExists('getCommentsByReference', 'refersToType', refersToType)
            // verify required parameter 'refersToId' is not null or undefined
            assertParamExists('getCommentsByReference', 'refersToId', refersToId)
            const localVarPath = `/api/v1/commentsByReference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refersToType !== undefined) {
                localVarQueryParameter['refersToType'] = refersToType;
            }

            if (refersToId !== undefined) {
                localVarQueryParameter['refersToId'] = refersToId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageStart !== undefined) {
                localVarQueryParameter['pageStart'] = pageStart;
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the number of comments on each provided Rezen reference entity (such as a Checklist Item).Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<string>} refersToTargets 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByReferenceCount: async (refersToTargets: Array<string>, authorType?: 'USER' | 'SYSTEM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refersToTargets' is not null or undefined
            assertParamExists('getCommentsByReferenceCount', 'refersToTargets', refersToTargets)
            const localVarPath = `/api/v1/commentsByReferenceCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refersToTargets) {
                localVarQueryParameter['refersToTargets'] = Array.from(refersToTargets);
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the latest comment for each provided Rezen reference entity (such as a Checklist Item). Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<RezenObject>} rezenObject 
         * @param {number} [limit] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestCommentsByReference: async (rezenObject: Array<RezenObject>, limit?: number, authorType?: 'USER' | 'SYSTEM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rezenObject' is not null or undefined
            assertParamExists('getLatestCommentsByReference', 'rezenObject', rezenObject)
            const localVarPath = `/api/v1/latestCommentsByReference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rezenObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a new comment. Container fields (id and type) are required.
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'USER' | 'SYSTEM'} authorType 
         * @param {PostCommentBody} postCommentBody 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {string} [refersToName] 
         * @param {string} [callerGroupId] 
         * @param {boolean} [triggerActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postComment: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', postCommentBody: PostCommentBody, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('postComment', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('postComment', 'containerId', containerId)
            // verify required parameter 'authorType' is not null or undefined
            assertParamExists('postComment', 'authorType', authorType)
            // verify required parameter 'postCommentBody' is not null or undefined
            assertParamExists('postComment', 'postCommentBody', postCommentBody)
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (refersToType !== undefined) {
                localVarQueryParameter['refersToType'] = refersToType;
            }

            if (refersToId !== undefined) {
                localVarQueryParameter['refersToId'] = refersToId;
            }

            if (refersToName !== undefined) {
                localVarQueryParameter['refersToName'] = refersToName;
            }

            if (callerGroupId !== undefined) {
                localVarQueryParameter['callerGroupId'] = callerGroupId;
            }

            if (authorType !== undefined) {
                localVarQueryParameter['authorType'] = authorType;
            }

            if (triggerActivity !== undefined) {
                localVarQueryParameter['triggerActivity'] = triggerActivity;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCommentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a batch of comments. Container fields (id and type) are required.
         * @param {BatchCommentRequest} batchCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsBatch: async (batchCommentRequest: BatchCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchCommentRequest' is not null or undefined
            assertParamExists('postCommentsBatch', 'batchCommentRequest', batchCommentRequest)
            const localVarPath = `/api/v1/comments-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for comments
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe1: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('subscribe1', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('subscribe1', 'containerId', containerId)
            const localVarPath = `/api/v1/comments/sse-subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (refersToType !== undefined) {
                localVarQueryParameter['refersToType'] = refersToType;
            }

            if (refersToId !== undefined) {
                localVarQueryParameter['refersToId'] = refersToId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {UpdateCommentBody} updateCommentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: async (containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, updateCommentBody: UpdateCommentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerType' is not null or undefined
            assertParamExists('updateComment', 'containerType', containerType)
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('updateComment', 'containerId', containerId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateComment', 'commentId', commentId)
            // verify required parameter 'updateCommentBody' is not null or undefined
            assertParamExists('updateComment', 'updateCommentBody', updateCommentBody)
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (commentId !== undefined) {
                localVarQueryParameter['commentId'] = commentId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(containerType, containerId, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find the authorized users that can see the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorizedUsersForComment(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorizedUsersForComment(commentId, containerType, containerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find the notifiable users for the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentListeners(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotifiableUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentListeners(commentId, containerType, containerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all comments within a particular Rezen container entity (such as a Transaction), including any comments on Rezen reference entities owned by the container (such as Checklist Items). Comments are returned in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByContainer(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryCommentsRs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByContainer(containerType, containerId, pageSize, pageStart, authorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all comments on a particular Rezen reference entity (such as a Checklist Item) owned by the container object. This endpoint queries on Dynamo\'s secondary index and returns comments in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} refersToType 
         * @param {string} refersToId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByReference(refersToType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryCommentsRs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByReference(refersToType, refersToId, pageSize, pageStart, authorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the number of comments on each provided Rezen reference entity (such as a Checklist Item).Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<string>} refersToTargets 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByReferenceCount(refersToTargets: Array<string>, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentCountRs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByReferenceCount(refersToTargets, authorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the latest comment for each provided Rezen reference entity (such as a Checklist Item). Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<RezenObject>} rezenObject 
         * @param {number} [limit] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestCommentsByReference(rezenObject: Array<RezenObject>, limit?: number, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestCommentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestCommentsByReference(rezenObject, limit, authorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post a new comment. Container fields (id and type) are required.
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'USER' | 'SYSTEM'} authorType 
         * @param {PostCommentBody} postCommentBody 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {string} [refersToName] 
         * @param {string} [callerGroupId] 
         * @param {boolean} [triggerActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', postCommentBody: PostCommentBody, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postComment(containerType, containerId, authorType, postCommentBody, refersToType, refersToId, refersToName, callerGroupId, triggerActivity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post a batch of comments. Container fields (id and type) are required.
         * @param {BatchCommentRequest} batchCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCommentsBatch(batchCommentRequest: BatchCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCommentsBatch(batchCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for comments
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribe1(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribe1(containerType, containerId, refersToType, refersToId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {UpdateCommentBody} updateCommentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, updateCommentBody: UpdateCommentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComment(containerType, containerId, commentId, updateCommentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteComment(containerType, containerId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find the authorized users that can see the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedUsersForComment(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: any): AxiosPromise<AuthorizedUsers> {
            return localVarFp.getAuthorizedUsersForComment(commentId, containerType, containerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find the notifiable users for the provided comment
         * @param {string} commentId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentListeners(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: any): AxiosPromise<NotifiableUsers> {
            return localVarFp.getCommentListeners(commentId, containerType, containerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all comments within a particular Rezen container entity (such as a Transaction), including any comments on Rezen reference entities owned by the container (such as Checklist Items). Comments are returned in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByContainer(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: any): AxiosPromise<QueryCommentsRs> {
            return localVarFp.getCommentsByContainer(containerType, containerId, pageSize, pageStart, authorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all comments on a particular Rezen reference entity (such as a Checklist Item) owned by the container object. This endpoint queries on Dynamo\'s secondary index and returns comments in descending order of creation date
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} refersToType 
         * @param {string} refersToId 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByReference(refersToType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: any): AxiosPromise<QueryCommentsRs> {
            return localVarFp.getCommentsByReference(refersToType, refersToId, pageSize, pageStart, authorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the number of comments on each provided Rezen reference entity (such as a Checklist Item).Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<string>} refersToTargets 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByReferenceCount(refersToTargets: Array<string>, authorType?: 'USER' | 'SYSTEM', options?: any): AxiosPromise<CommentCountRs> {
            return localVarFp.getCommentsByReferenceCount(refersToTargets, authorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the latest comment for each provided Rezen reference entity (such as a Checklist Item). Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
         * @param {Array<RezenObject>} rezenObject 
         * @param {number} [limit] 
         * @param {'USER' | 'SYSTEM'} [authorType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestCommentsByReference(rezenObject: Array<RezenObject>, limit?: number, authorType?: 'USER' | 'SYSTEM', options?: any): AxiosPromise<LatestCommentsResponse> {
            return localVarFp.getLatestCommentsByReference(rezenObject, limit, authorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a new comment. Container fields (id and type) are required.
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'USER' | 'SYSTEM'} authorType 
         * @param {PostCommentBody} postCommentBody 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {string} [refersToName] 
         * @param {string} [callerGroupId] 
         * @param {boolean} [triggerActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', postCommentBody: PostCommentBody, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, options?: any): AxiosPromise<CommentDto> {
            return localVarFp.postComment(containerType, containerId, authorType, postCommentBody, refersToType, refersToId, refersToName, callerGroupId, triggerActivity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a batch of comments. Container fields (id and type) are required.
         * @param {BatchCommentRequest} batchCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsBatch(batchCommentRequest: BatchCommentRequest, options?: any): AxiosPromise<BatchCommentResponse> {
            return localVarFp.postCommentsBatch(batchCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for comments
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
         * @param {string} [refersToId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe1(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, options?: any): AxiosPromise<SseEmitter> {
            return localVarFp.subscribe1(containerType, containerId, refersToType, refersToId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a comment by providing its container (type and id) and the comment id itself
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
         * @param {string} containerId 
         * @param {string} commentId 
         * @param {UpdateCommentBody} updateCommentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, updateCommentBody: UpdateCommentBody, options?: any): AxiosPromise<CommentDto> {
            return localVarFp.updateComment(containerType, containerId, commentId, updateCommentBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * 
     * @summary Delete a comment by providing its container (type and id) and the comment id itself
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public deleteComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).deleteComment(containerType, containerId, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find the authorized users that can see the provided comment
     * @param {string} commentId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getAuthorizedUsersForComment(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getAuthorizedUsersForComment(commentId, containerType, containerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find the notifiable users for the provided comment
     * @param {string} commentId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getCommentListeners(commentId: string, containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getCommentListeners(commentId, containerType, containerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all comments within a particular Rezen container entity (such as a Transaction), including any comments on Rezen reference entities owned by the container (such as Checklist Items). Comments are returned in descending order of creation date
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {number} [pageSize] 
     * @param {string} [pageStart] 
     * @param {'USER' | 'SYSTEM'} [authorType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getCommentsByContainer(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getCommentsByContainer(containerType, containerId, pageSize, pageStart, authorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all comments on a particular Rezen reference entity (such as a Checklist Item) owned by the container object. This endpoint queries on Dynamo\'s secondary index and returns comments in descending order of creation date
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} refersToType 
     * @param {string} refersToId 
     * @param {number} [pageSize] 
     * @param {string} [pageStart] 
     * @param {'USER' | 'SYSTEM'} [authorType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getCommentsByReference(refersToType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId: string, pageSize?: number, pageStart?: string, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getCommentsByReference(refersToType, refersToId, pageSize, pageStart, authorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the number of comments on each provided Rezen reference entity (such as a Checklist Item).Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
     * @param {Array<string>} refersToTargets 
     * @param {'USER' | 'SYSTEM'} [authorType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getCommentsByReferenceCount(refersToTargets: Array<string>, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getCommentsByReferenceCount(refersToTargets, authorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the latest comment for each provided Rezen reference entity (such as a Checklist Item). Note the format for the parameters is {TWO-DIGIT-ID}#{UUID}. For example: \'CI#4b7083ba-c858-48e8-99a4-0ba126e47d59\' or \'TX#1f516043-42c1-4741-b425-f9b45984c9f3\'
     * @param {Array<RezenObject>} rezenObject 
     * @param {number} [limit] 
     * @param {'USER' | 'SYSTEM'} [authorType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getLatestCommentsByReference(rezenObject: Array<RezenObject>, limit?: number, authorType?: 'USER' | 'SYSTEM', options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getLatestCommentsByReference(rezenObject, limit, authorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a new comment. Container fields (id and type) are required.
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {'USER' | 'SYSTEM'} authorType 
     * @param {PostCommentBody} postCommentBody 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
     * @param {string} [refersToId] 
     * @param {string} [refersToName] 
     * @param {string} [callerGroupId] 
     * @param {boolean} [triggerActivity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public postComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, authorType: 'USER' | 'SYSTEM', postCommentBody: PostCommentBody, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, refersToName?: string, callerGroupId?: string, triggerActivity?: boolean, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).postComment(containerType, containerId, authorType, postCommentBody, refersToType, refersToId, refersToName, callerGroupId, triggerActivity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a batch of comments. Container fields (id and type) are required.
     * @param {BatchCommentRequest} batchCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public postCommentsBatch(batchCommentRequest: BatchCommentRequest, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).postCommentsBatch(batchCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe to Server-Sent Events for comments
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [refersToType] 
     * @param {string} [refersToId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public subscribe1(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, refersToType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', refersToId?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).subscribe1(containerType, containerId, refersToType, refersToId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a comment by providing its container (type and id) and the comment id itself
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} containerType 
     * @param {string} containerId 
     * @param {string} commentId 
     * @param {UpdateCommentBody} updateCommentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public updateComment(containerType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId: string, commentId: string, updateCommentBody: UpdateCommentBody, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).updateComment(containerType, containerId, commentId, updateCommentBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InboxApi - axios parameter creator
 * @export
 */
export const InboxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all messages for a given user Id. Sorted descending from date of last activity
         * @param {string} userId 
         * @param {'ALL' | 'UNREAD' | 'WITH_UPLOADS'} [filter] 
         * @param {string} [searchText] 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForUser: async (userId: string, filter?: 'ALL' | 'UNREAD' | 'WITH_UPLOADS', searchText?: string, pageSize?: number, pageStart?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMessagesForUser', 'userId', userId)
            const localVarPath = `/api/v1/inbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageStart !== undefined) {
                localVarQueryParameter['pageStart'] = pageStart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get number of unread messages for a given user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCountForUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUnreadMessageCountForUser', 'userId', userId)
            const localVarPath = `/api/v1/inbox/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as read
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsRead: async (userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('markMessageAsRead', 'userId', userId)
            const localVarPath = `/api/v1/inbox/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (targetId !== undefined) {
                localVarQueryParameter['targetId'] = targetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as unread
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsUnread: async (userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('markMessageAsUnread', 'userId', userId)
            const localVarPath = `/api/v1/inbox/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (targetId !== undefined) {
                localVarQueryParameter['targetId'] = targetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxApi - functional programming interface
 * @export
 */
export const InboxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all messages for a given user Id. Sorted descending from date of last activity
         * @param {string} userId 
         * @param {'ALL' | 'UNREAD' | 'WITH_UPLOADS'} [filter] 
         * @param {string} [searchText] 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesForUser(userId: string, filter?: 'ALL' | 'UNREAD' | 'WITH_UPLOADS', searchText?: string, pageSize?: number, pageStart?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageQueryResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesForUser(userId, filter, searchText, pageSize, pageStart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get number of unread messages for a given user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadMessageCountForUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageCountRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadMessageCountForUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as read
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsRead(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsRead(userId, targetType, targetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as unread
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsUnread(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsUnread(userId, targetType, targetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InboxApi - factory interface
 * @export
 */
export const InboxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all messages for a given user Id. Sorted descending from date of last activity
         * @param {string} userId 
         * @param {'ALL' | 'UNREAD' | 'WITH_UPLOADS'} [filter] 
         * @param {string} [searchText] 
         * @param {number} [pageSize] 
         * @param {string} [pageStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForUser(userId: string, filter?: 'ALL' | 'UNREAD' | 'WITH_UPLOADS', searchText?: string, pageSize?: number, pageStart?: string, options?: any): AxiosPromise<MessageQueryResDto> {
            return localVarFp.getMessagesForUser(userId, filter, searchText, pageSize, pageStart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get number of unread messages for a given user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCountForUser(userId: string, options?: any): AxiosPromise<MessageCountRes> {
            return localVarFp.getUnreadMessageCountForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as read
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsRead(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.markMessageAsRead(userId, targetType, targetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a given message or all messages for a user + target as unread
         * @param {string} userId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsUnread(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.markMessageAsUnread(userId, targetType, targetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxApi - object-oriented interface
 * @export
 * @class InboxApi
 * @extends {BaseAPI}
 */
export class InboxApi extends BaseAPI {
    /**
     * 
     * @summary Get all messages for a given user Id. Sorted descending from date of last activity
     * @param {string} userId 
     * @param {'ALL' | 'UNREAD' | 'WITH_UPLOADS'} [filter] 
     * @param {string} [searchText] 
     * @param {number} [pageSize] 
     * @param {string} [pageStart] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public getMessagesForUser(userId: string, filter?: 'ALL' | 'UNREAD' | 'WITH_UPLOADS', searchText?: string, pageSize?: number, pageStart?: string, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).getMessagesForUser(userId, filter, searchText, pageSize, pageStart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get number of unread messages for a given user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public getUnreadMessageCountForUser(userId: string, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).getUnreadMessageCountForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a given message or all messages for a user + target as read
     * @param {string} userId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
     * @param {string} [targetId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public markMessageAsRead(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).markMessageAsRead(userId, targetType, targetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a given message or all messages for a user + target as unread
     * @param {string} userId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [targetType] 
     * @param {string} [targetId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public markMessageAsUnread(userId: string, targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', targetId?: string, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).markMessageAsUnread(userId, targetType, targetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createAtFrom?: number, createAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createAtFrom !== undefined) {
                localVarQueryParameter['createAtFrom'] = createAtFrom;
            }

            if (createAtTo !== undefined) {
                localVarQueryParameter['createAtTo'] = createAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (topic?: string, key?: string, createAtFrom?: number, createAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (createAtFrom !== undefined) {
                localVarQueryParameter['createAtFrom'] = createAtFrom;
            }

            if (createAtTo !== undefined) {
                localVarQueryParameter['createAtTo'] = createAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createAtFrom?: number, createAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createAtFrom, createAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(topic?: string, key?: string, createAtFrom?: number, createAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(topic, key, createAtFrom, createAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createAtFrom?: number, createAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createAtFrom, createAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(topic?: string, key?: string, createAtFrom?: number, createAtTo?: number, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(topic, key, createAtFrom, createAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createAtFrom] 
     * @param {number} [createAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createAtFrom?: number, createAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createAtFrom, createAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {number} [createAtFrom] 
     * @param {number} [createAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(topic?: string, key?: string, createAtFrom?: number, createAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(topic, key, createAtFrom, createAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoiceCallControllerApi - axios parameter creator
 * @export
 */
export const VoiceCallControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Setup a new voice call
         * @param {CreateVoiceCall} createVoiceCall 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVoiceCall: async (createVoiceCall: CreateVoiceCall, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVoiceCall' is not null or undefined
            assertParamExists('createVoiceCall', 'createVoiceCall', createVoiceCall)
            const localVarPath = `/api/v1/voice/calls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVoiceCall, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get voice-call details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceCall: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVoiceCall', 'id', id)
            const localVarPath = `/api/v1/voice/calls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send the dial number for the voice-call to the caller
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsDialNumber: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('smsDialNumber', 'id', id)
            const localVarPath = `/api/v1/voice/calls/{id}/sms-dial-number`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for voice calls
         * @param {string} calleeUserId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [containerType] 
         * @param {string} [containerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe: async (calleeUserId: string, containerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calleeUserId' is not null or undefined
            assertParamExists('subscribe', 'calleeUserId', calleeUserId)
            const localVarPath = `/api/v1/voice/calls/sse-subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (calleeUserId !== undefined) {
                localVarQueryParameter['calleeUserId'] = calleeUserId;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoiceCallControllerApi - functional programming interface
 * @export
 */
export const VoiceCallControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoiceCallControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Setup a new voice call
         * @param {CreateVoiceCall} createVoiceCall 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVoiceCall(createVoiceCall: CreateVoiceCall, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceCallDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVoiceCall(createVoiceCall, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get voice-call details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoiceCall(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceCallDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoiceCall(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send the dial number for the voice-call to the caller
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smsDialNumber(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smsDialNumber(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for voice calls
         * @param {string} calleeUserId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [containerType] 
         * @param {string} [containerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribe(calleeUserId: string, containerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribe(calleeUserId, containerType, containerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoiceCallControllerApi - factory interface
 * @export
 */
export const VoiceCallControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoiceCallControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Setup a new voice call
         * @param {CreateVoiceCall} createVoiceCall 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVoiceCall(createVoiceCall: CreateVoiceCall, options?: any): AxiosPromise<VoiceCallDto> {
            return localVarFp.createVoiceCall(createVoiceCall, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get voice-call details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceCall(id: string, options?: any): AxiosPromise<VoiceCallDto> {
            return localVarFp.getVoiceCall(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send the dial number for the voice-call to the caller
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsDialNumber(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.smsDialNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe to Server-Sent Events for voice calls
         * @param {string} calleeUserId 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [containerType] 
         * @param {string} [containerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe(calleeUserId: string, containerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId?: string, options?: any): AxiosPromise<SseEmitter> {
            return localVarFp.subscribe(calleeUserId, containerType, containerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoiceCallControllerApi - object-oriented interface
 * @export
 * @class VoiceCallControllerApi
 * @extends {BaseAPI}
 */
export class VoiceCallControllerApi extends BaseAPI {
    /**
     * 
     * @summary Setup a new voice call
     * @param {CreateVoiceCall} createVoiceCall 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public createVoiceCall(createVoiceCall: CreateVoiceCall, options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).createVoiceCall(createVoiceCall, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get voice-call details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public getVoiceCall(id: string, options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).getVoiceCall(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send the dial number for the voice-call to the caller
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public smsDialNumber(id: string, options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).smsDialNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe to Server-Sent Events for voice calls
     * @param {string} calleeUserId 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} [containerType] 
     * @param {string} [containerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCallControllerApi
     */
    public subscribe(calleeUserId: string, containerType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', containerId?: string, options?: AxiosRequestConfig) {
        return VoiceCallControllerApiFp(this.configuration).subscribe(calleeUserId, containerType, containerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoiceRecordingControllerApi - axios parameter creator
 * @export
 */
export const VoiceRecordingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get voice recording details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceRecording: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVoiceRecording', 'id', id)
            const localVarPath = `/api/v1/voice/recordings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get voice recording signed url
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceRecordingSignedUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVoiceRecordingSignedUrl', 'id', id)
            const localVarPath = `/api/v1/voice/recordings/{id}/signed-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update speakers for a given recording
         * @param {string} id 
         * @param {Speakers} speakers 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpeakers: async (id: string, speakers: Speakers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSpeakers', 'id', id)
            // verify required parameter 'speakers' is not null or undefined
            assertParamExists('updateSpeakers', 'speakers', speakers)
            const localVarPath = `/api/v1/voice/recordings/{id}/speakers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(speakers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoiceRecordingControllerApi - functional programming interface
 * @export
 */
export const VoiceRecordingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoiceRecordingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get voice recording details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoiceRecording(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceRecordingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoiceRecording(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get voice recording signed url
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoiceRecordingSignedUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoiceRecordingSignedUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update speakers for a given recording
         * @param {string} id 
         * @param {Speakers} speakers 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSpeakers(id: string, speakers: Speakers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSpeakers(id, speakers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoiceRecordingControllerApi - factory interface
 * @export
 */
export const VoiceRecordingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoiceRecordingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get voice recording details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceRecording(id: string, options?: any): AxiosPromise<VoiceRecordingDto> {
            return localVarFp.getVoiceRecording(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get voice recording signed url
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceRecordingSignedUrl(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getVoiceRecordingSignedUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update speakers for a given recording
         * @param {string} id 
         * @param {Speakers} speakers 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpeakers(id: string, speakers: Speakers, options?: any): AxiosPromise<void> {
            return localVarFp.updateSpeakers(id, speakers, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoiceRecordingControllerApi - object-oriented interface
 * @export
 * @class VoiceRecordingControllerApi
 * @extends {BaseAPI}
 */
export class VoiceRecordingControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get voice recording details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceRecordingControllerApi
     */
    public getVoiceRecording(id: string, options?: AxiosRequestConfig) {
        return VoiceRecordingControllerApiFp(this.configuration).getVoiceRecording(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get voice recording signed url
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceRecordingControllerApi
     */
    public getVoiceRecordingSignedUrl(id: string, options?: AxiosRequestConfig) {
        return VoiceRecordingControllerApiFp(this.configuration).getVoiceRecordingSignedUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update speakers for a given recording
     * @param {string} id 
     * @param {Speakers} speakers 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceRecordingControllerApi
     */
    public updateSpeakers(id: string, speakers: Speakers, options?: AxiosRequestConfig) {
        return VoiceRecordingControllerApiFp(this.configuration).updateSpeakers(id, speakers, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoiceVirtualNumberControllerApi - axios parameter creator
 * @export
 */
export const VoiceVirtualNumberControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate a virtual number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activate', 'id', id)
            const localVarPath = `/api/v1/voice/virtual-numbers/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new virtual-number
         * @param {CreateVirtualNumber} createVirtualNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVirtualNumber: async (createVirtualNumber: CreateVirtualNumber, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVirtualNumber' is not null or undefined
            assertParamExists('createVirtualNumber', 'createVirtualNumber', createVirtualNumber)
            const localVarPath = `/api/v1/voice/virtual-numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVirtualNumber, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate a virtual number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivate', 'id', id)
            const localVarPath = `/api/v1/voice/virtual-numbers/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get virtual-number details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVirtualNumber: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVirtualNumber', 'id', id)
            const localVarPath = `/api/v1/voice/virtual-numbers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a virtual number
         * @param {string} id 
         * @param {UpdateVirtualNumberRequest} updateVirtualNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateVirtualNumberRequest: UpdateVirtualNumberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateVirtualNumberRequest' is not null or undefined
            assertParamExists('update', 'updateVirtualNumberRequest', updateVirtualNumberRequest)
            const localVarPath = `/api/v1/voice/virtual-numbers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVirtualNumberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoiceVirtualNumberControllerApi - functional programming interface
 * @export
 */
export const VoiceVirtualNumberControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoiceVirtualNumberControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate a virtual number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new virtual-number
         * @param {CreateVirtualNumber} createVirtualNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVirtualNumber(createVirtualNumber: CreateVirtualNumber, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVirtualNumber(createVirtualNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate a virtual number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get virtual-number details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVirtualNumber(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVirtualNumber(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a virtual number
         * @param {string} id 
         * @param {UpdateVirtualNumberRequest} updateVirtualNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateVirtualNumberRequest: UpdateVirtualNumberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceVirtualNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateVirtualNumberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoiceVirtualNumberControllerApi - factory interface
 * @export
 */
export const VoiceVirtualNumberControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoiceVirtualNumberControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate a virtual number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(id: string, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.activate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new virtual-number
         * @param {CreateVirtualNumber} createVirtualNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVirtualNumber(createVirtualNumber: CreateVirtualNumber, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.createVirtualNumber(createVirtualNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate a virtual number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate(id: string, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.deactivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get virtual-number details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVirtualNumber(id: string, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.getVirtualNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a virtual number
         * @param {string} id 
         * @param {UpdateVirtualNumberRequest} updateVirtualNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateVirtualNumberRequest: UpdateVirtualNumberRequest, options?: any): AxiosPromise<VoiceVirtualNumberDto> {
            return localVarFp.update(id, updateVirtualNumberRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoiceVirtualNumberControllerApi - object-oriented interface
 * @export
 * @class VoiceVirtualNumberControllerApi
 * @extends {BaseAPI}
 */
export class VoiceVirtualNumberControllerApi extends BaseAPI {
    /**
     * 
     * @summary Activate a virtual number
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public activate(id: string, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).activate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new virtual-number
     * @param {CreateVirtualNumber} createVirtualNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public createVirtualNumber(createVirtualNumber: CreateVirtualNumber, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).createVirtualNumber(createVirtualNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate a virtual number
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public deactivate(id: string, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).deactivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get virtual-number details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public getVirtualNumber(id: string, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).getVirtualNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a virtual number
     * @param {string} id 
     * @param {UpdateVirtualNumberRequest} updateVirtualNumberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceVirtualNumberControllerApi
     */
    public update(id: string, updateVirtualNumberRequest: UpdateVirtualNumberRequest, options?: AxiosRequestConfig) {
        return VoiceVirtualNumberControllerApiFp(this.configuration).update(id, updateVirtualNumberRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


