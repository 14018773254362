import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import config from '../config';
import { getAuthCookie } from './AuthUtils';

class AxiosService {
  private static instance: AxiosService;
  private axiosInstance: AxiosInstance;

  private constructor(baseURL: string = config.apiBasePath.atlantis) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'X-real-app-name': 'one-real-web',
        'X-real-app-version': config.deployVersion,
      },
    });

    this.axiosInstance.interceptors.request.use(
      this.handleRequest,
      this.handleError,
    );
    this.axiosInstance.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );
  }

  public static getInstance = (): AxiosService => {
    if (!AxiosService.instance) {
      AxiosService.instance = new AxiosService();
    }

    return AxiosService.instance;
  };

  public setBaseURL = (newBaseURL: string): AxiosService => {
    this.axiosInstance.defaults.baseURL = newBaseURL;
    return this;
  };

  public get = (
    url: string,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse> => {
    return this.axiosInstance.get(url, config);
  };

  public post = (
    url: string,
    data: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse> => {
    return this.axiosInstance.post(url, data, config);
  };

  private handleRequest = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> => {
    config.headers.Authorization = `Bearer ${getAuthCookie()}`;
    return config;
  };

  private handleResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  private handleError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  };
}

export default AxiosService.getInstance();
