import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { ISelectOption } from '../../types';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

export interface SelectImageOption extends ISelectOption {
  image?: string;
}

export interface ControlledMultiSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  options: SelectImageOption[];
}

const ControlledMultiSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  options,
  shouldUnregister = true,
  ...controllerProps
}: ControlledMultiSelectInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  return (
    <div>
      {label && (
        <div className='mb-4'>
          <ControlledInputLabelComponent label={label} />
        </div>
      )}
      <div>
        <p className='font-inter-regular text-sm text-gray-5'>
          Select all that apply.
        </p>
        <div className=' flex flex-col mt-2'>
          {(options || []).map((option, index) => {
            const isLastOption = index === options.length - 1;
            const isSelected = !!(value || [])?.find(
              (v: string) => v === option.value,
            );

            return (
              <div
                key={option.label}
                className={classNames(isLastOption ? 'mb-0' : 'mb-5')}
              >
                <button
                  onClick={() => {
                    const values = isSelected
                      ? (value || []).filter((v: string) => v !== option.value)
                      : [...(value || []), option.value];
                    onChange(values.length ? values : '');
                  }}
                  disabled={option.disabled}
                  className={classNames([
                    'flex flex-row justify-between items-center rounded-xl w-full px-4 py-3',
                    isSelected
                      ? 'border-2 border-primary-skyblue bg-primary-skyblue bg-opacity-10'
                      : 'bg-transparent border border-silver',
                  ])}
                  data-testid={`select-option-${index}`}
                >
                  <div className='flex flex-row items-center'>
                    {!!option.image && (
                      <img
                        src={option.image}
                        className='w-6 h-6 mr-3 object-contain'
                        alt='icon'
                        data-testid={`select-image-${index}`}
                      />
                    )}
                    <span>{option.label}</span>
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    fontSize={24}
                    data-testid={`select-icon-${index}`}
                    className={
                      isSelected ? 'text-primary-skyblue' : 'text-silver'
                    }
                  />
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledMultiSelectInput;
