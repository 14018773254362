import {
  faChevronRight,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoanStatus from '../LoanStatus';
import { LoanCardProps } from '../LoanCard';

const LoanCardGemini: React.FC<LoanCardProps> = ({ loan, onPress }) => {
  return (
    <div
      onClick={() => onPress(loan)}
      className='bg-white cursor-pointer rounded-xl p-5 border border-silver'
    >
      <div>
        <div className='flex justify-between items-center'>
          <div className='font-inter-semibold text-xs text-primary-gray font-bold'>
            {loan.loanType}
          </div>
          <div className='font-inter-medium text-sm'>
            <LoanStatus status={loan.status} />
          </div>
        </div>
        <div className='border-b my-2 border-silver' />
        <div className='flex justify-between items-center'>
          {loan.propertyAddress ? (
            <div className='font-inter-medium text-base'>
              {loan.propertyAddress}
            </div>
          ) : (
            <div className='flex flex-row items-center'>
              <FontAwesomeIcon icon={faCircleExclamation} size='lg' />
              <div className='font-inter-light text-base ml-2'>
                The property address has not been entered yet.
              </div>
            </div>
          )}
          <div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanCardGemini;
