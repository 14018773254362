import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { ISelectOption } from '../../types';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

export interface SelectImageOption extends ISelectOption {
  image: string;
}

export interface ControlledSingleSelectImageInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  options: SelectImageOption[];
}

const ControlledSingleSelectImageInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  options,
  name,
  shouldUnregister = true,
  ...controllerProps
}: ControlledSingleSelectImageInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    name,
    ...controllerProps,
    shouldUnregister,
  });

  return (
    <div>
      {label && (
        <div className='mb-4'>
          <ControlledInputLabelComponent label={label} />
        </div>
      )}
      <div className='flex flex-row flex-wrap'>
        {(options || []).map((option, index) => {
          const isSelected = option.value === value;
          const lastRowIndex = options.length % 2 === 0 ? 3 : 2;
          const isLastRow = index > options.length - lastRowIndex;

          return (
            <button
              key={option.value}
              className={classNames([
                'w-1/2',
                index % 2 === 0 ? 'pr-2' : 'pl-2',
                isLastRow ? 'pb-0' : 'pb-4',
              ])}
              onClick={() => {
                if (!isSelected) {
                  onChange(option.value);
                }
              }}
            >
              <div
                data-testid={`select-icon-${index}`}
                className={classNames([
                  'flex flex-col justify-center items-center rounded-xl py-4 px-2 relative md:h-32 h-28',
                  isSelected
                    ? 'border-2 border-primary-skyblue bg-primary-skyblue bg-opacity-10 shadow-lg shadow-gray-300'
                    : 'bg-transparent border border-primary-navy',
                ])}
              >
                {isSelected && (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    fontSize={24}
                    className='absolute top-2 right-2 text-primary-skyblue'
                  />
                )}
                <img
                  src={option.image}
                  alt=''
                  className='h-10 w-10 object-contain'
                  data-testid='select-image'
                />
                <p
                  className={classNames([
                    'font-inter-medium text-sm mt-2 text-center text-black',
                  ])}
                >
                  {option.label}
                </p>
              </div>
            </button>
          );
        })}
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledSingleSelectImageInput;
