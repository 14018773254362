export default class ForceReportingError
  extends Error
  implements ForceReportingError
{
  public forceReporting: boolean;
  constructor(message: string) {
    super(message);
    this.forceReporting = true;
  }
}
