import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { ToastNotificationData } from '../types';

export interface ToastNotificationProps extends ToastNotificationData {
  onClose(): void;
}

const ToastNotification: React.FC<ToastNotificationProps> = ({
  type,
  title,
  subtitle,
  onClose,
}) => {
  return (
    <div className='flex items-center bg-gray-4 rounded-lg text-white p-4 w-full max-w-md'>
      <div className='mr-2'>
        {type === 'success' && (
          <FontAwesomeIcon icon={faCircleCheck} className='text-green-700' />
        )}
        {type === 'warning' && (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className='text-yellow-600'
          />
        )}
        {type === 'info' && (
          <FontAwesomeIcon
            icon={faCircleInfo}
            className='text-primary-skyblue'
          />
        )}
        {type === 'error' && (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className='text-primary-coral'
          />
        )}
      </div>
      <div className='flex-grow mr-2'>
        <p className='font-inter-regular'>{title}</p>
        {subtitle && <p className='font-inter-regular'>{subtitle}</p>}
      </div>
      <button type='button' onClick={onClose} className='cursor-pointer'>
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  );
};

export default ToastNotification;
