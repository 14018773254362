import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { deleteFileFromDropbox } from '../slices/DropboxSlice';
import { useAppDispatch, useAppSelector } from '../slices/store';
import { bytesToSize } from '../utils/FileUtils';
import DefaultLoader from './DefaultLoader';

interface FileRowProps {
  file: any;
}

const FileRow: React.FC<FileRowProps> = ({ file }) => {
  const dispatch = useAppDispatch();
  const { borrowerDetail } = useAppSelector((state) => state.loan);
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>();

  return (
    <div
      key={file.id}
      className='flex flex-row items-center justify-between pb-4'
    >
      <p className='font-inter-medium text-xs text-black w-11/12'>
        {file.filename}{' '}
        <p className='text-primary-gray'>({bytesToSize(file.length!)})</p>
      </p>
      <button
        onClick={async () => {
          setIsDeletingFile(true);
          await dispatch(deleteFileFromDropbox(borrowerDetail!, file?.id!));
          setIsDeletingFile(false);
        }}
        disabled={!!isDeletingFile}
      >
        {isDeletingFile ? (
          <DefaultLoader iconSize={10} noPadding />
        ) : (
          <FontAwesomeIcon
            icon={faTrash}
            fontSize={12}
            className='text-primary-gray'
          />
        )}
      </button>
    </div>
  );
};

export default FileRow;
