import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App.tsx';
import config from './config.ts';
import './index.css';
import Route500 from './routes/Route500.tsx';
import AnalyticsService from './services/AnalyticsService.ts';
import store from './slices/store.ts';
import {
  isDatadogErrorEventValid,
  isEventValid,
} from './utils/BugsnagHelper.ts';
import Logger from './utils/Logger.ts';
import { getTracingUrls } from './utils/datadog/datadogutils.ts';

AnalyticsService.instance().initialize();

Bugsnag.start({
  apiKey: config.bugsnag.apiKey,
  plugins: [new BugsnagPluginReact()],
  releaseStage: config.bugsnag.releaseStage,
  enabledReleaseStages: ['production', 'stage', 'play'],
  appVersion: config.bugsnag.deployVersion,
  redactedKeys: ['password', 'confirmPassword'],
  onError: (event: Event) => {
    Logger.debug('Bugsnag error to be reported:', event);

    if (isEventValid(event)) {
      Logger.debug('Bugsnag event is valid. Reporting.');
      return true;
    }

    Logger.debug('Bugsnag event is not valid. Ignoring.');
    return false;
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

Logger.debug(
  'Running version ',
  config.bugsnag.deployVersion,
  ' in ',
  config.bugsnag.releaseStage,
  ' environment.',
);

const enabledReleaseStages = ['production', 'stage', 'play'];
const isErrorTrackingEnabled = enabledReleaseStages.includes(
  config.bugsnag.releaseStage,
);

datadogRum.init({
  applicationId: config.datadog.applicationId,
  clientToken: config.datadog.clientToken,
  site: 'datadoghq.com',
  service: 'onereal-mortgage-web',
  env: config.bugsnag.releaseStage,
  version: config.datadog.deployVersion,
  sessionSampleRate: config.datadog.sessionSampleRate,
  sessionReplaySampleRate: config.datadog.sessionReplaySampleRate,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: getTracingUrls(),
  beforeSend: (event) => {
    const canTriggerError =
      isErrorTrackingEnabled && isDatadogErrorEventValid(event);

    if (canTriggerError) {
      return true;
    }

    return false;
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ErrorBoundary FallbackComponent={Route500}>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
);
