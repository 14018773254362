import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import ErrorService from '../../services/ErrorService';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { useAppDispatch } from '../../slices/store';
import AxiosService from '../../utils/AxiosService';
import { getCommonsApiErrorMessage } from '../../utils/ErrorUtils';
import Button from '../Button';

export interface ControlledUrlRequestInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label: string;
  urlSourcePath: string;
}

const ControlledUrlRequestInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  urlSourcePath,
  shouldUnregister = true,
  ...controllerProps
}: ControlledUrlRequestInputProps<TFieldValues, TName>) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    field: { onChange },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });
  const dispatch = useAppDispatch();
  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await AxiosService.get(urlSourcePath);
      onChange(true);
      await window.open(data, '_self');
    } catch (e: any) {
      ErrorService.notify('Unable to fetch finicity connect url', e);
      dispatch(
        showErrorToast(
          `Unable to fetch finicity connect url`,
          getCommonsApiErrorMessage(e),
        ),
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Button
        label={label}
        onPress={onSubmit}
        isSubmitting={isSubmitting}
        textSize='sm'
        variant='primary-outline'
        leftIcon={
          <FontAwesomeIcon icon={faLink} fontSize={20} className='text-black' />
        }
      />
    </div>
  );
};

export default ControlledUrlRequestInput;
