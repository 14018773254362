import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

export type ButtonVariant = 'primary' | 'primary-outline' | 'no-border';

export type GradientVariant = 'mintyfresh';

export type SizeVariant = 'xs' | 'sm' | 'md';

export interface ButtonProps {
  label: string;
  variant?: ButtonVariant;
  buttonSize?: SizeVariant;
  textSize?: SizeVariant;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  containerStyle?: string;
  textStyle?: string;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  fullWidth?: boolean;
  gradientVariant?: GradientVariant;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onPress(): void;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onPress,
  variant = 'primary',
  buttonSize = 'md',
  textSize = 'md',
  leftIcon,
  rightIcon,
  containerStyle,
  textStyle,
  isDisabled = false,
  isSubmitting = false,
  fullWidth = false,
  type,
  gradientVariant,
}) => {
  const variantClassMap: Record<ButtonVariant, string> = {
    primary: 'bg-primary-skyblue',
    'primary-outline': 'bg-transparent border border-steelblue',
    'no-border': 'bg-transparent !px-0 !py-0',
  };

  const disabledVariantClassMap: Record<ButtonVariant, string> = {
    primary: 'bg-steelblue',
    'primary-outline': 'bg-transparent border border-steelblue',
    'no-border': 'bg-transparent',
  };

  const textVariantClassMap: Record<ButtonVariant, string> = {
    primary: 'text-white',
    'no-border': 'text-primary-navy',
    'primary-outline': 'font-poppins-light text-black',
  };

  const disabledTextVariantClassMap: Record<ButtonVariant, string> = {
    primary: 'text-white',
    'no-border': 'text-steelblue',
    'primary-outline': 'opacity-50',
  };

  const buttonSizeVariantClassMap: Record<SizeVariant, string> = {
    xs: 'px-4 py-2 rounded-lg',
    sm: 'px-6 py-3 rounded-xl',
    md: 'px-8 py-4 rounded-2xl',
  };

  const textSizeVariantClassMap: Record<SizeVariant, string> = {
    xs: 'text-sm',
    sm: 'text-base',
    md: 'text-lg',
  };
  const disabled = isDisabled || isSubmitting;

  const gradientVariantClassMap: Record<GradientVariant, string> = {
    mintyfresh: disabled
      ? 'linear-gradient(20deg, #D2D5DB 0%, #D2D5DB 30%)'
      : 'linear-gradient(20deg, #83c8d0 0%, #61bfed 30%)',
  };

  return (
    <button
      onClick={onPress}
      disabled={disabled}
      className={classNames([fullWidth && 'w-full'])}
      type={type}
      data-testid={`button-${label}`}
    >
      <div
        className={classNames([
          'flex flex-row items-center justify-center',
          buttonSizeVariantClassMap[buttonSize],
          variantClassMap[variant!],
          containerStyle,
          disabled && disabledVariantClassMap[variant],
        ])}
        style={{ background: gradientVariantClassMap[gradientVariant!] }}
      >
        {isSubmitting && leftIcon && (
          <FontAwesomeIcon
            icon={faArrowsRotate}
            fontSize={18}
            className='text-primary-navy opacity-50 animate-spin mr-2'
          />
        )}
        {!isSubmitting && leftIcon && (
          <div className='mr-2'>
            {React.cloneElement(leftIcon, {
              className: classNames([
                leftIcon.props.className,
                disabled && disabledTextVariantClassMap[variant],
              ]),
            })}
          </div>
        )}
        <p
          className={classNames([
            'font-poppins-medium',
            textSizeVariantClassMap[textSize],
            textVariantClassMap[variant],
            textStyle,
            disabled && disabledTextVariantClassMap[variant],
          ])}
        >
          {label}
        </p>
        {isSubmitting && !leftIcon && (
          <FontAwesomeIcon
            icon={faArrowsRotate}
            fontSize={18}
            className='text-primary-navy opacity-50 animate-spin ml-2'
          />
        )}
        {!isSubmitting && rightIcon && (
          <div className='ml-2'>
            {React.cloneElement(rightIcon, {
              className: classNames([
                rightIcon.props?.className,
                disabled && disabledTextVariantClassMap[variant],
              ]),
            })}
          </div>
        )}
      </div>
    </button>
  );
};

export default Button;
