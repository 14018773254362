import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { toggleChat } from '../slices/LoanSlice';
import { useAppDispatch } from '../slices/store';

interface ChatRouteProps {}

const ChatRoute: React.FC<ChatRouteProps> = () => {
  const { borrowerId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleChat(true));
  }, [dispatch]);

  return <Navigate to={`/pre-approval-form/${borrowerId}`} replace />;
};

export default ChatRoute;
