import { DateTime } from 'luxon';
import React from 'react';
import classNames from 'classnames';
import { CommentDto } from '../../openapi/yada';
import { useAppSelector } from '../../slices/store';
import { isTextBlock } from '../../utils/TypeUtils';

interface ChatMessageProps {
  comment: CommentDto;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ comment }) => {
  const { authUserDetail } = useAppSelector((state) => state.auth);
  const isSelf = comment.createdById === authUserDetail?.keyMakerId;

  return (
    <div
      className={classNames([
        'flex flex-col',
        isSelf ? 'items-end' : 'items-start',
      ])}
    >
      <div
        className={classNames([
          isSelf ? 'bg-primary-mint' : 'bg-white',
          'p-4 rounded-b-xl rounded-tr-xl lg:mt-5 mt-3 shadow-md',
        ])}
      >
        {comment.richContent?.blocks?.map((block, index) => {
          if (isTextBlock(block)) {
            return (
              <p
                key={[block.preview!, index].join()}
                className='font-inter-medium text-sm text-black'
              >
                {block.preview}
              </p>
            );
          }

          return (
            <p
              key={[block.preview!, index].join()}
              className='font-inter-medium text-sm text-black'
            >
              {block.preview}
            </p>
          );
        })}
      </div>
      <div className='pt-2'>
        <p className='font-inter-medium text-xs text-gray-3'>
          {DateTime.fromMillis(comment.createdAt || 0).toLocaleString(
            DateTime.TIME_SIMPLE,
          )}
        </p>
      </div>
    </div>
  );
};

export default ChatMessage;
