import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthControllerApi } from '../openapi/keymaker';
import {
  UserControllerApi,
  UserResponse,
  UserResponseTypeEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService.ts';
import { AppThunk, AuthState } from '../types';
import { resetApp } from '../utils/authActions';
import { deleteAuthCookie, setUserInLogServices } from '../utils/AuthUtils';
import Logger from '../utils/Logger';
import {
  getKeymakerConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { fetchLoans } from './LoanSlice.ts';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: AuthState = {
  authUserDetail: undefined,
  appCompatible: false,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveAuthUserDetail(state, action: PayloadAction<UserResponse | undefined>) {
      state.authUserDetail = action.payload;
    },
    saveAppCompatibility(state, action: PayloadAction<boolean>) {
      state.appCompatible = action.payload;
    },
  },
});

export const { saveAuthUserDetail, saveAppCompatibility } = AuthSlice.actions;

export const fetchAuthUserDetail =
  (leadId?: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const { data } = await new UserControllerApi(
        await getYentaConfiguration(),
      ).getCurrentGenericUser();

      if (data.type === UserResponseTypeEnum.Generic) {
        const isBorrowerSuccess = await dispatch(fetchLoans(leadId));
        if (!isBorrowerSuccess) {
          Logger.log('Oops! Something went wrong. Please login again.');
          return false;
        }

        setUserInLogServices(data);
        dispatch(saveAuthUserDetail(data));
        return true;
      }

      deleteAuthCookie();
      return false;
    } catch (e: any) {
      Logger.error('Token expired. Please login again', e);
      ErrorService.notify('Token expired. Please login again', e);
      dispatch(showErrorToast('Token expired. Please login again'));
      deleteAuthCookie();
      return false;
    }
  };

export const signOutUser = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new AuthControllerApi(await getKeymakerConfiguration()).signOut();
    deleteAuthCookie();
    dispatch(resetApp());
  } catch (e: any) {
    Logger.error('Unable to logout', e);
    ErrorService.notify('Unable to logout', e);
  }
};

export default AuthSlice.reducer;
