import { UserResponse } from '../openapi/yenta';
import GoogleAnalyticsGateway from './GoogleAnalyticsGateway';
import IAnalyticsService from './interface/IAnalyticsService';

class AnalyticsService implements IAnalyticsService {
  private gateway: IAnalyticsService[];
  private static _instance: AnalyticsService;

  constructor() {
    this.gateway = [new GoogleAnalyticsGateway()];
  }

  public static instance(): AnalyticsService {
    if (!AnalyticsService._instance) {
      AnalyticsService._instance = new AnalyticsService();
    }
    return AnalyticsService._instance;
  }

  initialize() {
    this.gateway.forEach((gateway) => gateway.initialize());
  }

  setUser(userDetail: UserResponse) {
    this.gateway.forEach((gateway) => gateway.setUser(userDetail));
  }

  logEvent(eventName: string, eventData: Record<string, unknown> = {}) {
    this.gateway.forEach((gateway) => gateway.logEvent(eventName, eventData));
  }
}

export default AnalyticsService;
