import { Configuration as AtlantisConfiguration } from '../openapi/atlantis';
import { Configuration as DropboxConfiguration } from '../openapi/dropbox';
import { Configuration as KeymakerConfiguration } from '../openapi/keymaker';
import { Configuration as SherlockConfiguration } from '../openapi/sherlock';
import { Configuration as YadaConfiguration } from '../openapi/yada';
import { Configuration as YentaConfiguration } from '../openapi/yenta';
import config from '../config';
import { getAuthCookie } from './AuthUtils';

const baseOptions = {
  headers: {
    'X-real-app-name': 'one-real-web',
    'X-real-app-version': config.deployVersion,
  },
};

export const getKeymakerConfiguration = async () =>
  new KeymakerConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.keymaker,
    baseOptions,
  });

export const getAtlantisConfiguration = async () =>
  new AtlantisConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.atlantis,
    baseOptions,
  });

export const getDropboxConfiguration = async () =>
  new DropboxConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.dropbox,
    baseOptions,
  });

export const getYadaConfiguration = async () =>
  new YadaConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.yada,
    baseOptions,
  });

export const getYentaConfiguration = async () =>
  new YentaConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.yenta,
    baseOptions,
  });

export const getSherlockConfiguration = async () =>
  new SherlockConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.sherlock,
    baseOptions,
  });
