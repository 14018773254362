import Cookies from 'js-cookie';
import { UserResponse } from '../openapi/yenta';
import AnalyticsService from '../services/AnalyticsService';
import ErrorService from '../services/ErrorService';

export const JWT_TOKEN_COOKIE_NAME = 'jwtToken';

export const getAuthCookie = () => Cookies.get(JWT_TOKEN_COOKIE_NAME);

export const setAuthCookie = (value: string) =>
  Cookies.set(JWT_TOKEN_COOKIE_NAME, value);

export const deleteAuthCookie = () => Cookies.remove(JWT_TOKEN_COOKIE_NAME);

export const setUserInLogServices = (user: UserResponse) => {
  ErrorService.setUser(user);
  AnalyticsService.instance().setUser(user);
};
