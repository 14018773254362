import { FC } from 'react';

interface ControlledInputLabelComponentProps {
  label: string;
}

const ControlledInputLabelComponent: FC<ControlledInputLabelComponentProps> = ({
  label,
}) => {
  return (
    <div className='flex flex-row mb-1'>
      <p className='font-inter-regular text-base text-black'>{label}</p>
    </div>
  );
};

export default ControlledInputLabelComponent;
