import { faEnvelope, faPhoneVolume } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { PHONE_NUMBER } from '../../constants/OneRealMortgageConstants';
import { useAppSelector } from '../../slices/store';
import { getFullName } from '../../utils/commonUtils.ts';
import Avatar from '../Avatar';

interface ChatHeaderProps {}

const ChatHeader: React.FC<ChatHeaderProps> = () => {
  const { loanDetail } = useAppSelector((state) => state.loan);
  const fullName = getFullName(loanDetail?.loanOfficer!);

  return (
    <div className='flex flex-row items-center justify-between p-4 bg-black rounded-2xl'>
      <div className='flex flex-row items-center'>
        <Avatar
          name={fullName}
          size={isMobile ? 'md' : 'xl'}
          status='online'
          statusPosition='bottom'
          variant='circle'
        />
        <div className='ml-2'>
          <p className='font-inter-medium lg:text-base text-sm text-white'>
            {fullName}
          </p>
          <p className='font-inter-medium text-xs text-primary-blue'>Online</p>
        </div>
      </div>
      <div className='flex flex-row'>
        <a
          href={
            loanDetail?.loanOfficer?.emailAddress
              ? `mailto:${loanDetail?.loanOfficer?.emailAddress}`
              : undefined
          }
          target='_top'
          rel='noreferrer'
          className='bg-gray-2 lg:p-3 p-2 rounded-full h-9 w-9 flex justify-center items-center'
        >
          <FontAwesomeIcon icon={faEnvelope} fontSize={20} />
        </a>
        <a
          href={`tel:${PHONE_NUMBER}`}
          target='_blank'
          rel='noreferrer'
          className='bg-gray-2 lg:p-3 p-2 rounded-full h-9 w-9 ml-2 flex justify-center items-center'
        >
          <FontAwesomeIcon icon={faPhoneVolume} fontSize={20} />
        </a>
      </div>
    </div>
  );
};

export default ChatHeader;
