import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import {
  ApplicationProgressResponse,
  ApplicationStep,
  BorrowerDto,
  LoanDto,
} from './openapi/atlantis';
import { DropboxResponse } from './openapi/dropbox';
import { ChecklistResponse } from './openapi/sherlock';
import { UserResponse } from './openapi/yenta';
import rootReducer from './slices/rootReducer';
import store from './slices/store';

export type EnumMap<E extends string, T> = {
  [key in E]: T;
};

export type RootState = ReturnType<typeof rootReducer>;

export interface ReduxStateFactory<T> {
  getState(state: Partial<T>): T;
}

export type AppDispatch = typeof store.dispatch;

export interface DropboxState {
  dropboxByBorrowerId: Record<string, DropboxResponse | undefined>;
}

export interface ApplicationState {
  currentQuestion: ApplicationStep | undefined;
  progress: ApplicationProgressResponse | undefined;
  loanProgress: ChecklistResponse | undefined;
}

export interface AuthState {
  authUserDetail: UserResponse | undefined;
  appCompatible: boolean;
}

export interface LoanState {
  userId: string | undefined;
  loans: LoanDto[] | undefined;
  loanDetail: LoanDto | undefined;
  borrowerDetail: BorrowerDto | undefined;
  toggleChat: boolean;
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export interface ISelectOption<
  Label extends string = string,
  Value extends any = any,
> {
  label: Label;
  value: Value;
  disabled?: boolean;
}

export type ToastNotificationType = 'info' | 'warning' | 'success' | 'error';

export interface ToastNotificationData {
  type: ToastNotificationType;
  title: string;
  subtitle?: string;
}

export interface ToastNotificationState {
  visible: boolean;
  toastData: ToastNotificationData | undefined;
}

export enum ErrorCode {
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
}

export interface ApiError {
  debugMessage?: string;
  message?: string;
  status: number;
  subErrors?: number;
  timestamp?: string;
  reportable?: boolean;
}

export interface ApiErrorResponse {
  'com.real.commons.apierror.ApiError': ApiError;
}

export enum AnalyticsEventEnum {
  WELCOME_SCREEN_VIEWED = 'welcome_screen_viewed',
  LOGIN_SCREEN_VIEWED = 'login_screen_viewed',
  SIGNUP_SCREEN_VIEWED = 'signup_screen_viewed',
  FORGOT_PASSWORD_SCREEN_VIEWED = 'forgot_password_screen_viewed',
  SECTION_INTRO_VIEWED = 'section_intro_viewed',
  QUESTION_ANSWERED = 'question_answered',
  APPLICATION_PROGRESS_PANEL_VIEWED = 'application_progress_panel_viewed',
}

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export enum themeEnum {
  GEMINI = 'gemini',
  RESKIN = 'reskin',
}

export interface ThemeState {
  currentTheme: themeEnum;
}
