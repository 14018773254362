import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import Button, { ButtonVariant, GradientVariant, SizeVariant } from '../Button';

export interface ControlledButtonInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label: string;
  onPress: () => void;
  variant?: ButtonVariant;
  buttonSize?: SizeVariant;
  textSize?: SizeVariant;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  containerStyle?: string;
  textStyle?: string;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  gradientVariant?: GradientVariant;
}

const ControlledButtonInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  onPress,
  variant,
  buttonSize,
  textSize,
  leftIcon,
  rightIcon,
  containerStyle,
  textStyle,
  isDisabled,
  isSubmitting,
  shouldUnregister = true,
  gradientVariant,
  ...controllerProps
}: ControlledButtonInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  return (
    <div className='flex flex-row justify-end mt-3'>
      <Button
        label={label}
        variant={variant}
        buttonSize={buttonSize}
        textSize={textSize}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        containerStyle={containerStyle}
        textStyle={textStyle}
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        gradientVariant={gradientVariant}
        onPress={() => {
          onChange(true);
          onPress();
        }}
      />
    </div>
  );
};

export default ControlledButtonInput;
