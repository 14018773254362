import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import SimpleModal from '../SimpleModal';
import ControlledSingleSelectInput from '../inputs/ControlledSingleSelectInput';
import { themeEnum } from '../../types';
import { setTheme } from '../../slices/ThemeSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import { capitalizeEnum } from '../../utils/EnumHelper';
import ThemeService from '../../services/ThemeService';

interface SelectThemeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  theme: themeEnum;
}

const SelectThemeModal: React.FC<SelectThemeModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { currentTheme } = useAppSelector((state) => state.theme);
  const { control, watch } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      theme: currentTheme,
    },
  });

  const formThemeValue = watch('theme');

  useEffect(() => {
    if (formThemeValue !== currentTheme) {
      dispatch(setTheme(formThemeValue));
      ThemeService.storeTheme(formThemeValue);
      onClose();
    }
  }, [currentTheme, dispatch, formThemeValue, onClose]);

  return (
    <SimpleModal isOpen={isOpen} onClose={onClose} title='Select Theme'>
      <div className='p-4'>
        <ControlledSingleSelectInput
          control={control}
          name='theme'
          options={[...Object.keys(themeEnum)].map((themeKey) => ({
            label: capitalizeEnum(themeKey),
            value: themeEnum[themeKey as keyof typeof themeEnum],
          }))}
        />
      </div>
    </SimpleModal>
  );
};

export default SelectThemeModal;
