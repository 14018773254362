import Bugsnag from '@bugsnag/js';
import { datadogRum } from '@datadog/browser-rum';
import { DatadogError } from '../errors/DatadogError';
import ForceReportingError from '../errors/ForceReportingError';
import { UserResponse } from '../openapi/yenta';
import { ErrorCode } from '../types';
import Logger from '../utils/Logger';
import {
  isAPICommonsError,
  isAxiosError,
  isForceReportingError,
} from '../utils/TypeUtils';

export default class ErrorService {
  static getErrorCode(error: any): ErrorCode {
    if (isAxiosError(error)) {
      const status = error.response?.status;

      if (status === 401) {
        return ErrorCode.UNAUTHORIZED;
      }

      if (status === 403) {
        return ErrorCode.FORBIDDEN;
      }

      if (status === 404) {
        return ErrorCode.NOT_FOUND;
      }
    }

    return ErrorCode.SERVER_ERROR;
  }

  static setUser(agentResponse: UserResponse): void {
    const fullName = `${agentResponse.firstName} ${agentResponse.lastName}`;

    Bugsnag.setUser(
      `${agentResponse.id}`,
      agentResponse.emailAddress,
      `${agentResponse.firstName} ${agentResponse.lastName}`,
    );

    datadogRum.setUser({
      id: agentResponse.id,
      yentaId: agentResponse.id,
      email: agentResponse.emailAddress,
      name: fullName,
      type: agentResponse.type,
    });
  }

  static getErrorMessage(error: any): string | undefined {
    if (error.response) {
      return isAPICommonsError(error.response.data)
        ? error.response.data['com.real.commons.apierror.ApiError'].message
        : undefined;
    }
    return undefined;
  }

  static notifyIgnoreAuthErrors(
    message: string,
    error: Error,
    metadata: { [key: string]: object } = {},
  ) {
    return this.notifyIgnoreStatusCodes([401, 403], message, error, metadata);
  }

  static notifyIgnoreNotFound(
    message: string,
    error: Error,
    metadata: { [key: string]: object } = {},
  ) {
    return this.notifyIgnoreStatusCodes([404], message, error, metadata);
  }

  static notifyIgnoreStatusCodes(
    statusCodes: number[],
    message: string,
    error: Error,
    metadata: { [key: string]: object } = {},
  ): void {
    if (isAxiosError(error) && statusCodes.includes(error.response?.status!)) {
      Logger.debug(
        '[Bugsnag] - ignoring error with response type',
        error.response?.status,
      );

      return;
    }

    this.notify(message, error, metadata);
  }

  static notifyIgnoreHandled(
    message: string,
    error: Error,
    metadata: { [key: string]: object } = {},
  ): void {
    if (isAxiosError(error) && error.response?.status !== 500) {
      Logger.debug(
        '[Bugsnag] - ignoring error with response type',
        error.response?.status,
      );

      return;
    }

    this.notify(message, error, metadata);
  }

  static isReportableError = (error: Error) => {
    if (isForceReportingError(error)) {
      return true;
    }

    if (
      !isAxiosError(error) ||
      !isAPICommonsError(error.response?.data || {})
    ) {
      return true;
    }

    return (
      error.response?.data['com.real.commons.apierror.ApiError']?.reportable !==
      false
    );
  };

  static notify(
    message: string,
    error: Error,
    metadata: { [key: string]: object } = {},
  ): void {
    if (isAxiosError(error) && [403].includes(error.response?.status!)) {
      Logger.debug(
        '[Bugsnag] - ignoring error with response type',
        error.response?.status,
      );

      return;
    }

    Logger.error(message, error);

    if (ErrorService.isReportableError(error)) {
      const messageToReport = `${message} - ${error.message}`;
      const errorToReport = isForceReportingError(error)
        ? new ForceReportingError(messageToReport)
        : new Error(messageToReport);

      Bugsnag.notify(errorToReport, (event) => {
        Object.keys(metadata).forEach((key) => {
          event.addMetadata(key, metadata[key]);
        });

        if (isAxiosError(error)) {
          event.addMetadata('axios', error.response ?? {});
        }

        event.context = message;
        // Group all the errors with the same message.
        event.groupingHash = message;
      });
      datadogRum.addError(DatadogError.create(message, error), metadata);
    } else {
      Logger.debug('[Bugsnag] error is non-reportable:', error);
    }
  }
}
