import {
  faCircleCheck,
  faDollarSign,
  faFileCheck,
  faFileImport,
  faHourglassHalf,
  faListCheck,
  faSignature,
  faStamp,
  faTimer,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import {
  BorrowerDtoStatusEnum,
  LoanDtoStatusEnum,
} from '../../../openapi/atlantis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { LoanStatusProps } from '../LoanStatus';

export const variantToClassNameMap: EnumMap<LoanDtoStatusEnum, string> = {
  [LoanDtoStatusEnum.AccountCreated]: 'text-[#4967FD]',
  [LoanDtoStatusEnum.ApplicationInProgress]: 'text-[#C98B11]',
  [LoanDtoStatusEnum.ApplicationSubmitted]: 'text-[#7440CD]',
  [LoanDtoStatusEnum.LoanPreApproved]: 'text-[#439775]',
  [LoanDtoStatusEnum.SubmittedToLender]: 'text-[#4967FD]',
  [LoanDtoStatusEnum.Underwriting]: 'text-[#C98B11]',
  [LoanDtoStatusEnum.ConditionalApproval]: 'text-[#C98B11]',
  [LoanDtoStatusEnum.ClearToClose]: 'text-[#7440CD]',
  [LoanDtoStatusEnum.Closing]: 'text-[#439775]',
  [LoanDtoStatusEnum.Funding]: 'text-[#439775]',
  [LoanDtoStatusEnum.Terminated]: 'text-[#F84C6C]',
};

export const statusToName: EnumMap<LoanDtoStatusEnum, string> = {
  [LoanDtoStatusEnum.AccountCreated]: 'Created',
  [LoanDtoStatusEnum.ApplicationInProgress]: 'In progress',
  [LoanDtoStatusEnum.ApplicationSubmitted]: 'Submitted',
  [LoanDtoStatusEnum.LoanPreApproved]: 'Pre-approved',
  [LoanDtoStatusEnum.SubmittedToLender]: 'Submitted to lender',
  [LoanDtoStatusEnum.Underwriting]: 'Underwriting',
  [LoanDtoStatusEnum.ConditionalApproval]: 'Conditional approval',
  [LoanDtoStatusEnum.ClearToClose]: 'Clear to close',
  [LoanDtoStatusEnum.Closing]: 'Closing',
  [LoanDtoStatusEnum.Funding]: 'Funding',
  [LoanDtoStatusEnum.Terminated]: 'Terminated',
};

const LoanStatusReskin: React.FC<LoanStatusProps> = ({ status }) => {
  const LoanStatusIconMap: EnumMap<
    LoanDtoStatusEnum | BorrowerDtoStatusEnum,
    React.ReactElement
  > = {
    [LoanDtoStatusEnum.AccountCreated]: (
      <FontAwesomeIcon icon={faFileImport} size='sm' />
    ),
    [LoanDtoStatusEnum.ApplicationInProgress]: (
      <FontAwesomeIcon icon={faHourglassHalf} size='sm' />
    ),
    [LoanDtoStatusEnum.ApplicationSubmitted]: (
      <FontAwesomeIcon
        icon={faFileCheck}
        size='sm'
        className='text-[#7440CD]'
      />
    ),
    [LoanDtoStatusEnum.SubmittedToLender]: (
      <FontAwesomeIcon
        icon={faFileImport}
        size='sm'
        className='text-[#4967FD]'
      />
    ),
    [LoanDtoStatusEnum.Underwriting]: (
      <FontAwesomeIcon
        icon={faListCheck}
        size='sm'
        className='text-[#C98B11]'
      />
    ),
    [LoanDtoStatusEnum.LoanPreApproved]: (
      <FontAwesomeIcon
        icon={faCircleCheck}
        size='sm'
        className='text-[#439775]'
      />
    ),
    [LoanDtoStatusEnum.ConditionalApproval]: (
      <FontAwesomeIcon icon={faStamp} size='sm' className='text-[#E26811]' />
    ),
    [LoanDtoStatusEnum.ClearToClose]: (
      <FontAwesomeIcon icon={faTimer} size='sm' className='text-[#7440CD]' />
    ),
    [LoanDtoStatusEnum.Closing]: (
      <FontAwesomeIcon
        icon={faSignature}
        size='sm'
        className='text-[#439775]'
      />
    ),
    [LoanDtoStatusEnum.Funding]: (
      <FontAwesomeIcon
        icon={faDollarSign}
        size='sm'
        className='text-[#439775]'
      />
    ),
    [BorrowerDtoStatusEnum.Terminated]: (
      <FontAwesomeIcon icon={faXmark} size='sm' className='text-[#F84C6C]' />
    ),
  };

  const statusText = safeEnumMapGet(
    statusToName,
    status,
    LoanDtoStatusEnum.AccountCreated,
  );

  const styles = safeEnumMapGet(
    variantToClassNameMap,
    status,
    LoanDtoStatusEnum.AccountCreated,
  );

  const icon = safeEnumMapGet(
    LoanStatusIconMap,
    status,
    LoanStatusIconMap[LoanDtoStatusEnum.AccountCreated],
  );

  return (
    <div className='flex items-center justify-between'>
      <div
        className={classNames(
          'w-max flex items-center space-x-1.5 flex-shrink-0 py-1.5 px-2 mr-1 rounded-full md:whitespace-nowrap',
          styles,
        )}
      >
        {icon}
        <span className='font-poppins-light text-base leading-h3'>
          {statusText}
        </span>
      </div>
    </div>
  );
};

export default LoanStatusReskin;
