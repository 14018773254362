import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import DefaultLoader from '../DefaultLoader';
import ControlledChatInput from '../inputs/ControlledChatInput';
import ChatAttachment from './ChatAttachment';

interface FormData {
  chat: string;
}

interface ChatFooterProps {
  onSubmit: (message: string) => void;
}

const ChatFooter: React.FC<ChatFooterProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>();
  const isDisabled = isSubmitting || !isValid;

  const handleOnSubmit = async (formData: FormData) => {
    await onSubmit(formData.chat);
    setValue('chat', '');
  };

  return (
    <div className='flex flex-row justify-center items-center bg-white absolute bottom-0 right-0 left-0 p-4 rounded-b-2xl'>
      <ChatAttachment />
      <div className='flex-1 ml-3'>
        <ControlledChatInput
          name='chat'
          placeholder='Type Here...'
          control={control}
          shouldUnregister={false}
          keyDownHandler={handleSubmit(handleOnSubmit)}
          endAdornment={
            <button
              onClick={handleSubmit(handleOnSubmit)}
              className={classNames([
                isDisabled ? 'bg-gray-3' : 'bg-primary-coral',
                'w-10 h-10 rounded-full flex-row items-center justify-center',
              ])}
              disabled={isDisabled}
            >
              {isSubmitting ? (
                <DefaultLoader />
              ) : (
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  fontSize={16}
                  className='text-black'
                />
              )}
            </button>
          }
          rules={{
            required: 'Required',
            validate: (value) => {
              const trimValue = value.trim();

              if (trimValue.length === 0) {
                return 'Please enter a valid message';
              }

              return undefined;
            },
          }}
        />
      </div>
    </div>
  );
};

export default ChatFooter;
