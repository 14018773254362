import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { themeEnum, ThemeState } from '../types';
import ThemeService from '../services/ThemeService';

export const initialState: ThemeState = {
  currentTheme: (ThemeService.getTheme() as themeEnum) || themeEnum.GEMINI,
};

const ThemeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<themeEnum>) {
      state.currentTheme = action.payload;
    },
  },
});

export const { setTheme } = ThemeSlice.actions;

export default ThemeSlice.reducer;
