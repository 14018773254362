import {
  faFileImage,
  faFilePdf,
  faFilePlus,
  faPaperclip,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import React, { useRef, useState } from 'react';
import { MAX_FILE_SIZE } from '../../constants/FileConstants';
import ErrorService from '../../services/ErrorService.ts';
import { uploadFileToDropbox } from '../../slices/DropboxSlice.ts';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import { bytesToSize, isDocSizeValid } from '../../utils/FileUtils';
import Logger from '../../utils/Logger';
import DefaultLoader from '../DefaultLoader';
import Hover from '../Hover';
import PopoverOption from '../PopoverOption';

interface ChatAttachmentProps {}

const ChatAttachment: React.FC<ChatAttachmentProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const { borrowerDetail, userId } = useAppSelector((state) => state.loan);
  const fileUploader = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const uploadFile = async (event: any) => {
    const file = event.target?.files![0];
    if (isDocSizeValid(file)) {
      try {
        setUploading(true);
        await dispatch(uploadFileToDropbox(userId!, borrowerDetail!, file));
        setIsOpen(false);
      } catch (error: any) {
        Logger.error('Error uploading file', error);
        ErrorService.notify('Error uploading file', error);
      } finally {
        setUploading(false);
      }
    } else {
      dispatch(
        showErrorToast(
          `${pluralize(
            'File',
            file.length,
          )} size exceeds maximum limit of ${bytesToSize(MAX_FILE_SIZE)}.`,
        ),
      );
    }
  };

  const options = [
    {
      title: 'Upload Files',
      onPress: () => {
        fileUploader.current?.click();
      },
      icon: (
        <FontAwesomeIcon
          icon={faFilePlus}
          fontSize={18}
          className='text-gray-3'
        />
      ),
    },
    {
      title: 'Upload PDF',
      onPress: () => {
        fileUploader.current?.click();
      },
      icon: (
        <FontAwesomeIcon
          icon={faFilePdf}
          fontSize={18}
          className='text-gray-3'
        />
      ),
    },
    {
      title: 'Upload Media',
      onPress: () => {
        fileUploader.current?.click();
      },
      icon: (
        <FontAwesomeIcon
          icon={faFileImage}
          fontSize={18}
          className='text-gray-3'
        />
      ),
    },
  ];

  return (
    <div>
      {uploading ? (
        <div className='text-black bg-gray-2 p-3 rounded-full h-10 w-10 flex justify-center items-center'>
          <DefaultLoader iconSize={20} />
        </div>
      ) : (
        <Hover
          config={{
            placement: 'top-end',
            trigger: 'click',
            interactive: true,
            closeOnOutsideClick: false,
            visible: isOpen,
          }}
          hoverComponent={
            <div className='flex flex-col p-3'>
              {options?.map((option, index) => {
                const isLast = index === options.length - 1;
                return (
                  <div key={option.title} className={isLast ? 'mb-0' : 'mb-5'}>
                    <PopoverOption option={option} />
                  </div>
                );
              })}
            </div>
          }
        >
          <input
            type='file'
            id='file'
            ref={fileUploader}
            className='hidden'
            onChange={(e) => {
              if (e.target?.files && e.target?.files?.[0]) {
                uploadFile(e);
                e.target.value = '';
              }
            }}
          />
          <div className='text-black bg-gray-2 p-3 rounded-full h-10 w-10 flex justify-center items-center'>
            <FontAwesomeIcon
              icon={!isOpen ? faPaperclip : faXmark}
              onClick={() => setIsOpen(!isOpen)}
              fontSize={20}
            />
          </div>
        </Hover>
      )}
    </div>
  );
};

export default ChatAttachment;
