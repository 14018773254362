import { faAnglesUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  ApplicationProgressResponse,
  QuestionProgressDto,
  QuestionProgressDtoStatusEnum,
  SectionProgressDtoStatusEnum,
} from '../../openapi/atlantis';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import Step from './Step';

interface HorizonStepProgressProps {
  progress: ApplicationProgressResponse;
  onExpand: () => void;
}

const HorizonStepProgress: React.FC<HorizonStepProgressProps> = ({
  progress,
  onExpand,
}) => {
  const steps = progress?.sectionProgresses || [];

  let currentStepIndex = steps.findIndex(
    (step) => step.status === SectionProgressDtoStatusEnum.InProgress,
  );

  const getStepProgressPercentage = (
    questionProgress: QuestionProgressDto[],
  ) => {
    const totalCompletedQuestions = (questionProgress || []).filter(
      (question) => question.status === QuestionProgressDtoStatusEnum.Completed,
    )?.length;

    const totalQuestionsCount = (questionProgress || []).length;

    return (totalCompletedQuestions / totalQuestionsCount || 0) * 100;
  };

  return (
    <div className='relative'>
      <div className='m-5'>
        {steps[currentStepIndex] ? (
          <div className='flex flex-row justify-start my-2'>
            <p className='font-poppins-medium text-lg text-white'>
              {steps[currentStepIndex].name}
            </p>
          </div>
        ) : (
          <div className='flex flex-row justify-start my-2'>
            <p className='font-poppins-medium text-lg text-white'>
              Application Progress
            </p>
          </div>
        )}
        <div className='flex flex-row items-center justify-around'>
          {steps.map((step, index) => {
            const isFinal = index === steps.length - 1;

            const isCompleted =
              step.status === SectionProgressDtoStatusEnum.Completed;

            const isInProgress =
              step.status === SectionProgressDtoStatusEnum.InProgress;

            const inProgressPercentage = getStepProgressPercentage(
              step.questionProgresses!,
            );

            return (
              <Step
                key={step.name}
                inProgressPercentage={inProgressPercentage}
                isInProgress={isInProgress}
                isCompleted={isCompleted}
                isFinal={isFinal}
              />
            );
          })}
        </div>
      </div>
      <div className='bg-primary-skyblue rounded-3xl absolute -top-10 md:right-10 right-8'>
        <button
          onClick={() => {
            onExpand();
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.APPLICATION_PROGRESS_PANEL_VIEWED,
            );
          }}
          className='flex flex-row items-center px-6 py-2 space-x-2'
        >
          <p className='text-white text-sm font-inter-medium'>View Progress</p>
          <FontAwesomeIcon
            icon={faAnglesUp}
            className='text-white'
            fontSize={12}
          />
        </button>
      </div>
    </div>
  );
};

export default HorizonStepProgress;
