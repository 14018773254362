import { ALPHA_NUMERIC_REGEX } from './RegexUtils';

export const isStringEmpty = (str: string | undefined | null): boolean => {
  return !str || str.trim().length === 0;
};

export const isStringPresent = (
  str: string | undefined | null,
): str is string => {
  return !isStringEmpty(str);
};

export const stringToHexCode = (str: string) => {
  const hashCode = str
    .toUpperCase()
    .split('')
    .reduce((sum, s) => s.charCodeAt(0) + ((sum << 3) + sum), 0);

  const color = Math.abs(hashCode).toString(16).substring(0, 6);

  return '#' + color;
};

export const parseInitials = (name: string): string => {
  return name
    .split(' ')
    .map((n) => n && n[0])
    .filter((n) => ALPHA_NUMERIC_REGEX.test(n))
    .slice(0, 2)
    .join('')
    .toUpperCase();
};

export const removeHtmlTagsFromString = (str: string) => {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
};

export const maskPhoneNumberExceptLastFour = (phoneNumber: string) => {
  if (phoneNumber?.length <= 4) {
    return phoneNumber;
  }

  const visiblePart = phoneNumber?.slice(-4);

  const maskedPart = '*** **** ';

  return maskedPart + visiblePart;
};

export const formatPhoneNumber = (
  phoneNumberString: string | undefined,
): string | undefined => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return undefined;
};
