import React from 'react';
import { useAppSelector } from '../slices/store';
import { themeEnum } from '../types';

type ComponentThemesMap<IComponentProps> = {
  [key in themeEnum]: React.FC<IComponentProps>;
};

interface RenderThemeComponentProps<ComponentProps> {
  GeminiComponent: React.FC<ComponentProps>;
  ReskinComponent: React.FC<ComponentProps>;
}

const RenderThemeComponent = <IComponentProps extends object>({
  GeminiComponent,
  ReskinComponent,
  ...props
}: RenderThemeComponentProps<IComponentProps> & IComponentProps) => {
  const currentTheme = useAppSelector((state) => state.theme.currentTheme);

  const ComponentMap: ComponentThemesMap<IComponentProps> = {
    [themeEnum.GEMINI]: GeminiComponent,
    [themeEnum.RESKIN]: ReskinComponent,
  };

  const Component = ComponentMap[currentTheme];

  return <Component {...(props as IComponentProps)} />;
};

export default RenderThemeComponent;
