import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface FieldErrorMessageProps {
  message?: string;
}

const FieldErrorMessage: React.FC<FieldErrorMessageProps> = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <div className='flex flex-row items-center mt-1'>
      <FontAwesomeIcon
        icon={faCircleInfo}
        fontSize={14}
        className='text-primary-coral'
        data-testid='field-error-icon'
      />
      <div className='flex-shrink'>
        <p
          className='font-inter-medium text-primary-coral text-sm ml-2'
          data-testid='field-error-message'
        >
          {message}
        </p>
      </div>
    </div>
  );
};

export default FieldErrorMessage;
