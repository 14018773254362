import { useState } from 'react';
import ConfigDrivenTitle from '../ConfigDriven/Misc/ConfigDrivenTitle';
import SelectThemeModal from '../Theme/SelectThemeModal';

const LoansTitle = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClick = (e: { detail: number }) => {
    if (e.detail === 4) {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <div onClick={handleClick}>
        <ConfigDrivenTitle
          title='My Loans'
          textStyle='!font-poppins-medium select-none'
        />
      </div>

      {!!isModalOpen && (
        <SelectThemeModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default LoansTitle;
