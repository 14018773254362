import { faArrowsRotate, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import {
  QuestionProgressDtoStatusEnum,
  SectionProgressDto,
} from '../../openapi/atlantis';
import { useAppSelector } from '../../slices/store';
import { removeHtmlTagsFromString } from '../../utils/StringUtils';

interface VerticalStepQuestionsProps {
  step: SectionProgressDto;
  onNavigateToQuestion: (questionId: string) => void;
}

const VerticalStepQuestions: React.FC<VerticalStepQuestionsProps> = ({
  step,
  onNavigateToQuestion,
}) => {
  const [selectingQuestion, setSelectingQuestion] = useState<string>();
  const { currentQuestion } = useAppSelector((state) => state.application);

  return (
    <div className='bg-midnightblue p-4 rounded-b-lg rounded-r-lg mt-4 overflow-scroll h-48'>
      <div>
        {step.questionProgresses?.map((question) => {
          const isCompletedQuestion =
            question.status === QuestionProgressDtoStatusEnum.Completed;

          const isCurrentQuestion =
            currentQuestion?.content?.id === question.id;

          const isSelectingQuestion = selectingQuestion === question.id;

          const isCheckIconVisible = isCompletedQuestion && !isCurrentQuestion;

          const isInactiveQuestion =
            isSelectingQuestion || (!isCompletedQuestion && !isCurrentQuestion);

          return (
            <button
              key={question.id}
              className='flex flex-row items-center cursor-pointer text-start w-full mb-4'
              onClick={async () => {
                if (isCompletedQuestion) {
                  setSelectingQuestion(question.id);
                  await onNavigateToQuestion(question.id);
                  setSelectingQuestion(undefined);
                }
              }}
            >
              <div className='w-8'>
                {isSelectingQuestion ? (
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    fontSize={16}
                    className='text-white animate-spin opacity-50'
                  />
                ) : (
                  isCheckIconVisible && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      fontSize={16}
                      className='text-aqua font-extrabold mr-2 mt-1'
                    />
                  )
                )}
              </div>
              <p
                className={classNames([
                  'font-inter-medium text-sm',
                  isInactiveQuestion
                    ? 'text-primary-gray'
                    : isCurrentQuestion
                    ? 'text-white'
                    : 'text-aqua',
                ])}
              >
                {removeHtmlTagsFromString(question.title!)}
              </p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalStepQuestions;
