import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button.tsx';
import NotFoundImg from '../assets/img/404.svg';

interface Route404Props {}

const Route404: React.FC<Route404Props> = () => {
  const navigate = useNavigate();

  return (
    <div className='h-screen flex flex-col justify-center items-center p-4'>
      <img src={NotFoundImg} alt='404' width={400} height={400} />
      <p className='text-center font-inter-medium text-primary-navy text-2xl mt-5'>
        Hmm...we can&apos;t seem to find that.
      </p>
      <div className='mt-5'>
        <Button
          label='Go Home'
          fullWidth={isMobile}
          gradientVariant='mintyfresh'
          onPress={() => navigate('/')}
        />
      </div>
    </div>
  );
};

export default Route404;
