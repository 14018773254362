import { Event } from '@bugsnag/js';
import { RumEvent } from '@datadog/browser-rum';
import { isString } from 'lodash';
import ForceReportingError from '../errors/ForceReportingError';
import Logger from './Logger';
import { isAxiosError, isForceReportingError } from './TypeUtils';

const IGNORE_ERROR_MESSAGES = [
  'Network Error',
  'Request aborted',
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'timeout of 0ms exceeded',
  'Cannot redefine property: googletag',
];

export const isDatadogErrorEventValid = (event: RumEvent): boolean => {
  const error = event.error as Error;
  if (isForceReportingError(error)) {
    Logger.debug('Error is set to force reporting. Reporting to Datadog.');
    return true;
  }

  try {
    const isLaunchNotesError = error?.stack?.includes('embed.launchnotes.io');

    if (isLaunchNotesError) {
      Logger.debug('Error is from LaunchNotes. Not reporting to Bugsnag.');
      return false;
    }

    const isWebkitMaskedUrl = error?.stack?.includes('webkit-masked-url');

    if (isWebkitMaskedUrl) {
      Logger.debug(
        'Error is from webkit-masked-url. Not reporting to Bugsnag.',
      );
      return false;
    }
  } catch (e) {
    Logger.warn("Couldn't check if error is from LaunchNotes. Continuing...");
  }

  const message = error?.message ?? '';

  const includesIgnorableMessage = IGNORE_ERROR_MESSAGES.find((ignoreMessage) =>
    message.includes(ignoreMessage),
  );

  return !includesIgnorableMessage;
};

export const isEventValid = (event: Event): boolean => {
  let error: any = event?.originalError;
  if (isForceReportingError(error)) {
    Logger.debug('Error is set to force reporting. Reporting to Bugsnag.');
    return true;
  }

  try {
    const isLaunchNotesError = event.errors.some((error) => {
      return error.stacktrace.some((frame) => {
        return frame.file?.includes('embed.launchnotes.io');
      });
    });

    if (isLaunchNotesError) {
      Logger.debug('Error is from LaunchNotes. Not reporting to Bugsnag.');
      return false;
    }

    const isWebkitMaskedUrl = event.errors.some((error) => {
      return error.stacktrace.some((frame) => {
        return frame.file?.includes('webkit-masked-url');
      });
    });

    if (isWebkitMaskedUrl) {
      Logger.debug(
        'Error is from webkit-masked-url. Not reporting to Bugsnag.',
      );
      return false;
    }
  } catch (e) {
    Logger.warn("Couldn't check if error is from LaunchNotes. Continuing...");
  }

  let message: string = '';
  if (isString(error)) {
    message = error;
  } else if (isAxiosError(error) || error instanceof Error) {
    message = error.message ?? '';
  } else {
    return true;
  }

  const includesIgnorableMessage = IGNORE_ERROR_MESSAGES.find((ignoreMessage) =>
    message.includes(ignoreMessage),
  );

  return !includesIgnorableMessage;
};

export const forceReporting = (error: Error): ForceReportingError => {
  const forceReportingError: ForceReportingError = error as ForceReportingError;
  forceReportingError.forceReporting = true;
  return forceReportingError;
};
