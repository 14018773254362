import { capitalizeEnum, convertStringToEnums } from './EnumHelper';

export type AddressComponentType = {
  short_name: string;
  long_name: string;
  types: string[];
};

export interface GooglePlaceLocationType {
  formatted_address: string;
  place_id: string;
  geometry: {
    location: {
      lat: number | undefined;
      lng: number | undefined;
    };
  };
  address_components: AddressComponentType[] | undefined;
}

export type GooglePlaceAddressType = {
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  county: string;
  stateOrProvince: string;
  country: string;
  zipOrPostalCode: string;
};

export const getNameFromAddressComponent = (
  addressComponents: AddressComponentType[],
  level: string,
): string => {
  const addressComponentFound = addressComponents?.find((addressComponent) =>
    addressComponent.types.includes(level),
  );

  if (addressComponentFound) {
    return addressComponentFound?.long_name!;
  }

  return '';
};

export const getStreet1 = (
  addressComponents: AddressComponentType[],
): string => {
  const address = [];
  const streetNumber = getNameFromAddressComponent(
    addressComponents,
    'street_number',
  );
  if (streetNumber) {
    address.push(streetNumber);
  }

  const route = getNameFromAddressComponent(addressComponents, 'route');
  if (route) {
    address.push(route);
  }

  return address.join(' ');
};

export const getAddressFromAddressComponent = <
  K extends keyof GooglePlaceAddressType,
>(
  addressComponent: AddressComponentType[],
  addressOmitProperties: K[] = [],
): GooglePlaceAddressType => {
  const address: GooglePlaceAddressType = {
    streetAddress1: getStreet1(addressComponent),
    streetAddress2: getNameFromAddressComponent(
      addressComponent,
      'neighborhood',
    ),
    city:
      getNameFromAddressComponent(addressComponent, 'locality') ||
      getNameFromAddressComponent(addressComponent, 'sublocality'),
    county: convertStringToEnums(
      getNameFromAddressComponent(
        addressComponent,
        'administrative_area_level_2',
      ),
    ),
    stateOrProvince: convertStringToEnums(
      getNameFromAddressComponent(
        addressComponent,
        'administrative_area_level_1',
      ),
    ),
    country: convertStringToEnums(
      getNameFromAddressComponent(addressComponent, 'country'),
    ),
    zipOrPostalCode: getNameFromAddressComponent(
      addressComponent,
      'postal_code',
    ),
  };

  // Omit properties from the address object
  addressOmitProperties.forEach((property) => {
    delete address[property];
  });

  return address;
};

export const formatAddress = (address: any) => {
  const {
    streetAddress1,
    streetAddress2,
    city,
    stateOrProvince,
    zipOrPostalCode,
    country,
  } = address || {};

  if (!country) {
    return '';
  }

  const formattedAddress = [
    streetAddress1,
    streetAddress2,
    city,
    `${capitalizeEnum(stateOrProvince || '')} ${zipOrPostalCode}`,
    capitalizeEnum(country || ''),
  ]
    .filter(Boolean)
    .join(', ');

  return formattedAddress;
};
