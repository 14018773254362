import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import qs from 'qs';
import React, { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Controller, get, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import RealLogo from '../assets/img/logo.svg';
import AuthPageImage from '../components/AuthPageImage';
import Button from '../components/Button';
import PageLayout from '../components/PageLayout';
import { MfaControllerApi } from '../openapi/keymaker';
import ErrorService from '../services/ErrorService';
import { fetchAuthUserDetail } from '../slices/AuthSlice';
import { useAppDispatch } from '../slices/store';
import { setAuthCookie } from '../utils/AuthUtils';
import { getCommonsApiErrorMessage } from '../utils/ErrorUtils';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';
import { maskPhoneNumberExceptLastFour } from '../utils/StringUtils';

interface SignIn2FARouteProps {}

interface FormData {
  usernameOrEmail: string;
  password: string;
  submit: string;
}

const SignIn2FARoute: React.FC<SignIn2FARouteProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isSubmitting, errors, isValid },
  } = useForm<FormData>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const otpRefs = useRef<any>([]);
  const location = useLocation();
  const { phoneNumber, leadId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const isSmsTypeMFA = !!phoneNumber;

  const onResendCode = async () => {
    setIsLoading(true);
    try {
      await new MfaControllerApi(await getKeymakerConfiguration()).sendMfaSms(
        phoneNumber as string,
      );
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (values: FormData) => {
    const code = parseInt(Object.values(values).join(''), 10);
    try {
      const { data } = await new MfaControllerApi(
        await getKeymakerConfiguration(),
      ).signInWithMfa({
        code,
      });
      setAuthCookie(data?.accessToken!);
      const isLoginSuccess = await dispatch(
        fetchAuthUserDetail(leadId as string),
      );

      if (!isLoginSuccess) {
        setError('submit', {
          message:
            'Looks like the account you are trying to login as is an agent account. Please login to the ReZEN app instead.',
        });
      } else {
        navigate('/');
      }
    } catch (e: any) {
      setError('submit', {
        message: getCommonsApiErrorMessage(e),
      });
      ErrorService.notifyIgnoreAuthErrors(
        'Unable to signing with two factor authentication',
        e,
        {
          data: {
            code,
          },
        },
      );
    }
  };

  return (
    <PageLayout>
      <div className='order-2 md:order-1 md:col-span-5 md:h-screen grid md:justify-between max-w-lg mx-auto p-4'>
        <div className='md:row-span-1 md:my-auto md:block hidden'>
          <img src={RealLogo} alt='placeholder' width={100} height={35} />
        </div>
        <div className='md:row-span-4 md:my-auto mx-5 md:mx-0'>
          <div className='pb-8'>
            <p className='font-poppins-semibold md:text-5xl text-4xl text-black text-left'>
              Sign in to your account
            </p>
          </div>
          {get(errors, 'submit') && (
            <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
              <p className='font-inter-medium text-white text-base'>
                {get(errors, 'submit').message}
              </p>
            </div>
          )}
          <div className='mb-5'>
            {isSmsTypeMFA ? (
              <p className='font-inter-medium text-sm text-primary-gray text-start'>
                We sent a 6-digit code to{' '}
                {maskPhoneNumberExceptLastFour(phoneNumber! as string)}
              </p>
            ) : (
              <p className='font-inter-medium text-sm text-primary-gray text-start'>
                Enter the code from the authenticator app on your phone
              </p>
            )}
          </div>
          <div className='py-5'>
            <div className='flex flex-row'>
              {[...Array(6)].map((_, index) => {
                return (
                  <Controller
                    key={[index].toString()}
                    name={`otp${index + 1}` as keyof FormData}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <div className='flex flex-row flex-shrink items-stretch justify-between overflow-hidden text-base leading-5 border-b border-primary-gray md:mx-3 mx-1'>
                        <input
                          className='focus:appearance-none focus:outline-none w-full flex font-inter-medium flex-grow flex-shrink items-center justify-center text-center text-black text-3xl py-1'
                          maxLength={1}
                          type='tel'
                          data-testid={`otp-input-${index + 1}`}
                          value={value}
                          onBlur={onBlur}
                          onChange={({ target: { value: text } }) => {
                            if (text.length === 1 && /^[0-9]$/.test(text)) {
                              onChange(text);
                              if (index < 5 && otpRefs.current[index + 1]) {
                                otpRefs.current[index + 1]?.focus();
                              }
                            } else if (isEmpty(text)) {
                              onChange(text);
                              if (index > 0 && otpRefs.current[index - 1]) {
                                otpRefs.current[index - 1]?.focus();
                              }
                            }
                          }}
                          ref={(ref) => (otpRefs.current[index] = ref)}
                        />
                      </div>
                    )}
                  />
                );
              })}
            </div>
          </div>
          {isSmsTypeMFA && (
            <div className='flex flex-row justify-between items-center'>
              <p className='font-inter-medium text-sm text-primary-gray'>
                Don’t get a code?{' '}
              </p>
              <div className='mt-0.5'>
                <Button
                  label='Resend'
                  gradientVariant='mintyfresh'
                  onPress={onResendCode}
                  isDisabled={isLoading}
                  isSubmitting={isLoading}
                  textSize='sm'
                  rightIcon={
                    <FontAwesomeIcon
                      icon={faChevronsRight}
                      fontSize={14}
                      className='text-white'
                    />
                  }
                />
              </div>
            </div>
          )}
          <div className='flex w-full mt-10'>
            <Button
              label='Continue'
              fullWidth={isMobile}
              isDisabled={!isValid}
              isSubmitting={isSubmitting}
              gradientVariant='mintyfresh'
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronsRight}
                  fontSize={16}
                  className='text-white'
                />
              }
              onPress={() => {
                if (get(errors, 'submit')) {
                  clearErrors('submit');
                }
                handleSubmit(onSubmit)();
              }}
            />
          </div>
        </div>
      </div>
      <AuthPageImage />
    </PageLayout>
  );
};

export default SignIn2FARoute;
