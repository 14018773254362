import { faEye, faEyeSlash, faLock } from '@fortawesome/pro-regular-svg-icons';
import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { get, useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import RealLogo from '../assets/img/logo.svg';
import AuthPageImage from '../components/AuthPageImage';
import BlankSpace from '../components/BlankSpace';
import Button from '../components/Button';
import PageLayout from '../components/PageLayout';
import ControlledTextInput from '../components/inputs/ControlledTextInput';
import { AuthControllerApi, LoginRequest } from '../openapi/keymaker';
import ErrorService from '../services/ErrorService';
import { ErrorCode } from '../types';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';
import { PASSWORD_VALIDATIONS } from '../utils/Validations';

interface ChangePasswordRouteProps {}

interface FormData {
  usernameOrEmail: string;
  password: string;
  confirmPassword: string;
  submit: string;
}

const ChangePasswordRoute: React.FC<ChangePasswordRouteProps> = () => {
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const {
    control,
    handleSubmit,
    clearErrors,
    getValues,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({ mode: 'onChange' });

  const location = useLocation();
  const { passwordToken, emailAddress } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const onSubmit = async (values: LoginRequest) => {
    try {
      const api = await new AuthControllerApi(await getKeymakerConfiguration());
      await api.changePassword({
        password: values.password,
        token: passwordToken as string,
      });

      navigate('/');
    } catch (e: any) {
      setError('submit', {
        type: 'server',
        message: e.response.data?.message || ErrorService.getErrorCode(e),
      });

      const errorCode = ErrorService.getErrorCode(e);
      if (errorCode === ErrorCode.SERVER_ERROR) {
        ErrorService.notify('Unable to reset password.', e);
      }
    }
  };

  if (!passwordToken || !emailAddress) {
    return <Navigate to='/login' replace />;
  }

  return (
    <PageLayout>
      <div className='order-2 md:order-1 md:col-span-5 md:h-screen grid md:grid-flow-row md:grid-rows-6 md:justify-between max-w-lg mx-auto'>
        <div className='md:row-span-1 md:my-auto md:block hidden'>
          <img src={RealLogo} alt='placeholder' width={100} height={35} />
        </div>
        <div className='md:row-span-4 md:my-auto mx-5 md:mx-0'>
          <div className='py-8'>
            <p className='font-poppins-semibold md:text-5xl text-4xl text-black text-left'>
              Change Password
            </p>
            <p className='font-inter-medium text-sm pt-4 text-left text-gray-400'>
              Enter a new password to update your password
              <BlankSpace number={52} />
            </p>
          </div>
          {get(errors, 'submit') && (
            <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
              <p className='font-inter-medium text-white text-base'>
                {get(errors, 'submit').message}
              </p>
            </div>
          )}
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'usernameOrEmail'>
              placeholder='Email Address'
              name='usernameOrEmail'
              control={control}
              shouldUnregister={false}
              defaultValue={emailAddress as string}
              disabled
            />
          </div>
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'password'>
              placeholder='Password'
              name='password'
              label='Password'
              control={control}
              shouldUnregister={false}
              rules={PASSWORD_VALIDATIONS}
              type={hidePassword ? 'password' : 'text'}
              defaultValue=''
              startAdornment={
                <FontAwesomeIcon
                  icon={faLock}
                  fontSize={16}
                  className='text-primary-skyblue'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  onClick={() => setHidePassword(!hidePassword)}
                  icon={hidePassword ? faEyeSlash : faEye}
                  fontSize={20}
                  className='text-primary-gray'
                  data-testid='password'
                />
              }
              isPassword
            />
          </div>
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'confirmPassword'>
              placeholder='Confirm Password'
              name='confirmPassword'
              label='Confirm Password'
              control={control}
              shouldUnregister={false}
              rules={{
                required: 'Please re-enter your password',
                validate: (value) =>
                  getValues().password !== value
                    ? 'Passwords do not match'
                    : undefined,
              }}
              type={hideConfirmPassword ? 'password' : 'text'}
              defaultValue=''
              startAdornment={
                <FontAwesomeIcon
                  icon={faLock}
                  fontSize={16}
                  className='text-primary-skyblue'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  icon={hideConfirmPassword ? faEyeSlash : faEye}
                  fontSize={20}
                  className='text-primary-gray'
                  data-testid='confirmPassword'
                />
              }
              isPassword
            />
          </div>
          <div className='flex w-full pt-9'>
            <Button
              label='Change Password'
              fullWidth={isMobile}
              gradientVariant='mintyfresh'
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronsRight}
                  fontSize={16}
                  className='text-white'
                />
              }
              isSubmitting={isSubmitting}
              onPress={() => {
                if (get(errors, 'submit')) {
                  clearErrors('submit');
                }
                handleSubmit(onSubmit)();
              }}
            />
          </div>
        </div>
      </div>
      <AuthPageImage />
    </PageLayout>
  );
};

export default ChangePasswordRoute;
