import React from 'react';
import InternalServer500Img from '../assets/img/500.svg';

interface Route500Props {}

const Route500: React.FC<Route500Props> = () => {
  return (
    <div className='h-screen flex flex-col justify-center items-center -mt-10'>
      <div>
        <img src={InternalServer500Img} alt='500' />
        <p className='text-center font-primary-medium text-dark text-lg'>
          Something went wrong.
        </p>
      </div>
    </div>
  );
};

export default Route500;
