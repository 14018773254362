import {
  faArrowRightFromBracket,
  faBars,
  faComment,
  faFolder,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import BackgroundImagePlaceholder from '../../assets/img/holding-boxes.png';
import RealLogo from '../../assets/img/logo-white.svg';
import RealLogoOriginal from '../../assets/img/logo.svg';
import { ApplicationStep } from '../../openapi/atlantis';
import { signOutUser } from '../../slices/AuthSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import ChatModal from '../ChatModal';
import StepProgressContainer from '../StepProgress/StepProgressContainer';
import ConfigDrivenContainerLayoutHeader from './ConfigDrivenContainerLayoutHeader';

interface ConfigDrivenContainerLayoutProps {
  children: React.ReactNode;
  config?: ApplicationStep;
  onNavigateToQuestion?: (questionId: string) => void;
  hideMyLoans?: boolean;
  hideChat?: boolean;
}

const ConfigDrivenContainerLayout: React.FC<
  ConfigDrivenContainerLayoutProps
> = ({
  children,
  config,
  onNavigateToQuestion,
  hideMyLoans = false,
  hideChat = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    loan: { toggleChat },
    application: { progress },
  } = useAppSelector((state) => state);
  const [isProgressExpand, setIsProgressExpand] = useState<boolean>(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(toggleChat);
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  const backgroundImage =
    config?.content?.style?.backgroundImageDesktop ||
    BackgroundImagePlaceholder;

  useEffect(() => {
    // make body overflow hidden on mobile when chat is open
    // to prevent scrolling on body
    if (isMobile) {
      if (isChatOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [isChatOpen]);

  return (
    <div className='grid lg:grid-flow-col lg:grid-cols-11 grid-cols-1'>
      <div className='lg:col-span-5 relative'>
        <img
          src={RealLogo}
          alt='logo'
          width={100}
          height={35}
          className='absolute lg:top-10 top-5 md:left-10 left-5'
        />
        <img
          src={backgroundImage}
          alt='placeholder'
          className='lg:h-screen object-cover h-52 w-full'
        />
        <FontAwesomeIcon
          icon={faBars}
          onClick={() => setToggleMenu(!toggleMenu)}
          fontSize={28}
          className='text-white absolute top-5 md:right-10 right-5 lg:hidden block'
        />
        {onNavigateToQuestion && (
          <div className='lg:block hidden'>
            <StepProgressContainer
              progress={progress!}
              isProgressExpand={isProgressExpand}
              setIsProgressExpand={setIsProgressExpand}
              onNavigateToQuestion={onNavigateToQuestion}
            />
          </div>
        )}
      </div>
      <div className='lg:col-span-6 lg:h-screen lg:overflow-y-auto'>
        <ConfigDrivenContainerLayoutHeader hideMyLoans={hideMyLoans} />
        <div
          className={classNames(
            'flex items-center justify-center lg:p-8 px-4 pt-10 lg:pb-40',
            { 'pb-40': !isProgressExpand },
          )}
        >
          <div className='md:w-4/6 w-full'>{children}</div>
          {!hideChat && !isMobile && (
            <div className={classNames('absolute z-50 right-10 bottom-10')}>
              {isChatOpen && (
                <div className='absolute right-0 bottom-16'>
                  <ChatModal />
                </div>
              )}
              <button
                className='bg-aqua border-4 border-white w-16 h-16 rounded-full items-center justify-center'
                onClick={() => setIsChatOpen(!isChatOpen)}
              >
                <FontAwesomeIcon
                  icon={isChatOpen ? faXmark : faComment}
                  fontSize={24}
                  className='text-white'
                />
              </button>
            </div>
          )}
        </div>
      </div>
      {!hideChat && isMobile && (
        <div>
          {isChatOpen && (
            <div className='fixed right-0 left-0 top-0 w-[100vw] bg-black flex items-center justify-between lg:h-24 h-20 px-5 pb-2'>
              <div>
                <img src={RealLogo} alt='logo' width={100} height={35} />
              </div>
              <div>
                <FontAwesomeIcon
                  onClick={() => setIsChatOpen(!isChatOpen)}
                  icon={faXmark}
                  fontSize={20}
                  className='text-gray-3 cursor-pointer'
                />
              </div>
            </div>
          )}
          <div
            className={classNames(
              isChatOpen ? 'fixed left-0 right-0 bottom-0 top-20' : 'hidden',
            )}
          >
            <ChatModal />
          </div>
          {!isChatOpen && (
            <div
              className={classNames([
                'fixed left-5',
                onNavigateToQuestion ? 'bottom-32' : 'bottom-5',
              ])}
            >
              <button
                className='bg-aqua w-16 h-16 rounded-full items-center justify-center'
                onClick={() => setIsChatOpen(!isChatOpen)}
              >
                <FontAwesomeIcon
                  icon={isChatOpen ? faXmark : faComment}
                  fontSize={24}
                  className='text-white'
                />
              </button>
            </div>
          )}
        </div>
      )}
      {onNavigateToQuestion && !isChatOpen && (
        <div className='lg:hidden block'>
          <StepProgressContainer
            progress={progress!}
            isProgressExpand={isProgressExpand}
            setIsProgressExpand={setIsProgressExpand}
            onNavigateToQuestion={onNavigateToQuestion}
          />
        </div>
      )}
      <div
        className={classNames(
          'bg-white absolute top-0 h-48 rounded-b-3xl shadow-lg w-full p-4',
          toggleMenu ? 'block' : 'hidden',
        )}
      >
        <img
          src={RealLogoOriginal}
          alt='logo'
          width={100}
          height={35}
          className='absolute lg:top-10 top-5 md:left-10 left-5'
        />
        <div className='absolute top-5 right-5'>
          <FontAwesomeIcon
            icon={faXmark}
            onClick={() => setToggleMenu(!toggleMenu)}
            className='text-black'
            fontSize={30}
          />
        </div>
        <div className='flex flex-col space-y-8 justify-center h-full items-start pt-14 pl-5'>
          {!hideMyLoans && (
            <button
              className='flex flex-row items-center'
              onClick={() => navigate('/loans')}
            >
              <FontAwesomeIcon icon={faFolder} fontSize={20} />
              <p className='font-inter-medium text-black text-xl ml-2'>
                My Loans
              </p>
            </button>
          )}
          <button
            className='flex flex-row items-center'
            onClick={async () => {
              await dispatch(signOutUser());
              navigate('/');
            }}
          >
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              className='text-black'
              fontSize={20}
            />
            <p className='font-inter-medium text-black text-xl ml-2'>Logout</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfigDrivenContainerLayout;
