import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import useFieldArrayValidation from '../../../hooks/useFieldArrayValidation';
import {
  AnswerApplicationQuestionRequest,
  FieldObject,
} from '../../../openapi/atlantis';
import Button from '../../Button';
import FieldErrorMessage from '../../inputs/FieldErrorMessage';
import ConfigDrivenConditionalFormInput from './ConfigDrivenConditionalFormInput';

interface ConfigDrivenFormFieldArrayInputProps {
  fieldArray: FieldObject;
  onSubmit: (formData: AnswerApplicationQuestionRequest) => void;
  isSubmitting: boolean;
  checklistItemId: string;
}

const ConfigDrivenFormFieldArrayInput: React.FC<
  ConfigDrivenFormFieldArrayInputProps
> = ({ fieldArray, onSubmit, isSubmitting, checklistItemId }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArray.id!,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [append, fields.length]);

  useFieldArrayValidation(fieldArray);

  const fieldArrayErrors =
    (errors.fieldArrayErrors?.message as string)?.split(', ') || [];

  return (
    <div>
      {fields.map((field, index) => {
        const isPositiveIndex = index > 0;
        const isLastIndex = index === fields.length - 1;

        return (
          <div key={field.id}>
            <div
              className={classNames([
                isLastIndex ? 'border-b-0' : 'border-b',
                isPositiveIndex ? 'pt-10' : 'pt-0',
                'border-silver',
                'pb-10',
              ])}
            >
              {/* @ts-ignore */}
              {fieldArray.fields?.map((input: FieldObject) => {
                const name = `${fieldArray.id}.${index}.${input.id}`;

                return (
                  <ConfigDrivenConditionalFormInput
                    key={input.id}
                    name={name}
                    field={input}
                    onSubmit={onSubmit}
                    checklistItemId={checklistItemId}
                    isSubmitting={isSubmitting}
                  />
                );
              })}
              {(isLastIndex || isPositiveIndex) && (
                <div
                  className={classNames([
                    'flex flex-row mt-4',
                    isLastIndex ? 'justify-between' : 'justify-end',
                  ])}
                >
                  {isLastIndex && (
                    <Button
                      label={fieldArray.label!}
                      variant='no-border'
                      buttonSize='sm'
                      textSize='xs'
                      textStyle='text-primary-skyblue'
                      onPress={() => append({})}
                      leftIcon={
                        <FontAwesomeIcon
                          icon={faPlus}
                          fontSize={14}
                          className='text-primary-skyblue mb-0.5'
                        />
                      }
                    />
                  )}
                  {isPositiveIndex && (
                    <Button
                      label='Remove'
                      variant='no-border'
                      buttonSize='sm'
                      textSize='xs'
                      textStyle='!text-primary-coral'
                      onPress={() => remove(index)}
                      leftIcon={
                        <FontAwesomeIcon
                          icon={faTrash}
                          fontSize={14}
                          className='text-primary-coral'
                        />
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
      {!isEmpty(fieldArrayErrors) && (
        <div className='my-5'>
          {fieldArrayErrors.map((msg) => (
            <FieldErrorMessage key={msg} message={msg} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ConfigDrivenFormFieldArrayInput;
