import { FC } from 'react';
import useValidateVisibleCondition from '../../../hooks/useValidateVisibleCondition';
import {
  AnswerApplicationQuestionRequest,
  Segment,
} from '../../../openapi/atlantis';
import ConfigDrivenTitle from '../Misc/ConfigDrivenTitle';
import ConfigDrivenFields from './ConfigDrivenFields';

interface ConfigDrivenSegmentsProps {
  segment: Segment;
  onSubmit: (e: AnswerApplicationQuestionRequest) => void;
  isSubmitting: boolean;
  checklistItemId?: string;
}

const ConfigDrivenSegment: FC<ConfigDrivenSegmentsProps> = ({
  segment,
  onSubmit,
  isSubmitting,
  checklistItemId,
}) => {
  const { validateSegmentVisibleCondition } = useValidateVisibleCondition();
  const isVisible = validateSegmentVisibleCondition(segment);

  if (!isVisible) {
    return null;
  }

  return (
    <div key={segment.name} className='mb-8'>
      {segment.name && (
        <div className='mb-4'>
          <ConfigDrivenTitle
            title={segment.name}
            textStyle='font-poppins-medium lg:!text-lg !text-md'
          />
        </div>
      )}
      <ConfigDrivenFields
        fields={segment.fields}
        checklistItemId={checklistItemId!}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ConfigDrivenSegment;
