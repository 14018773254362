import React from 'react';

interface PageLayoutProps {
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <div className='grid md:grid-flow-col grid-flow-row md:grid-cols-11 grid-cols-1 relative md:h-screen w-full md:overflow-hidden overflow-scroll'>
      {children}
    </div>
  );
};

export default PageLayout;
