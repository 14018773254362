import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { ISelectOption } from '../../types';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

export interface ControlledBinaryInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  options: ISelectOption[];
}

const ControlledBinaryInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  options,
  shouldUnregister = true,
  ...controllerProps
}: ControlledBinaryInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  return (
    <div>
      {label && <ControlledInputLabelComponent label={label} />}
      <div className='flex w-full'>
        <div className='flex flex-row w-full'>
          {(options || []).map((option, index) => {
            const optionValue = option.value.toString();
            const isSelected = optionValue === value?.toString();

            return (
              <div
                key={option.label}
                className={classNames([
                  'w-1/2',
                  index % 2 === 0 ? 'pr-2' : 'pl-2',
                ])}
              >
                <button
                  onClick={() => {
                    if (!isSelected) {
                      onChange(optionValue);
                    }
                  }}
                  disabled={option.disabled}
                  className='w-full'
                >
                  <div
                    className={classNames([
                      'flex flex-row items-center justify-center p-4 rounded-xl',
                      isSelected
                        ? 'border-2 border-primary-skyblue bg-primary-skyblue bg-opacity-10'
                        : 'bg-transparent border border-primary-graphite',
                    ])}
                    data-testid={option.label}
                  >
                    <p
                      className={classNames([
                        'font-inter-medium',
                        'text-base',
                        isSelected ? 'text-black' : 'text-primary-graphite',
                      ])}
                    >
                      {option.label}
                    </p>
                    {isSelected && (
                      <FontAwesomeIcon
                        icon={value === 'true' ? faThumbsUp : faThumbsDown}
                        fontSize={20}
                        className='text-primary-skyblue ml-2'
                      />
                    )}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
        <FieldErrorMessage message={error?.message} />
      </div>
    </div>
  );
};

export default ControlledBinaryInput;
