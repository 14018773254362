import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApplyLoanLoanTypeEnum,
  BorrowerDto,
  LoanDto,
  LoansApi,
  UserApi,
} from '../openapi/atlantis';
import ErrorService from '../services/ErrorService';
import { AppThunk, LoanState } from '../types';
import { getAtlantisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { fetchDropboxById } from './DropboxSlice';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: LoanState = {
  userId: undefined,
  loans: undefined,
  loanDetail: undefined,
  borrowerDetail: undefined,
  toggleChat: false,
};

const LoanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    saveUserId(state, action: PayloadAction<string | undefined>) {
      state.userId = action.payload;
    },
    saveLoans(state, action: PayloadAction<LoanDto[] | undefined>) {
      state.loans = action.payload;
    },
    saveLoanDetail(state, action: PayloadAction<LoanDto | undefined>) {
      state.loanDetail = action.payload;
    },
    saveBorrowerDetail(state, action: PayloadAction<BorrowerDto | undefined>) {
      state.borrowerDetail = action.payload;
    },
    addLoan(state, action: PayloadAction<LoanDto>) {
      state.loans?.push(action.payload);
    },
    toggleChat(state, action: PayloadAction<boolean>) {
      state.toggleChat = action.payload;
    },
  },
});

export const {
  saveUserId,
  saveLoans,
  saveLoanDetail,
  saveBorrowerDetail,
  addLoan,
  toggleChat,
} = LoanSlice.actions;

export const fetchLoanByBorrowerId =
  (borrowerId: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const { data } = await new LoansApi(
        await getAtlantisConfiguration(),
      ).getLoanByBorrowerId(borrowerId);

      const borrower = data.borrowers.find((b) => b.id === borrowerId);

      if (borrower) {
        dispatch(saveLoanDetail(data));
        dispatch(saveBorrowerDetail(borrower));
        dispatch(fetchDropboxById(borrower));
        return true;
      }
    } catch (e: any) {
      ErrorService.notify('Unable to fetch borrower loan detail', e);
      dispatch(showErrorToast('Unable to fetch borrower loan detail'));
    }
    return false;
  };

export const fetchLoans =
  (leadId?: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const { data } = await new UserApi(
        await getAtlantisConfiguration(),
      ).getMe(leadId);
      dispatch(saveUserId(data.userId));
      dispatch(saveLoans(data.loans || []));
      return true;
    } catch (e: any) {
      ErrorService.notify('Unable to fetch current user detail', e);
      dispatch(showErrorToast('Unable to fetch current user detail'));
    }
    return false;
  };

export const applyForLoan =
  (loanType: ApplyLoanLoanTypeEnum): AppThunk<Promise<LoanDto | undefined>> =>
  async (dispatch) => {
    try {
      const { data } = await new LoansApi(
        await getAtlantisConfiguration(),
      ).applyForLoan({
        loanType,
      });
      dispatch(addLoan(data));
      return data;
    } catch (e: any) {
      ErrorService.notify('Unable to create loan', e);
      dispatch(showErrorToast('Unable to create loan'));
    }
    return undefined;
  };

export const getLoanBorrowerId =
  (loan?: LoanDto): AppThunk<Promise<string | undefined>> =>
  async (_, getState) => {
    const { userId } = getState().loan;

    if (!loan || !userId) {
      return undefined;
    }

    const borrower = (loan.borrowers || []).find((b) => b.userId === userId);

    return borrower?.id;
  };

export default LoanSlice.reducer;
