import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  LegacyRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MAX_FILE_SIZE } from '../../constants/FileConstants';
import {
  ChecklistApi,
  FileReferenceDto,
  ItemApi,
} from '../../openapi/sherlock';
import ErrorService from '../../services/ErrorService';
import { uploadFileToDropbox } from '../../slices/DropboxSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import { getDropboxFilesFromFileReferences } from '../../utils/DropboxUtils';
import { bytesToSize, isDocSizeInvalid } from '../../utils/FileUtils';
import { getSherlockConfiguration } from '../../utils/OpenapiConfigurationUtils';
import CopyToClipboard from '../CopyToClipboard';
import DefaultLoader from '../DefaultLoader';
import FileRow from '../FileRow';

interface FileInputComponentProps {
  checklistItemId: string;
  sendTo: string;
}

const FileInputComponent: React.FC<FileInputComponentProps> = ({
  checklistItemId,
  sendTo,
}) => {
  const dispatch = useAppDispatch();
  const {
    dropbox: { dropboxByBorrowerId },
    auth: { authUserDetail },
    loan: { borrowerDetail },
  } = useAppSelector((state) => state);
  const dropbox = dropboxByBorrowerId[borrowerDetail?.id!];
  const [uploading, setUploading] = useState<boolean>(false);

  const [fileReferences, setFileReferences] = useState<FileReferenceDto[]>();

  const uploadFile = async (file: any) => {
    setUploading(true);
    const authUserId = authUserDetail?.id!;

    const fileResponse = await dispatch(
      uploadFileToDropbox(authUserId, borrowerDetail!, file),
    );

    if (fileResponse) {
      const references = [
        {
          fileId: fileResponse?.id!,
          filename: fileResponse?.filename!,
        },
      ];

      try {
        await new ItemApi(await getSherlockConfiguration()).addFileReferences(
          checklistItemId,
          {
            references,
          },
        );
        await fetchChecklistItemById(checklistItemId);
      } catch (e: any) {
        dispatch(
          showErrorToast(
            'Unable to attach the document to checklist item. Please try again after sometime.',
          ),
        );
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to attach the dropbox document references to checklist item',
          e,
          {
            data: { checklistItemId, references },
          },
        );
      }
    }
    setUploading(false);
  };

  const fetchChecklistItemById = useCallback(
    async (id: string) => {
      try {
        const { data } = await new ChecklistApi(
          await getSherlockConfiguration(),
        ).getChecklistItemById(id);
        setFileReferences(data.fileReferences?.references || []);
      } catch (e: any) {
        dispatch(
          showErrorToast(
            'Unable to fetch the checklist item. Please try again after sometime.',
          ),
        );
        ErrorService.notify('Unable to fetch checklist item', e, {
          data: {
            checklistItemId: id,
          },
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    fetchChecklistItemById(checklistItemId);
  }, [checklistItemId, fetchChecklistItemById]);

  const files = getDropboxFilesFromFileReferences(
    fileReferences || [],
    dropbox!,
  );

  const isFileAvailable = files.length > 0;
  const fileInputRef = useRef<HTMLDivElement>();

  return (
    <div className='bg-gray-2 border border-dashed border-gray-3 rounded-xl p-6'>
      <div className='flex flex-col items-center justify-center'>
        <FontAwesomeIcon
          icon={faCloudArrowUp}
          fontSize={30}
          className='text-primary-gray'
        />
        <p className='font-inter-medium text-base text-black mt-4'>
          Upload Documents
        </p>
        <p className='font-inter-medium text-xs text-primary-gray mt-2'>
          Files must be less than {bytesToSize(MAX_FILE_SIZE)}.
        </p>
        <div className='flex flex-row mt-3 cursor-pointer'>
          <div
            className='flex flex-row items-center justify-center px-5 py-3 rounded-lg border border-gray-4'
            onClick={() => fileInputRef.current?.click()}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
            onDrop={async (e) => {
              e.preventDefault();
              if (isDocSizeInvalid(e.dataTransfer.files![0], MAX_FILE_SIZE)) {
                dispatch(
                  showErrorToast(
                    `File size exceeds maximum limit of ${bytesToSize(
                      MAX_FILE_SIZE,
                    )}.`,
                  ),
                );
              } else {
                uploadFile(e.dataTransfer.files![0]);
              }
            }}
          >
            <input
              ref={fileInputRef as unknown as LegacyRef<HTMLInputElement>}
              className='hidden w-full h-full'
              type='file'
              accept='.xlsx, .xls, .doc, .docx, .pdf, image/*'
              onChange={(e) => {
                if (isDocSizeInvalid(e.target.files![0], MAX_FILE_SIZE)) {
                  dispatch(
                    showErrorToast(
                      `File size exceeds maximum limit of ${bytesToSize(
                        MAX_FILE_SIZE,
                      )}.`,
                    ),
                  );
                } else {
                  uploadFile(e.target.files![0]);
                }
                e.target.value = '';
              }}
            />
            <p className='font-inter-medium text-xs text-black whitespace-nowrap'>
              Upload File
            </p>
            {uploading && (
              <div className='pl-4'>
                <DefaultLoader iconSize={10} noPadding />
              </div>
            )}
          </div>
        </div>
        <p className='font-inter-medium text-xs text-primary-gray mt-3'>or</p>
        <div className='flex flex-row justify-center items-center flex-wrap mt-3'>
          <p className='font-inter-medium text-xs text-primary-gray'>
            Email to
          </p>
          <p className='font-inter-medium text-xs text-black ml-1'>{sendTo}</p>
          <CopyToClipboard value={sendTo} />
        </div>
      </div>
      {isFileAvailable && (
        <div className='border-t border-gray-3 pt-4 mt-4'>
          {files.map((file) => {
            return <FileRow file={file} key={file.id} />;
          })}
        </div>
      )}
    </div>
  );
};

export default FileInputComponent;
