import { useFormContext } from 'react-hook-form';
import { FieldObject, Segment } from '../openapi/atlantis';
import { isEmptyAnswer } from '../utils/ConfigDrivenFormUtils';
import { getBooleanParse } from '../utils/TypeUtils';

interface useValidateVisibleConditionReturn {
  validateFieldVisibleCondition: (field: FieldObject) => boolean;
  validateSegmentVisibleCondition: (segment: Segment) => boolean;
}

const useValidateVisibleCondition = (): useValidateVisibleConditionReturn => {
  const { watch } = useFormContext();

  const validateFieldVisibleCondition = (field: FieldObject): boolean => {
    const { condition } = field;

    if (!condition || !condition.fieldId) {
      return true;
    }

    //@ts-ignore
    const { fieldId, fieldAnswer, answerType, evaluationMode } = condition;
    const fieldWatch = watch(fieldId!);

    if (isEmptyAnswer(fieldWatch)) {
      return false;
    }

    switch (answerType) {
      case 'LIST':
        if (evaluationMode === 'ANY') {
          return (fieldAnswer || []).some((answer: any) =>
            fieldWatch.includes(answer),
          );
        }
        if (evaluationMode === 'ALL') {
          return (fieldAnswer || []).every((answer: any) =>
            fieldWatch.includes(answer),
          );
        }
        return false;
      case 'BOOLEAN':
        return getBooleanParse(fieldWatch) === fieldAnswer;
      default:
        return fieldWatch === fieldAnswer;
    }
  };

  const validateSegmentVisibleCondition = (segment: Segment): boolean => {
    return (segment.fields || []).some((field) =>
      validateFieldVisibleCondition(field),
    );
  };

  return { validateFieldVisibleCondition, validateSegmentVisibleCondition };
};

export default useValidateVisibleCondition;
