import {
  faArrowLeftLong,
  faEnvelope,
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { get, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import RealLogo from '../assets/img/logo.svg';
import AnalyticsEventOnLoad from '../components/Analytics/AnalyticsEventOnLoad';
import AuthPageImage from '../components/AuthPageImage';
import Button from '../components/Button';
import PageLayout from '../components/PageLayout';
import ControlledTextInput from '../components/inputs/ControlledTextInput';
import {
  ResetPasswordRequestClientPlatformEnum,
  UserControllerApi,
} from '../openapi/yenta';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { useAppDispatch } from '../slices/store';
import { AnalyticsEventEnum } from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { EMAIL_VALIDATIONS } from '../utils/Validations';

interface ForgotPasswordRouteProps {}

interface FormData {
  usernameOrEmail: string;
  password: string;
  submit: string;
}

const ForgotPasswordRoute: React.FC<ForgotPasswordRouteProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = async (values: FormData) => {
    try {
      const api = new UserControllerApi(await getYentaConfiguration());
      await api.resetPassword({
        usernameOrEmail: values.usernameOrEmail,
        clientPlatform: ResetPasswordRequestClientPlatformEnum.Consumer,
      });
      navigate('/email-sent');
    } catch (e: any) {
      setError('submit', {
        message:
          e.response.data.errorMessage ||
          'Something went wrong. Please try again.',
      });
      dispatch(showErrorToast('Unable to reset password. Please try again.'));
    }
  };

  return (
    <PageLayout>
      <div className='order-2 md:order-1 md:col-span-5 md:h-screen grid md:grid-flow-row md:grid-rows-6 md:justify-between max-w-lg mx-auto'>
        <div className='md:row-span-1 md:my-auto md:block hidden'>
          <img src={RealLogo} alt='placeholder' width={100} height={35} />
        </div>
        <div className='md:row-span-4 md:my-auto mx-5 md:mx-0'>
          <div>
            <Link to='/' className='flex flex-row items-center space-x-3'>
              <FontAwesomeIcon icon={faArrowLeftLong} /> <p>Back</p>
            </Link>
          </div>
          <div className='py-8'>
            <p className='font-poppins-semibold md:text-5xl text-4xl text-black text-left'>
              Forgot Password?
            </p>
            <p className='font-inter-medium text-sm pt-4 text-left text-gray-400'>
              Please enter your email address you used to create your account,
              and we’ll send you a link to reset your password
            </p>
          </div>
          {get(errors, 'submit') && (
            <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
              <p className='font-inter-medium text-white text-base'>
                {get(errors, 'submit').message}
              </p>
            </div>
          )}
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'usernameOrEmail'>
              name='usernameOrEmail'
              control={control}
              label='Email address'
              placeholder='Email address'
              startAdornment={
                <FontAwesomeIcon
                  icon={faEnvelope}
                  fontSize={20}
                  className='text-primary-skyblue'
                />
              }
              shouldUnregister={false}
              rules={{
                required: 'Please enter email address',
                ...EMAIL_VALIDATIONS,
              }}
            />
          </div>

          <div className='flex w-full pt-9'>
            <Button
              label='Continue'
              fullWidth={isMobile}
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronsRight}
                  fontSize={16}
                  className='text-white'
                />
              }
              gradientVariant='mintyfresh'
              isSubmitting={isSubmitting}
              onPress={() => {
                if (get(errors, 'submit')) {
                  clearErrors('submit');
                }
                handleSubmit(onSubmit)();
              }}
            />
          </div>
        </div>
      </div>
      <AuthPageImage />
      <AnalyticsEventOnLoad
        eventName={AnalyticsEventEnum.FORGOT_PASSWORD_SCREEN_VIEWED}
      />
    </PageLayout>
  );
};

export default ForgotPasswordRoute;
