import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

interface ControlledCurrencyInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  placeholder: string;
  readonly?: boolean;
  disabled?: boolean;
  label?: string;
}

const ControlledCurrencyInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  placeholder,
  readonly = false,
  disabled = false,
  shouldUnregister = true,
  name,
  label,
  ...controllerProps
}: ControlledCurrencyInputProps<TFieldValues, TName>) => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    name,
    shouldUnregister,
  });

  const placeholderText = isFocused ? '' : placeholder;

  const isShowLabel = !!label && (isFocused || !!value);

  const variantBorderStylesMap = classNames([
    'flex flex-row flex-shrink items-stretch justify-between overflow-hidden border rounded-lg',
    isFocused ? 'border-black' : 'border-silver',
  ]);

  const variantViewStylesMap = classNames([
    'flex flex-row justify-between items-center w-full',
    readonly || disabled ? 'bg-whitesmoke' : 'bg-transparent',
  ]);

  const textInputStylesMap = classNames([
    'flex items-center justify-center flex-grow flex-shrink font-inter-regular text-base px-2 py-3 leading-4 text-black appearance-none focus:outline-none focus:ring-0',
  ]);

  return (
    <div>
      {isShowLabel && <ControlledInputLabelComponent label={label} />}

      <div className={variantBorderStylesMap}>
        <div className={variantViewStylesMap}>
          <div className='pl-2'>
            <FontAwesomeIcon
              icon={faDollarSign}
              fontSize={18}
              className='text-primary-skyblue'
            />
          </div>
          <CurrencyInput
            value={value}
            name={name}
            readOnly={readonly}
            disabled={disabled}
            placeholder={placeholderText}
            onValueChange={(evt) => {
              onChange(evt || '');
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              onBlur();
              setIsFocused(false);
            }}
            className={textInputStylesMap}
          />
        </div>
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledCurrencyInput;
