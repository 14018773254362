import { faArrowDownArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { intersection, values } from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AddressStateOrProvinceEnum,
  FieldObject,
} from '../../../openapi/atlantis';
import { capitalizeEnum } from '../../../utils/EnumHelper';
import {
  FieldValidationType,
  getGoogleAutoCompleteInputValidations,
} from '../../../utils/Validations';
import Button from '../../Button';
import ControlledGoogleAutoCompleteSearchInput from '../../inputs/ControlledGoogleAutoCompleteSearchInput';
import ControlledSingleSelectDropDownInput from '../../inputs/ControlledSingleSelectDropDownInput';
import ControlledTextInput from '../../inputs/ControlledTextInput';

interface AddressInputProps {
  name: string;
  field: FieldObject;
}

const AddressInput: React.FC<AddressInputProps> = ({ name, field }) => {
  const { control, setValue } = useFormContext();
  const [isManualAddress, setIsManualAddress] = useState<boolean>(false);

  React.useEffect(() => {
    setValue(`${name}.isManualAddress`, isManualAddress);
  }, [isManualAddress, name, setValue]);

  const isAddressFieldRequired = (fields: string[]): string | undefined => {
    // Get the list of required address component validations from the field validations
    const requiredAddressFieldValidation = [
      ...((field?.validations || []) as FieldValidationType[]),
    ]
      .filter((validation) => validation.type === 'addressComponent')
      .map((validation) => validation.value);

    // Check if any of the specified fields are required
    return intersection(requiredAddressFieldValidation, fields).length > 0
      ? 'Required'
      : undefined;
  };

  return (
    <div>
      {isManualAddress ? (
        <div>
          <div>
            <ControlledTextInput
              name={`${name}.streetAddress1`}
              placeholder='Street'
              label='Street Address'
              control={control}
              autoComplete='address-line1'
              rules={{ required: isAddressFieldRequired(['streetAddress1']) }}
            />
          </div>
          <div className='mt-6'>
            <ControlledTextInput
              label='Street Address 2'
              name={`${name}.streetAddress2`}
              placeholder='Street Line 2'
              control={control}
              autoComplete='address-line2'
            />
          </div>
          <div className='mt-6'>
            <ControlledTextInput
              name={`${name}.county`}
              label='County'
              placeholder='County'
              control={control}
              autoComplete='address-level3'
              rules={{
                required: isAddressFieldRequired(['streetAddress1', 'county']),
              }}
            />
          </div>
          <div className='mt-6'>
            <ControlledTextInput
              label='City'
              name={`${name}.city`}
              placeholder='City'
              control={control}
              autoComplete='address-level2'
              rules={{
                required: isAddressFieldRequired([
                  'streetAddress1',
                  'county',
                  'city',
                ]),
              }}
            />
          </div>
          <div className='mt-6'>
            <ControlledSingleSelectDropDownInput
              name={`${name}.stateOrProvince`}
              label='State'
              placeholder='State'
              control={control}
              options={values(AddressStateOrProvinceEnum).map((c) => ({
                label: capitalizeEnum(c),
                value: c,
              }))}
              autoComplete='address-level1'
              rules={{ required: 'Required' }}
            />
          </div>
          <div className='mt-6'>
            <ControlledTextInput
              name={`${name}.zipOrPostalCode`}
              placeholder='Zip Code'
              control={control}
              label='Zip Code'
              autoComplete='postal-code'
              rules={{ required: 'Required' }}
            />
          </div>
        </div>
      ) : (
        <ControlledGoogleAutoCompleteSearchInput
          name={name}
          control={control}
          label={field.label!}
          //@ts-ignore
          placeholder={field.placeholder}
          rules={getGoogleAutoCompleteInputValidations(field)}
        />
      )}
      <div className='flex flex-row my-3 justify-end'>
        <Button
          label={isManualAddress ? 'Search Address' : 'Enter address manually'}
          onPress={() => setIsManualAddress(!isManualAddress)}
          variant='no-border'
          buttonSize='sm'
          textSize='xs'
          textStyle='text-primary-skyblue'
          leftIcon={
            <FontAwesomeIcon
              icon={faArrowDownArrowUp}
              fontSize={12}
              className='text-primary-skyblue mb-0.5'
            />
          }
        />
      </div>
    </div>
  );
};

export default AddressInput;
