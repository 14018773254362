import {
  faArrowRightFromBracket,
  faFolder,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOutUser } from '../../slices/AuthSlice';
import { useAppDispatch } from '../../slices/store';

interface ConfigDrivenContainerLayoutHeaderProps {
  hideMyLoans?: boolean;
}

const ConfigDrivenContainerLayoutHeader: FC<
  ConfigDrivenContainerLayoutHeaderProps
> = ({ hideMyLoans }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div className='lg:flex hidden flex-row justify-end p-5 -z-20 bg-transparent'>
      <div className='flex flex-row items-end justify-end gap-x-5'>
        {!hideMyLoans && (
          <button
            className='flex flex-row items-center'
            onClick={() => navigate('/loans')}
          >
            <FontAwesomeIcon icon={faFolder} size='lg' />
            <p className='font-inter-regular text-lg ml-2'>My Loans</p>
          </button>
        )}
        <button
          className='flex flex-row items-center'
          onClick={async () => {
            await dispatch(signOutUser());
            navigate('/');
          }}
        >
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            className='text-black ml-4'
            size='lg'
          />
          <p className='font-inter-regular text-lg ml-2'>Logout</p>
        </button>
      </div>
    </div>
  );
};

export default ConfigDrivenContainerLayoutHeader;
