import { FC } from 'react';
import {
  AnswerApplicationQuestionRequest,
  Segment,
} from '../../../openapi/atlantis';
import ConfigDrivenSegment from './ConfigDrivenSegment';

interface ConfigDrivenSegmentsProps {
  segments?: Segment[];
  onSubmit: (e: AnswerApplicationQuestionRequest) => void;
  isSubmitting: boolean;
  checklistItemId?: string;
}

const ConfigDrivenSegments: FC<ConfigDrivenSegmentsProps> = ({
  segments = [],
  onSubmit,
  isSubmitting,
  checklistItemId,
}) => {
  return (
    <div>
      {segments.map((segment) => (
        <ConfigDrivenSegment
          key={segment.name}
          segment={segment}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          checklistItemId={checklistItemId}
        />
      ))}
    </div>
  );
};

export default ConfigDrivenSegments;
