import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';

export interface DefaultLoaderProps {
  noPadding?: boolean;
  iconSize?: number;
}

const DefaultLoader: React.FC<DefaultLoaderProps> = ({
  noPadding,
  iconSize,
}) => {
  return (
    <div
      className={classNames('w-full h-full flex items-center justify-center', {
        'p-5': !noPadding,
      })}
      title='spinning-loader'
    >
      <FontAwesomeIcon
        icon={faArrowsRotate}
        fontSize={iconSize ? iconSize : 28}
        className={classNames('animate-spin', 'text-zen-primary')}
      />
    </div>
  );
};

export default DefaultLoader;
