import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { ISelectOption } from '../../types';
import FieldErrorMessage from './FieldErrorMessage';
import FileInputComponent from './FileInputComponent';

export interface ControlledFileInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  sendTo: string;
  options: ISelectOption[];
  checklistItemId: string;
}

const ControlledFileInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  sendTo,
  options,
  checklistItemId,
  shouldUnregister = true,
  ...controllerProps
}: ControlledFileInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  return (
    <div>
      <FileInputComponent sendTo={sendTo} checklistItemId={checklistItemId} />
      <div className='flex flex-row mt-8'>
        {(options || []).map((option, index) => {
          const optionValue = option.value.toString();
          const isSelected = optionValue === value;

          return (
            <div
              key={option.label}
              className={`w-1/2 ${index % 2 === 0 ? 'pr-2' : 'pl-2'}`}
            >
              <button
                onClick={() => {
                  if (!isSelected) {
                    onChange(optionValue);
                  }
                }}
                className={`flex flex-row w-full items-center justify-center p-4 rounded-xl ${
                  isSelected
                    ? 'border-2 border-primary-skyblue bg-primary-skyblue bg-opacity-10'
                    : 'bg-transparent border border-black'
                }`}
              >
                <p
                  className={`font-inter-medium text-base ${
                    isSelected ? 'text-black' : 'text-primary-graphite'
                  }`}
                >
                  {option.label}
                </p>
                {isSelected && (
                  <FontAwesomeIcon
                    icon={value === 'true' ? faThumbsUp : faThumbsDown}
                    fontSize={20}
                    className='text-primary-skyblue ml-2'
                  />
                )}
              </button>
            </div>
          );
        })}
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledFileInput;
