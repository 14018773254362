import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import RealLogo from '../assets/img/logo.svg';
import AuthPageImage from '../components/AuthPageImage';
import Button from '../components/Button';
import PageLayout from '../components/PageLayout';

interface EmailSentRouteProps {}

const EmailSentRoute: React.FC<EmailSentRouteProps> = () => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className='order-2 md:order-1 md:col-span-5 md:h-screen grid md:grid-flow-row md:grid-rows-6 md:justify-between max-w-lg mx-auto'>
        <div className='md:row-span-1 md:my-auto md:block hidden'>
          <img src={RealLogo} alt='placeholder' width={100} height={35} />
        </div>
        <div className='md:row-span-4 md:my-auto mx-5 md:mx-0'>
          <div className='py-8'>
            <p className='font-poppins-semibold md:text-5xl text-4xl text-black text-left'>
              Reset email sent!
            </p>
            <p className='font-inter-medium text-sm pt-4 text-left text-gray-400'>
              You can reset your password by following instructions in your
              email.
            </p>
          </div>
          <div className='flex w-full pt-9'>
            <Button
              label='Login'
              fullWidth={isMobile}
              gradientVariant='mintyfresh'
              onPress={() => {
                navigate('/login');
              }}
            />
          </div>
        </div>
      </div>
      <AuthPageImage />
    </PageLayout>
  );
};

export default EmailSentRoute;
