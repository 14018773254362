import classNames from 'classnames';
import React from 'react';

export interface PopoverOptionInterFace {
  title: string;
  onPress: () => void;
  icon?: React.ReactElement;
  disabled?: boolean;
}

interface PopoverOptionProps {
  option: PopoverOptionInterFace;
}

const PopoverOption: React.FC<PopoverOptionProps> = ({ option }) => {
  return (
    <button
      className={classNames(
        'flex flex-row items-center',
        option.disabled ? 'opacity-50' : 'opacity-100',
      )}
      onClick={option.onPress}
      disabled={option.disabled}
    >
      {!!option.icon && <div className='mr-2'>{option.icon}</div>}
      <div>
        <p
          className={classNames(
            'font-inter-medium text-base hover:underline',
            option.disabled ? 'text-gray-3' : 'text-black',
          )}
        >
          {option.title}
        </p>
      </div>
    </button>
  );
};

export default PopoverOption;
