/* tslint:disable */
/* eslint-disable */
/**
 * Sherlock API
 * Sherlock Main Application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddFileReferencesRequest
 */
export interface AddFileReferencesRequest {
    /**
     * 
     * @type {Array<FileReferenceInfo>}
     * @memberof AddFileReferencesRequest
     */
    'references': Array<FileReferenceInfo>;
}
/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponse
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface BatchArchiveRequest
 */
export interface BatchArchiveRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchArchiveRequest
     */
    'checklistIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BatchPatchChecklistItem
 */
export interface BatchPatchChecklistItem {
    /**
     * 
     * @type {string}
     * @memberof BatchPatchChecklistItem
     */
    'checklistId': string;
    /**
     * 
     * @type {PatchChecklistRequest}
     * @memberof BatchPatchChecklistItem
     */
    'patch': PatchChecklistRequest;
}
/**
 * 
 * @export
 * @interface BatchPatchChecklistRequest
 */
export interface BatchPatchChecklistRequest {
    /**
     * 
     * @type {Array<BatchPatchChecklistItem>}
     * @memberof BatchPatchChecklistRequest
     */
    'items'?: Array<BatchPatchChecklistItem>;
}
/**
 * 
 * @export
 * @interface ChecklistDefaultsDto
 */
export interface ChecklistDefaultsDto {
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefaultsDto
     */
    'targetType': ChecklistDefaultsDtoTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefaultsDto
     */
    'defaultChecklistId': string;
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefaultsDto
     */
    'defaultJourneyId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ChecklistDefaultsDtoTargetTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}

/**
 * 
 * @export
 * @interface ChecklistDefinitionDto
 */
export interface ChecklistDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefinitionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefinitionDto
     */
    'name': string;
    /**
     * 
     * @type {Array<ItemDefinitionDto>}
     * @memberof ChecklistDefinitionDto
     */
    'items'?: Array<ItemDefinitionDto>;
}
/**
 * 
 * @export
 * @interface ChecklistProgressResponse
 */
export interface ChecklistProgressResponse {
    /**
     * 
     * @type {string}
     * @memberof ChecklistProgressResponse
     */
    'checklistId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChecklistProgressResponse
     */
    'itemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistProgressResponse
     */
    'completedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistProgressResponse
     */
    'itemCountIncludingOptionals'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistProgressResponse
     */
    'completedCountIncludingOptionals'?: number;
}
/**
 * 
 * @export
 * @interface ChecklistResponse
 */
export interface ChecklistResponse {
    /**
     * 
     * @type {string}
     * @memberof ChecklistResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChecklistResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChecklistResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<ItemResponse>}
     * @memberof ChecklistResponse
     */
    'items'?: Array<ItemResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof ChecklistResponse
     */
    'approved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChecklistResponse
     */
    'locked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChecklistResponse
     */
    'checklistDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChecklistResponse
     */
    'fileApiVersion'?: ChecklistResponseFileApiVersionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ChecklistResponseFileApiVersionEnum {
    V1 = 'V1',
    V2 = 'V2'
}

/**
 * 
 * @export
 * @interface ConditionSets
 */
export interface ConditionSets {
    /**
     * 
     * @type {Array<DefinedConditionPair>}
     * @memberof ConditionSets
     */
    'definedConditionPairs'?: Array<DefinedConditionPair>;
    /**
     * 
     * @type {Array<ScriptConditionPair>}
     * @memberof ConditionSets
     */
    'scriptConditionPairs'?: Array<ScriptConditionPair>;
}
/**
 * 
 * @export
 * @interface CreateChecklistRequest
 */
export interface CreateChecklistRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistRequest
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistRequest
     */
    'parentType'?: CreateChecklistRequestParentTypeEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateChecklistRequest
     */
    'assignees'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistRequest
     */
    'brokerAgentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistRequest
     */
    'ownerAgentId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateChecklistRequestParentTypeEnum {
    Transaction = 'TRANSACTION',
    Referral = 'REFERRAL',
    InternalReferral = 'INTERNAL_REFERRAL',
    Listing = 'LISTING',
    ChecklistItem = 'CHECKLIST_ITEM',
    Dropbox = 'DROPBOX',
    Real = 'REAL',
    Office = 'OFFICE',
    Loan = 'LOAN',
    Agent = 'AGENT',
    Borrower = 'BORROWER',
    MortgageLead = 'MORTGAGE_LEAD',
    Application = 'APPLICATION',
    Checklist = 'CHECKLIST',
    Email = 'EMAIL',
    Comment = 'COMMENT',
    Team = 'TEAM',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Wallet = 'WALLET',
    Voice = 'VOICE'
}

/**
 * 
 * @export
 * @interface CreateTriggerDefRequest
 */
export interface CreateTriggerDefRequest {
    /**
     * 
     * @type {ConditionSets}
     * @memberof CreateTriggerDefRequest
     */
    'conditionSets': ConditionSets;
    /**
     * 
     * @type {string}
     * @memberof CreateTriggerDefRequest
     */
    'actionName': CreateTriggerDefRequestActionNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateTriggerDefRequestActionNameEnum {
    AcceptChecklistItem = 'ACCEPT_CHECKLIST_ITEM',
    ResetChecklistItem = 'RESET_CHECKLIST_ITEM',
    HideItem = 'HIDE_ITEM',
    ShowItem = 'SHOW_ITEM',
    TransitionTxReadyForCommissionDocumentGeneration = 'TRANSITION_TX_READY_FOR_COMMISSION_DOCUMENT_GENERATION',
    MarkTxAsCompliant = 'MARK_TX_AS_COMPLIANT'
}

/**
 * 
 * @export
 * @interface DefinedConditionPair
 */
export interface DefinedConditionPair {
    /**
     * 
     * @type {string}
     * @memberof DefinedConditionPair
     */
    'eventType': DefinedConditionPairEventTypeEnum;
    /**
     * 
     * @type {EventSubscription}
     * @memberof DefinedConditionPair
     */
    'eventSubscription'?: EventSubscription;
    /**
     * 
     * @type {string}
     * @memberof DefinedConditionPair
     */
    'conditionName'?: DefinedConditionPairConditionNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DefinedConditionPairEventTypeEnum {
    System = 'SYSTEM',
    Timer = 'TIMER'
}
/**
    * @export
    * @enum {string}
    */
export enum DefinedConditionPairConditionNameEnum {
    NoCondition = 'NO_CONDITION',
    TxFirmDateHasPassed = 'TX_FIRM_DATE_HAS_PASSED',
    TxClosingDateHasPassed = 'TX_CLOSING_DATE_HAS_PASSED',
    TxClosingDateHasPassedAndCommissionHeldByReal = 'TX_CLOSING_DATE_HAS_PASSED_AND_COMMISSION_HELD_BY_REAL',
    TxHasAgentsPaidByReal = 'TX_HAS_AGENTS_PAID_BY_REAL',
    TxClosingDateHasPassedAndNoAgentsPaidByReal = 'TX_CLOSING_DATE_HAS_PASSED_AND_NO_AGENTS_PAID_BY_REAL',
    TxIsRezenClosed = 'TX_IS_REZEN_CLOSED',
    TxIsRezenClosedAndZeroBalancedTrustAndCommissionAccounts = 'TX_IS_REZEN_CLOSED_AND_ZERO_BALANCED_TRUST_AND_COMMISSION_ACCOUNTS',
    TxHasRebateFee = 'TX_HAS_REBATE_FEE',
    TxHasRebateFeeAndZeroBalancedTrustAndCommissionAccounts = 'TX_HAS_REBATE_FEE_AND_ZERO_BALANCED_TRUST_AND_COMMISSION_ACCOUNTS',
    AllRequiredItemsAccepted = 'ALL_REQUIRED_ITEMS_ACCEPTED',
    AllRequiredItemsAcceptedOrPending = 'ALL_REQUIRED_ITEMS_ACCEPTED_OR_PENDING',
    AllReqForPaymentItemsAccepted = 'ALL_REQ_FOR_PAYMENT_ITEMS_ACCEPTED',
    AllReqForCommissionDocGenerationItemsAccepted = 'ALL_REQ_FOR_COMMISSION_DOC_GENERATION_ITEMS_ACCEPTED',
    AllReqForCommissionDocGenerationItemsArePendingOrAccepted = 'ALL_REQ_FOR_COMMISSION_DOC_GENERATION_ITEMS_ARE_PENDING_OR_ACCEPTED',
    AllReqForTerminationItemsAccepted = 'ALL_REQ_FOR_TERMINATION_ITEMS_ACCEPTED',
    AllReqForTransactionItemsAccepted = 'ALL_REQ_FOR_TRANSACTION_ITEMS_ACCEPTED',
    ConfirmedCommissionInstallmentsEqExpectedToReal = 'CONFIRMED_COMMISSION_INSTALLMENTS_EQ_EXPECTED_TO_REAL',
    TrustAndCommissionAccountBalancesAreZero = 'TRUST_AND_COMMISSION_ACCOUNT_BALANCES_ARE_ZERO',
    CommissionHeldByReal = 'COMMISSION_HELD_BY_REAL',
    AllBrokerRequiredItemsAccepted = 'ALL_BROKER_REQUIRED_ITEMS_ACCEPTED',
    AllBrokerRequiredItemsAcceptedOrPending = 'ALL_BROKER_REQUIRED_ITEMS_ACCEPTED_OR_PENDING',
    BooleanQuestionAnsweredYes = 'BOOLEAN_QUESTION_ANSWERED_YES',
    BooleanQuestionAnsweredNo = 'BOOLEAN_QUESTION_ANSWERED_NO',
    SelectQuestionAnsweredOpt1 = 'SELECT_QUESTION_ANSWERED_OPT_1',
    SelectQuestionAnsweredOpt2 = 'SELECT_QUESTION_ANSWERED_OPT_2',
    SelectQuestionAnsweredOpt3 = 'SELECT_QUESTION_ANSWERED_OPT_3',
    SelectQuestionAnsweredOpt4 = 'SELECT_QUESTION_ANSWERED_OPT_4',
    SelectQuestionAnsweredOpt5 = 'SELECT_QUESTION_ANSWERED_OPT_5',
    SelectQuestionAnsweredOpt6 = 'SELECT_QUESTION_ANSWERED_OPT_6',
    SelectQuestionAnsweredOpt7 = 'SELECT_QUESTION_ANSWERED_OPT_7',
    SelectQuestionAnsweredOpt8 = 'SELECT_QUESTION_ANSWERED_OPT_8',
    SelectQuestionAnsweredOpt9 = 'SELECT_QUESTION_ANSWERED_OPT_9',
    SelectQuestionAnsweredOpt10 = 'SELECT_QUESTION_ANSWERED_OPT_10',
    SelectQuestionAnsweredOpt11 = 'SELECT_QUESTION_ANSWERED_OPT_11',
    SelectQuestionAnsweredOpt12 = 'SELECT_QUESTION_ANSWERED_OPT_12'
}

/**
 * 
 * @export
 * @interface DocumentDefinitionDto
 */
export interface DocumentDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDefinitionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDefinitionDto
     */
    'itemDefinitionId'?: string;
    /**
     * 
     * @type {Array<DocumentVersionDefinitionDto>}
     * @memberof DocumentDefinitionDto
     */
    'versions'?: Array<DocumentVersionDefinitionDto>;
}
/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<VersionDto>}
     * @memberof DocumentDto
     */
    'versions'?: Array<VersionDto>;
    /**
     * 
     * @type {VersionDto}
     * @memberof DocumentDto
     */
    'currentVersion'?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'documentDefinitionId'?: string;
}
/**
 * 
 * @export
 * @interface DocumentVersionDefinitionDto
 */
export interface DocumentVersionDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDefinitionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDefinitionDto
     */
    'documentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDefinitionDto
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDefinitionDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDefinitionDto
     */
    'uploadedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentVersionDefinitionDto
     */
    'uploadedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDefinitionDto
     */
    'path'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentVersionDefinitionDto
     */
    'data'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EventSubscription
 */
export interface EventSubscription {
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'event'?: EventSubscriptionEventEnum;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'domainEventName'?: EventSubscriptionDomainEventNameEnum;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'checklistDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'itemDefinitionId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EventSubscriptionEventEnum {
    AgentAnniversaryUpdated = 'AGENT_ANNIVERSARY_UPDATED',
    AgentNationalIdentificationUpdated = 'AGENT_NATIONAL_IDENTIFICATION_UPDATED',
    AgentJoinedTeam = 'AGENT_JOINED_TEAM',
    AgentLeftTeam = 'AGENT_LEFT_TEAM',
    AgentSwitchedTeams = 'AGENT_SWITCHED_TEAMS',
    AgentTerminated = 'AGENT_TERMINATED',
    AgentPaymentDetailsInvalidUpdated = 'AGENT_PAYMENT_DETAILS_INVALID_UPDATED',
    AgentSingleCheckPreferenceUpdated = 'AGENT_SINGLE_CHECK_PREFERENCE_UPDATED',
    AgentActivated = 'AGENT_ACTIVATED',
    AgentEmailUpdated = 'AGENT_EMAIL_UPDATED',
    AgentReachedElite = 'AGENT_REACHED_ELITE',
    AgentCapped = 'AGENT_CAPPED',
    AgentApplicationStatusUpdated = 'AGENT_APPLICATION_STATUS_UPDATED',
    TransactionCreated = 'TRANSACTION_CREATED',
    CommissionValidated = 'COMMISSION_VALIDATED',
    TransactionApprovedClosing = 'TRANSACTION_APPROVED_CLOSING',
    TransactionPaid = 'TRANSACTION_PAID',
    TransactionSettled = 'TRANSACTION_SETTLED',
    TransactionClosed = 'TRANSACTION_CLOSED',
    TransactionPaymentAccepted = 'TRANSACTION_PAYMENT_ACCEPTED',
    TransactionPaymentScheduled = 'TRANSACTION_PAYMENT_SCHEDULED',
    DepositReceived = 'DEPOSIT_RECEIVED',
    TransactionApprovedForCdGeneration = 'TRANSACTION_APPROVED_FOR_CD_GENERATION',
    CommissionDocumentApproved = 'COMMISSION_DOCUMENT_APPROVED',
    CommissionPayerAssigned = 'COMMISSION_PAYER_ASSIGNED',
    CommissionDocumentGenerated = 'COMMISSION_DOCUMENT_GENERATED',
    CommissionDocumentSent = 'COMMISSION_DOCUMENT_SENT',
    CommissionDocumentUpdated = 'COMMISSION_DOCUMENT_UPDATED',
    ConveyanceValidatedEvent = 'CONVEYANCE_VALIDATED_EVENT',
    ConveyanceInvalidatedEvent = 'CONVEYANCE_INVALIDATED_EVENT',
    DepositConfirmed = 'DEPOSIT_CONFIRMED',
    TrustDepositSubmitted = 'TRUST_DEPOSIT_SUBMITTED',
    TrustDepositSubmittedInFull = 'TRUST_DEPOSIT_SUBMITTED_IN_FULL',
    TrustDepositConfirmedInFull = 'TRUST_DEPOSIT_CONFIRMED_IN_FULL',
    TrustDepositInstallmentSubmitted = 'TRUST_DEPOSIT_INSTALLMENT_SUBMITTED',
    TrustDepositInstallmentConfirmed = 'TRUST_DEPOSIT_INSTALLMENT_CONFIRMED',
    CommissionDepositSubmitted = 'COMMISSION_DEPOSIT_SUBMITTED',
    CommissionDepositSubmittedInFull = 'COMMISSION_DEPOSIT_SUBMITTED_IN_FULL',
    CommissionDepositConfirmedInFull = 'COMMISSION_DEPOSIT_CONFIRMED_IN_FULL',
    CommissionDepositInstallmentSubmitted = 'COMMISSION_DEPOSIT_INSTALLMENT_SUBMITTED',
    CommissionDepositInstallmentConfirmed = 'COMMISSION_DEPOSIT_INSTALLMENT_CONFIRMED',
    ExcessFundsReleased = 'EXCESS_FUNDS_RELEASED',
    FundsReleaseReverted = 'FUNDS_RELEASE_REVERTED',
    TransactionTerminationRequested = 'TRANSACTION_TERMINATION_REQUESTED',
    TransactionTerminated = 'TRANSACTION_TERMINATED',
    TransactionParticipantAdded = 'TRANSACTION_PARTICIPANT_ADDED',
    TransactionParticipantUpdated = 'TRANSACTION_PARTICIPANT_UPDATED',
    TransactionParticipantRemoved = 'TRANSACTION_PARTICIPANT_REMOVED',
    TransactionFeeAdded = 'TRANSACTION_FEE_ADDED',
    TransactionFeeRemoved = 'TRANSACTION_FEE_REMOVED',
    TransactionAddressUpdated = 'TRANSACTION_ADDRESS_UPDATED',
    TransactionYearBuiltUpdated = 'TRANSACTION_YEAR_BUILT_UPDATED',
    TransactionMlsNumberUpdated = 'TRANSACTION_MLS_NUMBER_UPDATED',
    TransactionEscrowNumberUpdated = 'TRANSACTION_ESCROW_NUMBER_UPDATED',
    TransactionSalePriceUpdated = 'TRANSACTION_SALE_PRICE_UPDATED',
    TransactionListingCommissionUpdated = 'TRANSACTION_LISTING_COMMISSION_UPDATED',
    TransactionSaleCommissionUpdated = 'TRANSACTION_SALE_COMMISSION_UPDATED',
    TransactionContractAcceptanceDateUpdated = 'TRANSACTION_CONTRACT_ACCEPTANCE_DATE_UPDATED',
    TransactionActualClosingDateUpdated = 'TRANSACTION_ACTUAL_CLOSING_DATE_UPDATED',
    TransactionEstimatedClosingDateUpdated = 'TRANSACTION_ESTIMATED_CLOSING_DATE_UPDATED',
    TransactionFirmDateUpdated = 'TRANSACTION_FIRM_DATE_UPDATED',
    TransactionMarkedAsCompliant = 'TRANSACTION_MARKED_AS_COMPLIANT',
    TransactionMarkedAsNonCompliant = 'TRANSACTION_MARKED_AS_NON_COMPLIANT',
    DoubleEnderTransactionLinked = 'DOUBLE_ENDER_TRANSACTION_LINKED',
    DoubleEnderTransactionUnlinked = 'DOUBLE_ENDER_TRANSACTION_UNLINKED',
    TransactionInternalReferralUnlinked = 'TRANSACTION_INTERNAL_REFERRAL_UNLINKED',
    TransactionInternalReferralLinked = 'TRANSACTION_INTERNAL_REFERRAL_LINKED',
    TxReminderWaitForApproveCdCalculation = 'TX_REMINDER_WAIT_FOR_APPROVE_CD_CALCULATION',
    TxReminderWaitForSendCd = 'TX_REMINDER_WAIT_FOR_SEND_CD',
    TxReminderListingIsAboutToExpire = 'TX_REMINDER_LISTING_IS_ABOUT_TO_EXPIRE',
    TxReminderListingHasExpired = 'TX_REMINDER_LISTING_HAS_EXPIRED',
    TxReminderIsAboutToClose = 'TX_REMINDER_IS_ABOUT_TO_CLOSE',
    TxReminderHasExpired = 'TX_REMINDER_HAS_EXPIRED',
    TransactionTaxExemptUpdated = 'TRANSACTION_TAX_EXEMPT_UPDATED',
    TransactionTaxPaidFromCommissionUpdated = 'TRANSACTION_TAX_PAID_FROM_COMMISSION_UPDATED',
    TransactionTaxRatesUpdated = 'TRANSACTION_TAX_RATES_UPDATED',
    TransactionTaxAmountUpdated = 'TRANSACTION_TAX_AMOUNT_UPDATED',
    ListingCreated = 'LISTING_CREATED',
    ChecklistCreatedEvent = 'CHECKLIST_CREATED_EVENT',
    ChecklistItemCreated = 'CHECKLIST_ITEM_CREATED',
    ChecklistItemNotStarted = 'CHECKLIST_ITEM_NOT_STARTED',
    ChecklistItemPending = 'CHECKLIST_ITEM_PENDING',
    ChecklistItemWaitingForReview = 'CHECKLIST_ITEM_WAITING_FOR_REVIEW',
    ChecklistItemInReview = 'CHECKLIST_ITEM_IN_REVIEW',
    ChecklistItemRevisionRequested = 'CHECKLIST_ITEM_REVISION_REQUESTED',
    ChecklistItemAccepted = 'CHECKLIST_ITEM_ACCEPTED',
    ChecklistItemDeleted = 'CHECKLIST_ITEM_DELETED',
    ChecklistItemStatusUpdated = 'CHECKLIST_ITEM_STATUS_UPDATED',
    ChecklistItemNameUpdated = 'CHECKLIST_ITEM_NAME_UPDATED',
    ChecklistItemRequiredStatusChanged = 'CHECKLIST_ITEM_REQUIRED_STATUS_CHANGED',
    ChecklistItemDescriptionUpdated = 'CHECKLIST_ITEM_DESCRIPTION_UPDATED',
    ChecklistItemAssigneeUpdated = 'CHECKLIST_ITEM_ASSIGNEE_UPDATED',
    ChecklistItemDueDateUpdated = 'CHECKLIST_ITEM_DUE_DATE_UPDATED',
    ChecklistItemUrgentStatusUpdated = 'CHECKLIST_ITEM_URGENT_STATUS_UPDATED',
    ChecklistItemRequiredStatusUpdated = 'CHECKLIST_ITEM_REQUIRED_STATUS_UPDATED',
    ChecklistItemLabelAddedEvent = 'CHECKLIST_ITEM_LABEL_ADDED_EVENT',
    ChecklistItemLabelRemovedEvent = 'CHECKLIST_ITEM_LABEL_REMOVED_EVENT',
    ChecklistItemFileUploaded = 'CHECKLIST_ITEM_FILE_UPLOADED',
    ChecklistItemFileVersionUploaded = 'CHECKLIST_ITEM_FILE_VERSION_UPLOADED',
    ChecklistItemFileDeleted = 'CHECKLIST_ITEM_FILE_DELETED',
    ChecklistItemFileReferenceAttached = 'CHECKLIST_ITEM_FILE_REFERENCE_ATTACHED',
    ChecklistItemFileReferenceRemoved = 'CHECKLIST_ITEM_FILE_REFERENCE_REMOVED',
    ChecklistItemAssigned = 'CHECKLIST_ITEM_ASSIGNED',
    ChecklistItemUnassigned = 'CHECKLIST_ITEM_UNASSIGNED',
    JourneyItemCompleted = 'JOURNEY_ITEM_COMPLETED',
    JourneyItemIncomplete = 'JOURNEY_ITEM_INCOMPLETE',
    EmailReceivedEvent = 'EMAIL_RECEIVED_EVENT',
    OfficePaymentSystemUpdated = 'OFFICE_PAYMENT_SYSTEM_UPDATED',
    OfficePaymentBatchUpdated = 'OFFICE_PAYMENT_BATCH_UPDATED',
    OfficeRealWithholdsTaxUpdated = 'OFFICE_REAL_WITHHOLDS_TAX_UPDATED',
    OfficeTaxesUpdated = 'OFFICE_TAXES_UPDATED',
    OfficeTaxPaidFromCommissionUpdated = 'OFFICE_TAX_PAID_FROM_COMMISSION_UPDATED',
    MortgageLeadCreated = 'MORTGAGE_LEAD_CREATED',
    MortgageBorrowerInvited = 'MORTGAGE_BORROWER_INVITED',
    MortgageCoBorrowerAdded = 'MORTGAGE_CO_BORROWER_ADDED',
    MortgageCoBorrowerInvited = 'MORTGAGE_CO_BORROWER_INVITED',
    MortgageApplicationStarted = 'MORTGAGE_APPLICATION_STARTED',
    MortgageApplicationSubmitted = 'MORTGAGE_APPLICATION_SUBMITTED',
    MortgageLoanPreApproved = 'MORTGAGE_LOAN_PRE_APPROVED',
    DropboxCreatedEvent = 'DROPBOX_CREATED_EVENT',
    DropboxFileCreatedEvent = 'DROPBOX_FILE_CREATED_EVENT',
    DropboxFileVersionAddedEvent = 'DROPBOX_FILE_VERSION_ADDED_EVENT',
    DropboxFileDeletedEvent = 'DROPBOX_FILE_DELETED_EVENT',
    DropboxFileMovedToTrashEvent = 'DROPBOX_FILE_MOVED_TO_TRASH_EVENT',
    DropboxFileRestoredFromTrashEvent = 'DROPBOX_FILE_RESTORED_FROM_TRASH_EVENT',
    DropboxFileNameUpdatedEvent = 'DROPBOX_FILE_NAME_UPDATED_EVENT',
    CommentCreated = 'COMMENT_CREATED',
    CommentEdited = 'COMMENT_EDITED',
    WebhookEndpointTestEvent = 'WEBHOOK_ENDPOINT_TEST_EVENT',
    FundReleaseRequestSubmitted = 'FUND_RELEASE_REQUEST_SUBMITTED',
    FundReleaseRequestUpdated = 'FUND_RELEASE_REQUEST_UPDATED',
    FundReleaseRequestApprovedByFinance = 'FUND_RELEASE_REQUEST_APPROVED_BY_FINANCE',
    FundReleaseRequestApprovedByBroker = 'FUND_RELEASE_REQUEST_APPROVED_BY_BROKER',
    FundReleaseRequestCancelled = 'FUND_RELEASE_REQUEST_CANCELLED',
    FundReleaseRequestRestored = 'FUND_RELEASE_REQUEST_RESTORED',
    FundReleaseRequestReleased = 'FUND_RELEASE_REQUEST_RELEASED',
    FundReleaseRequestUndoApproval = 'FUND_RELEASE_REQUEST_UNDO_APPROVAL',
    FundReleaseRequestReverted = 'FUND_RELEASE_REQUEST_REVERTED',
    FundReleaseRequestAttachmentUploaded = 'FUND_RELEASE_REQUEST_ATTACHMENT_UPLOADED',
    FundReleaseRequestAttachmentReplaced = 'FUND_RELEASE_REQUEST_ATTACHMENT_REPLACED',
    FundReleaseRequestAttachmentDeleted = 'FUND_RELEASE_REQUEST_ATTACHMENT_DELETED',
    OutgoingPaymentSentToPaymentSystem = 'OUTGOING_PAYMENT_SENT_TO_PAYMENT_SYSTEM',
    TeamMembershipUpdated = 'TEAM_MEMBERSHIP_UPDATED',
    TeamLeaderAdded = 'TEAM_LEADER_ADDED',
    TeamLeaderRemoved = 'TEAM_LEADER_REMOVED',
    WalletCreated = 'WALLET_CREATED',
    WalletBankingPartnerApplicationCreated = 'WALLET_BANKING_PARTNER_APPLICATION_CREATED',
    WalletBankingPartnerApplicationSubmitted = 'WALLET_BANKING_PARTNER_APPLICATION_SUBMITTED',
    WalletBankingPartnerApplicationPendingDocuments = 'WALLET_BANKING_PARTNER_APPLICATION_PENDING_DOCUMENTS',
    WalletBankingPartnerApplicationPendingReview = 'WALLET_BANKING_PARTNER_APPLICATION_PENDING_REVIEW',
    WalletBankingPartnerApplicationApproved = 'WALLET_BANKING_PARTNER_APPLICATION_APPROVED',
    WalletBankingPartnerApplicationDenied = 'WALLET_BANKING_PARTNER_APPLICATION_DENIED',
    WalletBankingPartnerApplicationCanceled = 'WALLET_BANKING_PARTNER_APPLICATION_CANCELED',
    WalletBankingPartnershipCreated = 'WALLET_BANKING_PARTNERSHIP_CREATED',
    WalletAccountCreated = 'WALLET_ACCOUNT_CREATED',
    WalletAccountApplicationSubmitted = 'WALLET_ACCOUNT_APPLICATION_SUBMITTED',
    WalletAccountApplicationApproved = 'WALLET_ACCOUNT_APPLICATION_APPROVED',
    WalletAccountApplicationMarkedForReview = 'WALLET_ACCOUNT_APPLICATION_MARKED_FOR_REVIEW',
    WalletAccountApplicationRejected = 'WALLET_ACCOUNT_APPLICATION_REJECTED',
    WalletAccountApplicationCanceled = 'WALLET_ACCOUNT_APPLICATION_CANCELED',
    WalletBankCardCreated = 'WALLET_BANK_CARD_CREATED',
    WalletBankCardActivated = 'WALLET_BANK_CARD_ACTIVATED',
    WalletBankCardFrozen = 'WALLET_BANK_CARD_FROZEN',
    WalletBankCardUnfrozen = 'WALLET_BANK_CARD_UNFROZEN',
    WalletBankCardExpired = 'WALLET_BANK_CARD_EXPIRED',
    WalletBankCardLost = 'WALLET_BANK_CARD_LOST',
    WalletBankCardStolen = 'WALLET_BANK_CARD_STOLEN',
    WalletBankCardSuspectedOfFraud = 'WALLET_BANK_CARD_SUSPECTED_OF_FRAUD',
    WalletBankCardClearedOfFraudSuspicion = 'WALLET_BANK_CARD_CLEARED_OF_FRAUD_SUSPICION',
    WalletBankCardClosed = 'WALLET_BANK_CARD_CLOSED',
    WalletBankCardReissued = 'WALLET_BANK_CARD_REISSUED',
    WalletBankCardPinSet = 'WALLET_BANK_CARD_PIN_SET',
    WalletChargeAuthorizationRequested = 'WALLET_CHARGE_AUTHORIZATION_REQUESTED',
    WalletChargeAuthorizationUserApprovalRequested = 'WALLET_CHARGE_AUTHORIZATION_USER_APPROVAL_REQUESTED',
    WalletChargeAuthorizationRequestApprovedByUser = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED_BY_USER',
    WalletChargeAuthorizationRequestDeclinedByUser = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED_BY_USER',
    WalletChargeAuthorizationRequestApprovedByBankingPartner = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED_BY_BANKING_PARTNER',
    WalletChargeAuthorizationRequestDeclinedByBankingPartner = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED_BY_BANKING_PARTNER',
    WalletChargeAuthorizationRequestApproved = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_APPROVED',
    WalletChargeAuthorizationRequestDeclined = 'WALLET_CHARGE_AUTHORIZATION_REQUEST_DECLINED',
    VoiceCallStarted = 'VOICE_CALL_STARTED',
    VoiceRecordingComplete = 'VOICE_RECORDING_COMPLETE',
    VoiceRecordingTranscribed = 'VOICE_RECORDING_TRANSCRIBED'
}
/**
    * @export
    * @enum {string}
    */
export enum EventSubscriptionDomainEventNameEnum {
    ItemBeingAccepted = 'ITEM_BEING_ACCEPTED'
}

/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageClientTypeEnum {
    Producer = 'PRODUCER',
    Consumer = 'CONSUMER'
}
/**
    * @export
    * @enum {string}
    */
export enum FailedMessageStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageUpdateStatusRequestStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FileReferenceDto
 */
export interface FileReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'fileId': string;
}
/**
 * 
 * @export
 * @interface FileReferenceInfo
 */
export interface FileReferenceInfo {
    /**
     * 
     * @type {string}
     * @memberof FileReferenceInfo
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceInfo
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface FileReferencesDto
 */
export interface FileReferencesDto {
    /**
     * 
     * @type {Array<FileReferenceDto>}
     * @memberof FileReferencesDto
     */
    'references'?: Array<FileReferenceDto>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseChecklistDefinitionDto
 */
export interface GenericSearchResponseChecklistDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseChecklistDefinitionDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseChecklistDefinitionDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseChecklistDefinitionDto
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseChecklistDefinitionDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseChecklistDefinitionDto
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseChecklistDefinitionDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<ChecklistDefinitionDto>}
     * @memberof GenericSearchResponseChecklistDefinitionDto
     */
    'results'?: Array<ChecklistDefinitionDto>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

/**
    * @export
    * @enum {string}
    */
export enum GenericSearchResponseFailedMessageSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface ItemDefinitionDto
 */
export interface ItemDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof ItemDefinitionDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemDefinitionDto
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemDefinitionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDefinitionDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemDefinitionDto
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItemDefinitionDto
     */
    'defaultAssignee'?: ItemDefinitionDtoDefaultAssigneeEnum;
    /**
     * 
     * @type {number}
     * @memberof ItemDefinitionDto
     */
    'daysUntilDue'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemDefinitionDto
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {Array<DocumentDefinitionDto>}
     * @memberof ItemDefinitionDto
     */
    'documents'?: Array<DocumentDefinitionDto>;
    /**
     * 
     * @type {Array<TriggerDefinitionDto>}
     * @memberof ItemDefinitionDto
     */
    'triggerDefinitions'?: Array<TriggerDefinitionDto>;
    /**
     * 
     * @type {TemplateReferencesDefinitionDto}
     * @memberof ItemDefinitionDto
     */
    'templateReferences'?: TemplateReferencesDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof ItemDefinitionDto
     */
    'requiredFor'?: ItemDefinitionDtoRequiredForEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ItemDefinitionDto
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {WrapperObject}
     * @memberof ItemDefinitionDto
     */
    'data'?: WrapperObject;
}

/**
    * @export
    * @enum {string}
    */
export enum ItemDefinitionDtoDefaultAssigneeEnum {
    Agent = 'AGENT',
    Broker = 'BROKER',
    Tc = 'TC',
    Admin = 'ADMIN',
    System = 'SYSTEM',
    Borrower = 'BORROWER',
    LoanOfficer = 'LOAN_OFFICER'
}
/**
    * @export
    * @enum {string}
    */
export enum ItemDefinitionDtoRequiredForEnum {
    CommissionDocGeneration = 'COMMISSION_DOC_GENERATION',
    Payment = 'PAYMENT',
    Transactions = 'TRANSACTIONS',
    Termination = 'TERMINATION'
}

/**
 * 
 * @export
 * @interface ItemDto
 */
export interface ItemDto {
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemDto
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemDto
     */
    'required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemDto
     */
    'urgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemDto
     */
    'systemGenerated'?: boolean;
    /**
     * 
     * @type {Array<LabelDto>}
     * @memberof ItemDto
     */
    'labels'?: Array<LabelDto>;
    /**
     * 
     * @type {Array<DocumentDto>}
     * @memberof ItemDto
     */
    'documents'?: Array<DocumentDto>;
    /**
     * 
     * @type {Array<TriggerDto>}
     * @memberof ItemDto
     */
    'triggers'?: Array<TriggerDto>;
    /**
     * 
     * @type {FileReferencesDto}
     * @memberof ItemDto
     */
    'fileReferences'?: FileReferencesDto;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemDto
     */
    'completedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    'status': ItemDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    'requiredFor'?: ItemDtoRequiredForEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ItemDto
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {WrapperObject}
     * @memberof ItemDto
     */
    'data'?: WrapperObject;
    /**
     * 
     * @type {boolean}
     * @memberof ItemDto
     */
    'skipped'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemDto
     */
    'complete'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ItemDtoStatusEnum {
    NotStarted = 'NOT_STARTED',
    Pending = 'PENDING',
    WaitingForReview = 'WAITING_FOR_REVIEW',
    InReview = 'IN_REVIEW',
    RevisionRequested = 'REVISION_REQUESTED',
    Done = 'DONE',
    Accepted = 'ACCEPTED'
}
/**
    * @export
    * @enum {string}
    */
export enum ItemDtoRequiredForEnum {
    CommissionDocGeneration = 'COMMISSION_DOC_GENERATION',
    Payment = 'PAYMENT',
    Transactions = 'TRANSACTIONS',
    Termination = 'TERMINATION'
}

/**
 * 
 * @export
 * @interface ItemRequest
 */
export interface ItemRequest {
    /**
     * 
     * @type {ItemDto}
     * @memberof ItemRequest
     */
    'item': ItemDto;
}
/**
 * 
 * @export
 * @interface ItemResponse
 */
export interface ItemResponse {
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'itemDefinitionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'dueDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'urgent'?: boolean;
    /**
     * 
     * @type {Array<LabelDto>}
     * @memberof ItemResponse
     */
    'labels'?: Array<LabelDto>;
    /**
     * 
     * @type {Array<DocumentDto>}
     * @memberof ItemResponse
     */
    'documents'?: Array<DocumentDto>;
    /**
     * 
     * @type {Array<TriggerDto>}
     * @memberof ItemResponse
     */
    'triggers'?: Array<TriggerDto>;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'markedCompletedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'completedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'systemGenerated'?: boolean;
    /**
     * 
     * @type {FileReferencesDto}
     * @memberof ItemResponse
     */
    'fileReferences'?: FileReferencesDto;
    /**
     * 
     * @type {TemplateReferencesDto}
     * @memberof ItemResponse
     */
    'templateReferences'?: TemplateReferencesDto;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'status': ItemResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'requiredFor'?: ItemResponseRequiredForEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'skipped'?: boolean;
    /**
     * 
     * @type {WrapperObject}
     * @memberof ItemResponse
     */
    'data'?: WrapperObject;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'complete'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ItemResponseStatusEnum {
    NotStarted = 'NOT_STARTED',
    Pending = 'PENDING',
    WaitingForReview = 'WAITING_FOR_REVIEW',
    InReview = 'IN_REVIEW',
    RevisionRequested = 'REVISION_REQUESTED',
    Done = 'DONE',
    Accepted = 'ACCEPTED'
}
/**
    * @export
    * @enum {string}
    */
export enum ItemResponseRequiredForEnum {
    CommissionDocGeneration = 'COMMISSION_DOC_GENERATION',
    Payment = 'PAYMENT',
    Transactions = 'TRANSACTIONS',
    Termination = 'TERMINATION'
}

/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface LabelDto
 */
export interface LabelDto {
    /**
     * 
     * @type {string}
     * @memberof LabelDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LabelDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof LabelDto
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface PagedChecklistDefResponse
 */
export interface PagedChecklistDefResponse {
    /**
     * 
     * @type {string}
     * @memberof PagedChecklistDefResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PagedChecklistDefResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedChecklistDefResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedChecklistDefResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedChecklistDefResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagedChecklistDefResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<ChecklistDefinitionDto>}
     * @memberof PagedChecklistDefResponse
     */
    'list'?: Array<ChecklistDefinitionDto>;
}
/**
 * 
 * @export
 * @interface PatchChecklistItemRequest
 */
export interface PatchChecklistItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchChecklistItemRequest
     */
    'itemStatus'?: PatchChecklistItemRequestItemStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PatchChecklistItemRequestItemStatusEnum {
    NotStarted = 'NOT_STARTED',
    Pending = 'PENDING',
    WaitingForReview = 'WAITING_FOR_REVIEW',
    InReview = 'IN_REVIEW',
    RevisionRequested = 'REVISION_REQUESTED',
    Done = 'DONE',
    Accepted = 'ACCEPTED'
}

/**
 * 
 * @export
 * @interface PatchChecklistRequest
 */
export interface PatchChecklistRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PatchChecklistRequest
     */
    'locked'?: boolean;
}
/**
 * 
 * @export
 * @interface RemoveFileReferencesRequest
 */
export interface RemoveFileReferencesRequest {
    /**
     * 
     * @type {Array<FileReferenceInfo>}
     * @memberof RemoveFileReferencesRequest
     */
    'references': Array<FileReferenceInfo>;
}
/**
 * 
 * @export
 * @interface ResetChecklistItemsRequest
 */
export interface ResetChecklistItemsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResetChecklistItemsRequest
     */
    'itemIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ScriptConditionEvaluationResponse
 */
export interface ScriptConditionEvaluationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ScriptConditionEvaluationResponse
     */
    'result'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScriptConditionEvaluationResponse
     */
    'compiled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScriptConditionEvaluationResponse
     */
    'executed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScriptConditionEvaluationResponse
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScriptConditionEvaluationResponse
     */
    'debugMessage'?: string;
}
/**
 * 
 * @export
 * @interface ScriptConditionPair
 */
export interface ScriptConditionPair {
    /**
     * 
     * @type {string}
     * @memberof ScriptConditionPair
     */
    'eventType'?: ScriptConditionPairEventTypeEnum;
    /**
     * 
     * @type {EventSubscription}
     * @memberof ScriptConditionPair
     */
    'eventSubscription'?: EventSubscription;
    /**
     * 
     * @type {string}
     * @memberof ScriptConditionPair
     */
    'scriptCondition'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ScriptConditionPairEventTypeEnum {
    System = 'SYSTEM',
    Timer = 'TIMER'
}

/**
 * 
 * @export
 * @interface ScriptConditionPreviewRequest
 */
export interface ScriptConditionPreviewRequest {
    /**
     * 
     * @type {string}
     * @memberof ScriptConditionPreviewRequest
     */
    'script': string;
}
/**
 * 
 * @export
 * @interface SearchOrder
 */
export interface SearchOrder {
    /**
     * 
     * @type {string}
     * @memberof SearchOrder
     */
    'column'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchOrder
     */
    'asc'?: boolean;
}
/**
 * 
 * @export
 * @interface SearchParam
 */
export interface SearchParam {
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    'operator'?: SearchParamOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    'column'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    'value'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParam
     */
    'values'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    'joinColumn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchParam
     */
    'stringStrictCase'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchParamOperatorEnum {
    Like = 'LIKE',
    In = 'IN',
    Eq = 'EQ',
    Lt = 'LT',
    Gt = 'GT',
    Lte = 'LTE',
    Gte = 'GTE',
    IsNull = 'IS_NULL',
    IsNotNull = 'IS_NOT_NULL'
}

/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    'targetObject'?: SearchRequestTargetObjectEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRequest
     */
    'joinOn'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRequest
     */
    'responseColumns'?: Array<string>;
    /**
     * 
     * @type {Array<SearchParam>}
     * @memberof SearchRequest
     */
    'filterAnd'?: Array<SearchParam>;
    /**
     * 
     * @type {Array<SearchParam>}
     * @memberof SearchRequest
     */
    'filterOr'?: Array<SearchParam>;
    /**
     * 
     * @type {Array<SearchOrder>}
     * @memberof SearchRequest
     */
    'order'?: Array<SearchOrder>;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchRequestTargetObjectEnum {
    ChecklistDefinition = 'CHECKLIST_DEFINITION',
    ItemDefinition = 'ITEM_DEFINITION'
}

/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    'targetObject'?: SearchResponseTargetObjectEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchResponse
     */
    'responseColumns'?: Array<string>;
    /**
     * 
     * @type {Array<SearchParam>}
     * @memberof SearchResponse
     */
    'params'?: Array<SearchParam>;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof SearchResponse
     */
    'responseRows'?: Array<Array<any>>;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResponse
     */
    'hasNext'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchResponseTargetObjectEnum {
    ChecklistDefinition = 'CHECKLIST_DEFINITION',
    ItemDefinition = 'ITEM_DEFINITION'
}

/**
 * 
 * @export
 * @interface TemplateReferenceDefinitionDto
 */
export interface TemplateReferenceDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof TemplateReferenceDefinitionDto
     */
    'dropboxId': string;
    /**
     * 
     * @type {string}
     * @memberof TemplateReferenceDefinitionDto
     */
    'fileId': string;
}
/**
 * 
 * @export
 * @interface TemplateReferenceDto
 */
export interface TemplateReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof TemplateReferenceDto
     */
    'dropboxId': string;
    /**
     * 
     * @type {string}
     * @memberof TemplateReferenceDto
     */
    'fileId': string;
}
/**
 * 
 * @export
 * @interface TemplateReferencesDefinitionDto
 */
export interface TemplateReferencesDefinitionDto {
    /**
     * 
     * @type {Array<TemplateReferenceDefinitionDto>}
     * @memberof TemplateReferencesDefinitionDto
     */
    'references'?: Array<TemplateReferenceDefinitionDto>;
}
/**
 * 
 * @export
 * @interface TemplateReferencesDto
 */
export interface TemplateReferencesDto {
    /**
     * 
     * @type {Array<TemplateReferenceDto>}
     * @memberof TemplateReferencesDto
     */
    'references'?: Array<TemplateReferenceDto>;
}
/**
 * 
 * @export
 * @interface TriggerDefinitionDto
 */
export interface TriggerDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof TriggerDefinitionDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TriggerDefinitionDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {ConditionSets}
     * @memberof TriggerDefinitionDto
     */
    'conditionSets'?: ConditionSets;
    /**
     * 
     * @type {string}
     * @memberof TriggerDefinitionDto
     */
    'actionName'?: TriggerDefinitionDtoActionNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TriggerDefinitionDtoActionNameEnum {
    AcceptChecklistItem = 'ACCEPT_CHECKLIST_ITEM',
    ResetChecklistItem = 'RESET_CHECKLIST_ITEM',
    HideItem = 'HIDE_ITEM',
    ShowItem = 'SHOW_ITEM',
    TransitionTxReadyForCommissionDocumentGeneration = 'TRANSITION_TX_READY_FOR_COMMISSION_DOCUMENT_GENERATION',
    MarkTxAsCompliant = 'MARK_TX_AS_COMPLIANT'
}

/**
 * 
 * @export
 * @interface TriggerDto
 */
export interface TriggerDto {
    /**
     * 
     * @type {string}
     * @memberof TriggerDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TriggerDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof TriggerDto
     */
    'triggerDefinitionId'?: string;
    /**
     * 
     * @type {ConditionSets}
     * @memberof TriggerDto
     */
    'conditionSets'?: ConditionSets;
    /**
     * 
     * @type {string}
     * @memberof TriggerDto
     */
    'actionName'?: TriggerDtoActionNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TriggerDto
     */
    'fired'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TriggerDtoActionNameEnum {
    AcceptChecklistItem = 'ACCEPT_CHECKLIST_ITEM',
    ResetChecklistItem = 'RESET_CHECKLIST_ITEM',
    HideItem = 'HIDE_ITEM',
    ShowItem = 'SHOW_ITEM',
    TransitionTxReadyForCommissionDocumentGeneration = 'TRANSITION_TX_READY_FOR_COMMISSION_DOCUMENT_GENERATION',
    MarkTxAsCompliant = 'MARK_TX_AS_COMPLIANT'
}

/**
 * 
 * @export
 * @interface UpdateItemDefinitionRequest
 */
export interface UpdateItemDefinitionRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateItemDefinitionRequest
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemDefinitionRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemDefinitionRequest
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemDefinitionRequest
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemDefinitionRequest
     */
    'defaultAssignee'?: UpdateItemDefinitionRequestDefaultAssigneeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemDefinitionRequest
     */
    'daysUntilDue': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateItemDefinitionRequest
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {Array<DocumentDefinitionDto>}
     * @memberof UpdateItemDefinitionRequest
     */
    'documents'?: Array<DocumentDefinitionDto>;
    /**
     * 
     * @type {Array<TriggerDefinitionDto>}
     * @memberof UpdateItemDefinitionRequest
     */
    'triggerDefinitions'?: Array<TriggerDefinitionDto>;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemDefinitionRequest
     */
    'requiredFor'?: UpdateItemDefinitionRequestRequiredForEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemDefinitionRequest
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {TemplateReferencesDefinitionDto}
     * @memberof UpdateItemDefinitionRequest
     */
    'templateReferences'?: TemplateReferencesDefinitionDto;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateItemDefinitionRequestDefaultAssigneeEnum {
    Agent = 'AGENT',
    Broker = 'BROKER',
    Tc = 'TC',
    Admin = 'ADMIN',
    System = 'SYSTEM',
    Borrower = 'BORROWER',
    LoanOfficer = 'LOAN_OFFICER'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateItemDefinitionRequestRequiredForEnum {
    CommissionDocGeneration = 'COMMISSION_DOC_GENERATION',
    Payment = 'PAYMENT',
    Transactions = 'TRANSACTIONS',
    Termination = 'TERMINATION'
}

/**
 * 
 * @export
 * @interface VersionDto
 */
export interface VersionDto {
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof VersionDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof VersionDto
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    'uploaderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    'documentVersionDefinitionId'?: string;
}
/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}
/**
 * 
 * @export
 * @interface WrapperObject
 */
export interface WrapperObject {
    /**
     * 
     * @type {any}
     * @memberof WrapperObject
     */
    'message'?: any;
    /**
     * 
     * @type {string}
     * @memberof WrapperObject
     */
    '_class'?: string;
}

/**
 * ArchiveApi - axios parameter creator
 * @export
 */
export const ArchiveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive checklists
         * @param {BatchArchiveRequest} batchArchiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive: async (batchArchiveRequest: BatchArchiveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchArchiveRequest' is not null or undefined
            assertParamExists('archive', 'batchArchiveRequest', batchArchiveRequest)
            const localVarPath = `/api/v1/checklists/batch-archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchArchiveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArchiveApi - functional programming interface
 * @export
 */
export const ArchiveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArchiveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive checklists
         * @param {BatchArchiveRequest} batchArchiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archive(batchArchiveRequest: BatchArchiveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archive(batchArchiveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArchiveApi - factory interface
 * @export
 */
export const ArchiveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArchiveApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive checklists
         * @param {BatchArchiveRequest} batchArchiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(batchArchiveRequest: BatchArchiveRequest, options?: any): AxiosPromise<void> {
            return localVarFp.archive(batchArchiveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArchiveApi - object-oriented interface
 * @export
 * @class ArchiveApi
 * @extends {BaseAPI}
 */
export class ArchiveApi extends BaseAPI {
    /**
     * 
     * @summary Archive checklists
     * @param {BatchArchiveRequest} batchArchiveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchiveApi
     */
    public archive(batchArchiveRequest: BatchArchiveRequest, options?: AxiosRequestConfig) {
        return ArchiveApiFp(this.configuration).archive(batchArchiveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistApi - axios parameter creator
 * @export
 */
export const ChecklistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update all checklists with the given ids
         * @param {BatchPatchChecklistRequest} batchPatchChecklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchChecklist: async (batchPatchChecklistRequest: BatchPatchChecklistRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchPatchChecklistRequest' is not null or undefined
            assertParamExists('batchPatchChecklist', 'batchPatchChecklistRequest', batchPatchChecklistRequest)
            const localVarPath = `/api/v1/checklists/batch-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchPatchChecklistRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Checklist for given ChecklistDefinitionId
         * @param {string} checklistDefinitionId 
         * @param {CreateChecklistRequest} createChecklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklist: async (checklistDefinitionId: string, createChecklistRequest: CreateChecklistRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistDefinitionId' is not null or undefined
            assertParamExists('createChecklist', 'checklistDefinitionId', checklistDefinitionId)
            // verify required parameter 'createChecklistRequest' is not null or undefined
            assertParamExists('createChecklist', 'createChecklistRequest', createChecklistRequest)
            const localVarPath = `/api/v1/checklists/{checklistDefinitionId}`
                .replace(`{${"checklistDefinitionId"}}`, encodeURIComponent(String(checklistDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChecklistRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create ChecklistItem for given ChecklistId and ChecklistItem request
         * @param {string} checklistId 
         * @param {ItemRequest} itemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklistItem: async (checklistId: string, itemRequest: ItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('createChecklistItem', 'checklistId', checklistId)
            // verify required parameter 'itemRequest' is not null or undefined
            assertParamExists('createChecklistItem', 'itemRequest', itemRequest)
            const localVarPath = `/api/v1/checklists/{checklistId}/items`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete the given documentId
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocument', 'documentId', documentId)
            const localVarPath = `/api/v1/checklists/checklist-documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete ChecklistItem for given ChecklistId
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedChecklistItem: async (checklistItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('deletedChecklistItem', 'checklistItemId', checklistItemId)
            const localVarPath = `/api/v1/checklists/checklist-items/{checklistItemId}`
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checklist for given Id
         * @param {string} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistById: async (checklistId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('getChecklistById', 'checklistId', checklistId)
            const localVarPath = `/api/v1/checklists/{checklistId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checklistItem for given Id
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistItemById: async (checklistItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('getChecklistItemById', 'checklistItemId', checklistItemId)
            const localVarPath = `/api/v1/checklists/checklist-items/{checklistItemId}`
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checklists progress for given checklist Ids
         * @param {Array<string>} checklistIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistProgressByIds: async (checklistIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistIds' is not null or undefined
            assertParamExists('getChecklistProgressByIds', 'checklistIds', checklistIds)
            const localVarPath = `/api/v1/checklists/progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (checklistIds) {
                localVarQueryParameter['checklistIds'] = checklistIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document for the given Id
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentById: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentById', 'documentId', documentId)
            const localVarPath = `/api/v1/checklists/checklist-documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all items in checklist by label name
         * @param {string} checklistId 
         * @param {string} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsByLabel: async (checklistId: string, label: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('getItemsByLabel', 'checklistId', checklistId)
            // verify required parameter 'label' is not null or undefined
            assertParamExists('getItemsByLabel', 'label', label)
            const localVarPath = `/api/v1/checklists/{checklistId}/items-by-label/{label}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)))
                .replace(`{${"label"}}`, encodeURIComponent(String(label)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get next not started item in checklist
         * @param {string} checklistId 
         * @param {string} [assignedTo] 
         * @param {boolean} [includeSkipped] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextItem: async (checklistId: string, assignedTo?: string, includeSkipped?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('getNextItem', 'checklistId', checklistId)
            const localVarPath = `/api/v1/checklists/{checklistId}/next-item`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (assignedTo !== undefined) {
                localVarQueryParameter['assignedTo'] = assignedTo;
            }

            if (includeSkipped !== undefined) {
                localVarQueryParameter['includeSkipped'] = includeSkipped;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the version url
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionUrl: async (versionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('getVersionUrl', 'versionId', versionId)
            const localVarPath = `/api/v1/checklists/checklist-documents/versions/{versionId}/download`
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the Checklist for a given id
         * @param {string} checklistId 
         * @param {PatchChecklistRequest} patchChecklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchChecklist: async (checklistId: string, patchChecklistRequest: PatchChecklistRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('patchChecklist', 'checklistId', checklistId)
            // verify required parameter 'patchChecklistRequest' is not null or undefined
            assertParamExists('patchChecklist', 'patchChecklistRequest', patchChecklistRequest)
            const localVarPath = `/api/v1/checklists/{checklistId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchChecklistRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset the checklist items for the given item ids
         * @param {ResetChecklistItemsRequest} resetChecklistItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetChecklistItems: async (resetChecklistItemsRequest: ResetChecklistItemsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetChecklistItemsRequest' is not null or undefined
            assertParamExists('resetChecklistItems', 'resetChecklistItemsRequest', resetChecklistItemsRequest)
            const localVarPath = `/api/v1/checklists/checklist-items/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetChecklistItemsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Skip the given checklist item
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipChecklistItem: async (checklistItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('skipChecklistItem', 'checklistItemId', checklistItemId)
            const localVarPath = `/api/v1/checklists/checklist-items/{checklistItemId}/skip`
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update ChecklistItem for given ChecklistItemId and ChecklistItem request
         * @param {string} checklistItemId 
         * @param {ItemRequest} itemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistItem: async (checklistItemId: string, itemRequest: ItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('updateChecklistItem', 'checklistItemId', checklistItemId)
            // verify required parameter 'itemRequest' is not null or undefined
            assertParamExists('updateChecklistItem', 'itemRequest', itemRequest)
            const localVarPath = `/api/v1/checklists/checklist-items/{checklistItemId}`
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update complete
         * @param {string} checklistItemId 
         * @param {boolean} [isComplete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComplete: async (checklistItemId: string, isComplete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('updateComplete', 'checklistItemId', checklistItemId)
            const localVarPath = `/api/v1/checklists/checklist-items/{checklistItemId}/complete`
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isComplete !== undefined) {
                localVarQueryParameter['isComplete'] = isComplete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update current version for the given documentId
         * @param {string} documentId 
         * @param {DocumentDto} documentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentMeta: async (documentId: string, documentDto: DocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateDocumentMeta', 'documentId', documentId)
            // verify required parameter 'documentDto' is not null or undefined
            assertParamExists('updateDocumentMeta', 'documentDto', documentDto)
            const localVarPath = `/api/v1/checklists/checklist-documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add a new document to the given checklistItemId
         * @param {string} checklistItemId 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {string} [uploaderId] 
         * @param {File} [file] 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewDocument: async (checklistItemId: string, name?: string, description?: string, uploaderId?: string, file?: File, transactionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('uploadNewDocument', 'checklistItemId', checklistItemId)
            const localVarPath = `/api/v1/checklists/checklist-items/{checklistItemId}/documents`
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (uploaderId !== undefined) { 
                localVarFormParams.append('uploaderId', uploaderId as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (transactionId !== undefined) { 
                localVarFormParams.append('transactionId', transactionId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add a new version for given documentId
         * @param {string} checklistDocumentId 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {string} [uploaderId] 
         * @param {File} [file] 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewVersion: async (checklistDocumentId: string, name?: string, description?: string, uploaderId?: string, file?: File, transactionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistDocumentId' is not null or undefined
            assertParamExists('uploadNewVersion', 'checklistDocumentId', checklistDocumentId)
            const localVarPath = `/api/v1/checklists/checklist-documents/{checklistDocumentId}/versions`
                .replace(`{${"checklistDocumentId"}}`, encodeURIComponent(String(checklistDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (uploaderId !== undefined) { 
                localVarFormParams.append('uploaderId', uploaderId as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (transactionId !== undefined) { 
                localVarFormParams.append('transactionId', transactionId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistApi - functional programming interface
 * @export
 */
export const ChecklistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update all checklists with the given ids
         * @param {BatchPatchChecklistRequest} batchPatchChecklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchPatchChecklist(batchPatchChecklistRequest: BatchPatchChecklistRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchPatchChecklist(batchPatchChecklistRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Checklist for given ChecklistDefinitionId
         * @param {string} checklistDefinitionId 
         * @param {CreateChecklistRequest} createChecklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChecklist(checklistDefinitionId: string, createChecklistRequest: CreateChecklistRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChecklist(checklistDefinitionId, createChecklistRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create ChecklistItem for given ChecklistId and ChecklistItem request
         * @param {string} checklistId 
         * @param {ItemRequest} itemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChecklistItem(checklistId: string, itemRequest: ItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChecklistItem(checklistId, itemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete the given documentId
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete ChecklistItem for given ChecklistId
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletedChecklistItem(checklistItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletedChecklistItem(checklistItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checklist for given Id
         * @param {string} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistById(checklistId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistById(checklistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checklistItem for given Id
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistItemById(checklistItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistItemById(checklistItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checklists progress for given checklist Ids
         * @param {Array<string>} checklistIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistProgressByIds(checklistIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChecklistProgressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistProgressByIds(checklistIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get document for the given Id
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentById(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentById(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all items in checklist by label name
         * @param {string} checklistId 
         * @param {string} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemsByLabel(checklistId: string, label: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemsByLabel(checklistId, label, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get next not started item in checklist
         * @param {string} checklistId 
         * @param {string} [assignedTo] 
         * @param {boolean} [includeSkipped] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextItem(checklistId: string, assignedTo?: string, includeSkipped?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextItem(checklistId, assignedTo, includeSkipped, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the version url
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionUrl(versionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionUrl(versionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the Checklist for a given id
         * @param {string} checklistId 
         * @param {PatchChecklistRequest} patchChecklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchChecklist(checklistId: string, patchChecklistRequest: PatchChecklistRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchChecklist(checklistId, patchChecklistRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset the checklist items for the given item ids
         * @param {ResetChecklistItemsRequest} resetChecklistItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetChecklistItems(resetChecklistItemsRequest: ResetChecklistItemsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetChecklistItems(resetChecklistItemsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Skip the given checklist item
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skipChecklistItem(checklistItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skipChecklistItem(checklistItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update ChecklistItem for given ChecklistItemId and ChecklistItem request
         * @param {string} checklistItemId 
         * @param {ItemRequest} itemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChecklistItem(checklistItemId: string, itemRequest: ItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChecklistItem(checklistItemId, itemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update complete
         * @param {string} checklistItemId 
         * @param {boolean} [isComplete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComplete(checklistItemId: string, isComplete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComplete(checklistItemId, isComplete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update current version for the given documentId
         * @param {string} documentId 
         * @param {DocumentDto} documentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentMeta(documentId: string, documentDto: DocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentMeta(documentId, documentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary add a new document to the given checklistItemId
         * @param {string} checklistItemId 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {string} [uploaderId] 
         * @param {File} [file] 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNewDocument(checklistItemId: string, name?: string, description?: string, uploaderId?: string, file?: File, transactionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNewDocument(checklistItemId, name, description, uploaderId, file, transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary add a new version for given documentId
         * @param {string} checklistDocumentId 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {string} [uploaderId] 
         * @param {File} [file] 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNewVersion(checklistDocumentId: string, name?: string, description?: string, uploaderId?: string, file?: File, transactionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNewVersion(checklistDocumentId, name, description, uploaderId, file, transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistApi - factory interface
 * @export
 */
export const ChecklistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistApiFp(configuration)
    return {
        /**
         * 
         * @summary Update all checklists with the given ids
         * @param {BatchPatchChecklistRequest} batchPatchChecklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchChecklist(batchPatchChecklistRequest: BatchPatchChecklistRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchPatchChecklist(batchPatchChecklistRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Checklist for given ChecklistDefinitionId
         * @param {string} checklistDefinitionId 
         * @param {CreateChecklistRequest} createChecklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklist(checklistDefinitionId: string, createChecklistRequest: CreateChecklistRequest, options?: any): AxiosPromise<ChecklistResponse> {
            return localVarFp.createChecklist(checklistDefinitionId, createChecklistRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create ChecklistItem for given ChecklistId and ChecklistItem request
         * @param {string} checklistId 
         * @param {ItemRequest} itemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklistItem(checklistId: string, itemRequest: ItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.createChecklistItem(checklistId, itemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete the given documentId
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete ChecklistItem for given ChecklistId
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletedChecklistItem(checklistItemId: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.deletedChecklistItem(checklistItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checklist for given Id
         * @param {string} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistById(checklistId: string, options?: any): AxiosPromise<ChecklistResponse> {
            return localVarFp.getChecklistById(checklistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checklistItem for given Id
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistItemById(checklistItemId: string, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.getChecklistItemById(checklistItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checklists progress for given checklist Ids
         * @param {Array<string>} checklistIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistProgressByIds(checklistIds: Array<string>, options?: any): AxiosPromise<Array<ChecklistProgressResponse>> {
            return localVarFp.getChecklistProgressByIds(checklistIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document for the given Id
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentById(documentId: string, options?: any): AxiosPromise<DocumentDto> {
            return localVarFp.getDocumentById(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all items in checklist by label name
         * @param {string} checklistId 
         * @param {string} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsByLabel(checklistId: string, label: string, options?: any): AxiosPromise<Array<ItemResponse>> {
            return localVarFp.getItemsByLabel(checklistId, label, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get next not started item in checklist
         * @param {string} checklistId 
         * @param {string} [assignedTo] 
         * @param {boolean} [includeSkipped] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextItem(checklistId: string, assignedTo?: string, includeSkipped?: boolean, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.getNextItem(checklistId, assignedTo, includeSkipped, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the version url
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionUrl(versionId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getVersionUrl(versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the Checklist for a given id
         * @param {string} checklistId 
         * @param {PatchChecklistRequest} patchChecklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchChecklist(checklistId: string, patchChecklistRequest: PatchChecklistRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchChecklist(checklistId, patchChecklistRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset the checklist items for the given item ids
         * @param {ResetChecklistItemsRequest} resetChecklistItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetChecklistItems(resetChecklistItemsRequest: ResetChecklistItemsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resetChecklistItems(resetChecklistItemsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Skip the given checklist item
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipChecklistItem(checklistItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.skipChecklistItem(checklistItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update ChecklistItem for given ChecklistItemId and ChecklistItem request
         * @param {string} checklistItemId 
         * @param {ItemRequest} itemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistItem(checklistItemId: string, itemRequest: ItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.updateChecklistItem(checklistItemId, itemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update complete
         * @param {string} checklistItemId 
         * @param {boolean} [isComplete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComplete(checklistItemId: string, isComplete?: boolean, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.updateComplete(checklistItemId, isComplete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update current version for the given documentId
         * @param {string} documentId 
         * @param {DocumentDto} documentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentMeta(documentId: string, documentDto: DocumentDto, options?: any): AxiosPromise<DocumentDto> {
            return localVarFp.updateDocumentMeta(documentId, documentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add a new document to the given checklistItemId
         * @param {string} checklistItemId 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {string} [uploaderId] 
         * @param {File} [file] 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewDocument(checklistItemId: string, name?: string, description?: string, uploaderId?: string, file?: File, transactionId?: string, options?: any): AxiosPromise<DocumentDto> {
            return localVarFp.uploadNewDocument(checklistItemId, name, description, uploaderId, file, transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add a new version for given documentId
         * @param {string} checklistDocumentId 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {string} [uploaderId] 
         * @param {File} [file] 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewVersion(checklistDocumentId: string, name?: string, description?: string, uploaderId?: string, file?: File, transactionId?: string, options?: any): AxiosPromise<VersionDto> {
            return localVarFp.uploadNewVersion(checklistDocumentId, name, description, uploaderId, file, transactionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistApi - object-oriented interface
 * @export
 * @class ChecklistApi
 * @extends {BaseAPI}
 */
export class ChecklistApi extends BaseAPI {
    /**
     * 
     * @summary Update all checklists with the given ids
     * @param {BatchPatchChecklistRequest} batchPatchChecklistRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public batchPatchChecklist(batchPatchChecklistRequest: BatchPatchChecklistRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).batchPatchChecklist(batchPatchChecklistRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Checklist for given ChecklistDefinitionId
     * @param {string} checklistDefinitionId 
     * @param {CreateChecklistRequest} createChecklistRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public createChecklist(checklistDefinitionId: string, createChecklistRequest: CreateChecklistRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).createChecklist(checklistDefinitionId, createChecklistRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create ChecklistItem for given ChecklistId and ChecklistItem request
     * @param {string} checklistId 
     * @param {ItemRequest} itemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public createChecklistItem(checklistId: string, itemRequest: ItemRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).createChecklistItem(checklistId, itemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete the given documentId
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public deleteDocument(documentId: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).deleteDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete ChecklistItem for given ChecklistId
     * @param {string} checklistItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public deletedChecklistItem(checklistItemId: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).deletedChecklistItem(checklistItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checklist for given Id
     * @param {string} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public getChecklistById(checklistId: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).getChecklistById(checklistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checklistItem for given Id
     * @param {string} checklistItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public getChecklistItemById(checklistItemId: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).getChecklistItemById(checklistItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checklists progress for given checklist Ids
     * @param {Array<string>} checklistIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public getChecklistProgressByIds(checklistIds: Array<string>, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).getChecklistProgressByIds(checklistIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document for the given Id
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public getDocumentById(documentId: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).getDocumentById(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all items in checklist by label name
     * @param {string} checklistId 
     * @param {string} label 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public getItemsByLabel(checklistId: string, label: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).getItemsByLabel(checklistId, label, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get next not started item in checklist
     * @param {string} checklistId 
     * @param {string} [assignedTo] 
     * @param {boolean} [includeSkipped] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public getNextItem(checklistId: string, assignedTo?: string, includeSkipped?: boolean, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).getNextItem(checklistId, assignedTo, includeSkipped, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the version url
     * @param {string} versionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public getVersionUrl(versionId: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).getVersionUrl(versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the Checklist for a given id
     * @param {string} checklistId 
     * @param {PatchChecklistRequest} patchChecklistRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public patchChecklist(checklistId: string, patchChecklistRequest: PatchChecklistRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).patchChecklist(checklistId, patchChecklistRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset the checklist items for the given item ids
     * @param {ResetChecklistItemsRequest} resetChecklistItemsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public resetChecklistItems(resetChecklistItemsRequest: ResetChecklistItemsRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).resetChecklistItems(resetChecklistItemsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Skip the given checklist item
     * @param {string} checklistItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public skipChecklistItem(checklistItemId: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).skipChecklistItem(checklistItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update ChecklistItem for given ChecklistItemId and ChecklistItem request
     * @param {string} checklistItemId 
     * @param {ItemRequest} itemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public updateChecklistItem(checklistItemId: string, itemRequest: ItemRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).updateChecklistItem(checklistItemId, itemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update complete
     * @param {string} checklistItemId 
     * @param {boolean} [isComplete] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public updateComplete(checklistItemId: string, isComplete?: boolean, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).updateComplete(checklistItemId, isComplete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update current version for the given documentId
     * @param {string} documentId 
     * @param {DocumentDto} documentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public updateDocumentMeta(documentId: string, documentDto: DocumentDto, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).updateDocumentMeta(documentId, documentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add a new document to the given checklistItemId
     * @param {string} checklistItemId 
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {string} [uploaderId] 
     * @param {File} [file] 
     * @param {string} [transactionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public uploadNewDocument(checklistItemId: string, name?: string, description?: string, uploaderId?: string, file?: File, transactionId?: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).uploadNewDocument(checklistItemId, name, description, uploaderId, file, transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add a new version for given documentId
     * @param {string} checklistDocumentId 
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {string} [uploaderId] 
     * @param {File} [file] 
     * @param {string} [transactionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public uploadNewVersion(checklistDocumentId: string, name?: string, description?: string, uploaderId?: string, file?: File, transactionId?: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).uploadNewVersion(checklistDocumentId, name, description, uploaderId, file, transactionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistDefinitionApi - axios parameter creator
 * @export
 */
export const ChecklistDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change the position of an Item within its Checklist definition
         * @param {string} itemId 
         * @param {number} newPosition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeItemDefinitionPosition: async (itemId: string, newPosition: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('changeItemDefinitionPosition', 'itemId', itemId)
            // verify required parameter 'newPosition' is not null or undefined
            assertParamExists('changeItemDefinitionPosition', 'newPosition', newPosition)
            const localVarPath = `/api/v1/checklistDefinition/checklist-items/{itemId}/changePosition`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (newPosition !== undefined) {
                localVarQueryParameter['newPosition'] = newPosition;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Checklist definition
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklistDefinition: async (checklistDefinitionDto: ChecklistDefinitionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistDefinitionDto' is not null or undefined
            assertParamExists('createChecklistDefinition', 'checklistDefinitionDto', checklistDefinitionDto)
            const localVarPath = `/api/v1/checklistDefinition/checklists/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Checklist definition
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklistDefinition1: async (checklistDefinitionDto: ChecklistDefinitionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistDefinitionDto' is not null or undefined
            assertParamExists('createChecklistDefinition1', 'checklistDefinitionDto', checklistDefinitionDto)
            const localVarPath = `/api/v1/checklistDefinition/checklists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new Item definitions for a given Checklist definition
         * @param {string} checklistDefinitionId 
         * @param {Array<ItemDefinitionDto>} itemDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemDefinitions: async (checklistDefinitionId: string, itemDefinitionDto: Array<ItemDefinitionDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistDefinitionId' is not null or undefined
            assertParamExists('createItemDefinitions', 'checklistDefinitionId', checklistDefinitionId)
            // verify required parameter 'itemDefinitionDto' is not null or undefined
            assertParamExists('createItemDefinitions', 'itemDefinitionDto', itemDefinitionDto)
            const localVarPath = `/api/v1/checklistDefinition/checklists/{checklistDefinitionId}/items`
                .replace(`{${"checklistDefinitionId"}}`, encodeURIComponent(String(checklistDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a label for a given Item definition. Case-insensitive
         * @param {string} itemId 
         * @param {string} text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel: async (itemId: string, text: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createLabel', 'itemId', itemId)
            // verify required parameter 'text' is not null or undefined
            assertParamExists('createLabel', 'text', text)
            const localVarPath = `/api/v1/checklistDefinition/checklist-items/{itemId}/labels`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Checklist Definition Item Document and its Version history
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentDefinition: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocumentDefinition', 'documentId', documentId)
            const localVarPath = `/api/v1/checklistDefinition/checklist-documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Item definition by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemDefinitionById: async (itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteItemDefinitionById', 'itemId', itemId)
            const localVarPath = `/api/v1/checklistDefinition/checklist-items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets default checklists that should be applied when an office does not have a suitable default
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} targetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistDefaults: async (targetType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetType' is not null or undefined
            assertParamExists('getChecklistDefaults', 'targetType', targetType)
            const localVarPath = `/api/v1/checklistDefinition/checklists/defaults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Checklist definition by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistDefinitionById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChecklistDefinitionById', 'id', id)
            const localVarPath = `/api/v1/checklistDefinition/checklists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Checklist definitions
         * @param {string} [name] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistDefinitions: async (name?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/checklistDefinition/checklists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Checklist Definition Item Document and its Version history
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDefinition: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentDefinition', 'documentId', documentId)
            const localVarPath = `/api/v1/checklistDefinition/checklist-documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Document Version
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentVersionDefinition: async (versionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('getDocumentVersionDefinition', 'versionId', versionId)
            const localVarPath = `/api/v1/checklistDefinition/checklist-document-versions/{versionId}`
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Item definition by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDefinitionById: async (itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('getItemDefinitionById', 'itemId', itemId)
            const localVarPath = `/api/v1/checklistDefinition/checklist-items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all labels for a given Item definition
         * @param {string} itemDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelsForItemDefinition: async (itemDefinitionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDefinitionId' is not null or undefined
            assertParamExists('getLabelsForItemDefinition', 'itemDefinitionId', itemDefinitionId)
            const localVarPath = `/api/v1/checklistDefinition/checklist-items/{itemDefinitionId}/labels`
                .replace(`{${"itemDefinitionId"}}`, encodeURIComponent(String(itemDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the recently used labels of a Checklist definition
         * @param {string} checklistDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyUsedLabels: async (checklistDefinitionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistDefinitionId' is not null or undefined
            assertParamExists('getRecentlyUsedLabels', 'checklistDefinitionId', checklistDefinitionId)
            const localVarPath = `/api/v1/checklistDefinition/checklist/{checklistDefinitionId}/recentLabels`
                .replace(`{${"checklistDefinitionId"}}`, encodeURIComponent(String(checklistDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Document Version\'s S3 URL
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionS3Url: async (versionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('getVersionS3Url', 'versionId', versionId)
            const localVarPath = `/api/v1/checklistDefinition/checklist-document-versions/{versionId}/download`
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a label for a given Item definition
         * @param {string} itemDefinitionId 
         * @param {string} text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLabelDefinition: async (itemDefinitionId: string, text: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDefinitionId' is not null or undefined
            assertParamExists('removeLabelDefinition', 'itemDefinitionId', itemDefinitionId)
            // verify required parameter 'text' is not null or undefined
            assertParamExists('removeLabelDefinition', 'text', text)
            const localVarPath = `/api/v1/checklistDefinition/checklist-items/{itemDefinitionId}/labels`
                .replace(`{${"itemDefinitionId"}}`, encodeURIComponent(String(itemDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search checklist templates
         * @param {Array<'NAME'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search1: async (sortBy?: Array<'NAME'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/checklistDefinition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the default checklists that should be applied when an office does not have a suitable default
         * @param {ChecklistDefaultsDto} checklistDefaultsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistDefaults: async (checklistDefaultsDto: ChecklistDefaultsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistDefaultsDto' is not null or undefined
            assertParamExists('updateChecklistDefaults', 'checklistDefaultsDto', checklistDefaultsDto)
            const localVarPath = `/api/v1/checklistDefinition/checklists/defaults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistDefaultsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing Checklist definition
         * @param {string} checklistDefinitionId 
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistDefinition: async (checklistDefinitionId: string, checklistDefinitionDto: ChecklistDefinitionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistDefinitionId' is not null or undefined
            assertParamExists('updateChecklistDefinition', 'checklistDefinitionId', checklistDefinitionId)
            // verify required parameter 'checklistDefinitionDto' is not null or undefined
            assertParamExists('updateChecklistDefinition', 'checklistDefinitionDto', checklistDefinitionDto)
            const localVarPath = `/api/v1/checklistDefinition/checklists/{checklistDefinitionId}`
                .replace(`{${"checklistDefinitionId"}}`, encodeURIComponent(String(checklistDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the latest Version of a Checklist Item Document
         * @param {string} documentId 
         * @param {DocumentVersionDefinitionDto} documentVersionDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentDefinitionLatestVersion: async (documentId: string, documentVersionDefinitionDto: DocumentVersionDefinitionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateDocumentDefinitionLatestVersion', 'documentId', documentId)
            // verify required parameter 'documentVersionDefinitionDto' is not null or undefined
            assertParamExists('updateDocumentDefinitionLatestVersion', 'documentVersionDefinitionDto', documentVersionDefinitionDto)
            const localVarPath = `/api/v1/checklistDefinition/checklist-documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentVersionDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Item definition by id
         * @param {string} itemId 
         * @param {UpdateItemDefinitionRequest} updateItemDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemDefinition: async (itemId: string, updateItemDefinitionRequest: UpdateItemDefinitionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('updateItemDefinition', 'itemId', itemId)
            // verify required parameter 'updateItemDefinitionRequest' is not null or undefined
            assertParamExists('updateItemDefinition', 'updateItemDefinitionRequest', updateItemDefinitionRequest)
            const localVarPath = `/api/v1/checklistDefinition/checklist-items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemDefinitionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a new document definition & version to a Checklist Item
         * @param {string} itemDefinitionId 
         * @param {File} file 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (itemDefinitionId: string, file: File, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDefinitionId' is not null or undefined
            assertParamExists('uploadDocument', 'itemDefinitionId', itemDefinitionId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDocument', 'file', file)
            const localVarPath = `/api/v1/checklistDefinition/checklist-items/{itemDefinitionId}/documents`
                .replace(`{${"itemDefinitionId"}}`, encodeURIComponent(String(itemDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a new Version to an existing Checklist Item Document
         * @param {string} documentId 
         * @param {File} file 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentVersionDefinition: async (documentId: string, file: File, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('uploadDocumentVersionDefinition', 'documentId', documentId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDocumentVersionDefinition', 'file', file)
            const localVarPath = `/api/v1/checklistDefinition/checklist-documents/{documentId}/versions`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistDefinitionApi - functional programming interface
 * @export
 */
export const ChecklistDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change the position of an Item within its Checklist definition
         * @param {string} itemId 
         * @param {number} newPosition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeItemDefinitionPosition(itemId: string, newPosition: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeItemDefinitionPosition(itemId, newPosition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new Checklist definition
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChecklistDefinition(checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChecklistDefinition(checklistDefinitionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new Checklist definition
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChecklistDefinition1(checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChecklistDefinition1(checklistDefinitionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new Item definitions for a given Checklist definition
         * @param {string} checklistDefinitionId 
         * @param {Array<ItemDefinitionDto>} itemDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemDefinitions(checklistDefinitionId: string, itemDefinitionDto: Array<ItemDefinitionDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemDefinitionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemDefinitions(checklistDefinitionId, itemDefinitionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a label for a given Item definition. Case-insensitive
         * @param {string} itemId 
         * @param {string} text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLabel(itemId: string, text: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLabel(itemId, text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Checklist Definition Item Document and its Version history
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentDefinition(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentDefinition(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Item definition by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItemDefinitionById(itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItemDefinitionById(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets default checklists that should be applied when an office does not have a suitable default
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} targetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistDefaults(targetType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistDefaultsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistDefaults(targetType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Checklist definition by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistDefinitionById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistDefinitionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Checklist definitions
         * @param {string} [name] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistDefinitions(name?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedChecklistDefResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistDefinitions(name, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Checklist Definition Item Document and its Version history
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentDefinition(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentDefinition(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Document Version
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentVersionDefinition(versionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentVersionDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentVersionDefinition(versionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Item definition by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemDefinitionById(itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemDefinitionById(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all labels for a given Item definition
         * @param {string} itemDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabelsForItemDefinition(itemDefinitionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabelsForItemDefinition(itemDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the recently used labels of a Checklist definition
         * @param {string} checklistDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentlyUsedLabels(checklistDefinitionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentlyUsedLabels(checklistDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Document Version\'s S3 URL
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionS3Url(versionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionS3Url(versionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a label for a given Item definition
         * @param {string} itemDefinitionId 
         * @param {string} text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeLabelDefinition(itemDefinitionId: string, text: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeLabelDefinition(itemDefinitionId, text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search checklist templates
         * @param {Array<'NAME'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search1(sortBy?: Array<'NAME'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseChecklistDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search1(sortBy, sortDirection, pageNumber, pageSize, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the default checklists that should be applied when an office does not have a suitable default
         * @param {ChecklistDefaultsDto} checklistDefaultsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChecklistDefaults(checklistDefaultsDto: ChecklistDefaultsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistDefaultsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChecklistDefaults(checklistDefaultsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing Checklist definition
         * @param {string} checklistDefinitionId 
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChecklistDefinition(checklistDefinitionId: string, checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChecklistDefinition(checklistDefinitionId, checklistDefinitionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the latest Version of a Checklist Item Document
         * @param {string} documentId 
         * @param {DocumentVersionDefinitionDto} documentVersionDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentDefinitionLatestVersion(documentId: string, documentVersionDefinitionDto: DocumentVersionDefinitionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentDefinitionLatestVersion(documentId, documentVersionDefinitionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Item definition by id
         * @param {string} itemId 
         * @param {UpdateItemDefinitionRequest} updateItemDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemDefinition(itemId: string, updateItemDefinitionRequest: UpdateItemDefinitionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemDefinition(itemId, updateItemDefinitionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a new document definition & version to a Checklist Item
         * @param {string} itemDefinitionId 
         * @param {File} file 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(itemDefinitionId: string, file: File, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(itemDefinitionId, file, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a new Version to an existing Checklist Item Document
         * @param {string} documentId 
         * @param {File} file 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocumentVersionDefinition(documentId: string, file: File, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentVersionDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocumentVersionDefinition(documentId, file, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistDefinitionApi - factory interface
 * @export
 */
export const ChecklistDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistDefinitionApiFp(configuration)
    return {
        /**
         * 
         * @summary Change the position of an Item within its Checklist definition
         * @param {string} itemId 
         * @param {number} newPosition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeItemDefinitionPosition(itemId: string, newPosition: number, options?: any): AxiosPromise<void> {
            return localVarFp.changeItemDefinitionPosition(itemId, newPosition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Checklist definition
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklistDefinition(checklistDefinitionDto: ChecklistDefinitionDto, options?: any): AxiosPromise<ChecklistDefinitionDto> {
            return localVarFp.createChecklistDefinition(checklistDefinitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Checklist definition
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklistDefinition1(checklistDefinitionDto: ChecklistDefinitionDto, options?: any): AxiosPromise<ChecklistDefinitionDto> {
            return localVarFp.createChecklistDefinition1(checklistDefinitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new Item definitions for a given Checklist definition
         * @param {string} checklistDefinitionId 
         * @param {Array<ItemDefinitionDto>} itemDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemDefinitions(checklistDefinitionId: string, itemDefinitionDto: Array<ItemDefinitionDto>, options?: any): AxiosPromise<Array<ItemDefinitionDto>> {
            return localVarFp.createItemDefinitions(checklistDefinitionId, itemDefinitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a label for a given Item definition. Case-insensitive
         * @param {string} itemId 
         * @param {string} text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel(itemId: string, text: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.createLabel(itemId, text, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Checklist Definition Item Document and its Version history
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentDefinition(documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocumentDefinition(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Item definition by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemDefinitionById(itemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteItemDefinitionById(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets default checklists that should be applied when an office does not have a suitable default
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} targetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistDefaults(targetType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', options?: any): AxiosPromise<ChecklistDefaultsDto> {
            return localVarFp.getChecklistDefaults(targetType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Checklist definition by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistDefinitionById(id: string, options?: any): AxiosPromise<ChecklistDefinitionDto> {
            return localVarFp.getChecklistDefinitionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Checklist definitions
         * @param {string} [name] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistDefinitions(name?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagedChecklistDefResponse> {
            return localVarFp.getChecklistDefinitions(name, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Checklist Definition Item Document and its Version history
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDefinition(documentId: string, options?: any): AxiosPromise<DocumentDefinitionDto> {
            return localVarFp.getDocumentDefinition(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Document Version
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentVersionDefinition(versionId: string, options?: any): AxiosPromise<DocumentVersionDefinitionDto> {
            return localVarFp.getDocumentVersionDefinition(versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Item definition by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDefinitionById(itemId: string, options?: any): AxiosPromise<ItemDefinitionDto> {
            return localVarFp.getItemDefinitionById(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all labels for a given Item definition
         * @param {string} itemDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelsForItemDefinition(itemDefinitionId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getLabelsForItemDefinition(itemDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the recently used labels of a Checklist definition
         * @param {string} checklistDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyUsedLabels(checklistDefinitionId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getRecentlyUsedLabels(checklistDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Document Version\'s S3 URL
         * @param {string} versionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionS3Url(versionId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getVersionS3Url(versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a label for a given Item definition
         * @param {string} itemDefinitionId 
         * @param {string} text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLabelDefinition(itemDefinitionId: string, text: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.removeLabelDefinition(itemDefinitionId, text, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search checklist templates
         * @param {Array<'NAME'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search1(sortBy?: Array<'NAME'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, name?: string, options?: any): AxiosPromise<GenericSearchResponseChecklistDefinitionDto> {
            return localVarFp.search1(sortBy, sortDirection, pageNumber, pageSize, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the default checklists that should be applied when an office does not have a suitable default
         * @param {ChecklistDefaultsDto} checklistDefaultsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistDefaults(checklistDefaultsDto: ChecklistDefaultsDto, options?: any): AxiosPromise<ChecklistDefaultsDto> {
            return localVarFp.updateChecklistDefaults(checklistDefaultsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing Checklist definition
         * @param {string} checklistDefinitionId 
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistDefinition(checklistDefinitionId: string, checklistDefinitionDto: ChecklistDefinitionDto, options?: any): AxiosPromise<ChecklistDefinitionDto> {
            return localVarFp.updateChecklistDefinition(checklistDefinitionId, checklistDefinitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the latest Version of a Checklist Item Document
         * @param {string} documentId 
         * @param {DocumentVersionDefinitionDto} documentVersionDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentDefinitionLatestVersion(documentId: string, documentVersionDefinitionDto: DocumentVersionDefinitionDto, options?: any): AxiosPromise<DocumentDefinitionDto> {
            return localVarFp.updateDocumentDefinitionLatestVersion(documentId, documentVersionDefinitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Item definition by id
         * @param {string} itemId 
         * @param {UpdateItemDefinitionRequest} updateItemDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemDefinition(itemId: string, updateItemDefinitionRequest: UpdateItemDefinitionRequest, options?: any): AxiosPromise<ItemDefinitionDto> {
            return localVarFp.updateItemDefinition(itemId, updateItemDefinitionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a new document definition & version to a Checklist Item
         * @param {string} itemDefinitionId 
         * @param {File} file 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(itemDefinitionId: string, file: File, description?: string, options?: any): AxiosPromise<DocumentDefinitionDto> {
            return localVarFp.uploadDocument(itemDefinitionId, file, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a new Version to an existing Checklist Item Document
         * @param {string} documentId 
         * @param {File} file 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentVersionDefinition(documentId: string, file: File, description?: string, options?: any): AxiosPromise<DocumentVersionDefinitionDto> {
            return localVarFp.uploadDocumentVersionDefinition(documentId, file, description, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistDefinitionApi - object-oriented interface
 * @export
 * @class ChecklistDefinitionApi
 * @extends {BaseAPI}
 */
export class ChecklistDefinitionApi extends BaseAPI {
    /**
     * 
     * @summary Change the position of an Item within its Checklist definition
     * @param {string} itemId 
     * @param {number} newPosition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public changeItemDefinitionPosition(itemId: string, newPosition: number, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).changeItemDefinitionPosition(itemId, newPosition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new Checklist definition
     * @param {ChecklistDefinitionDto} checklistDefinitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public createChecklistDefinition(checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).createChecklistDefinition(checklistDefinitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new Checklist definition
     * @param {ChecklistDefinitionDto} checklistDefinitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public createChecklistDefinition1(checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).createChecklistDefinition1(checklistDefinitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new Item definitions for a given Checklist definition
     * @param {string} checklistDefinitionId 
     * @param {Array<ItemDefinitionDto>} itemDefinitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public createItemDefinitions(checklistDefinitionId: string, itemDefinitionDto: Array<ItemDefinitionDto>, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).createItemDefinitions(checklistDefinitionId, itemDefinitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a label for a given Item definition. Case-insensitive
     * @param {string} itemId 
     * @param {string} text 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public createLabel(itemId: string, text: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).createLabel(itemId, text, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Checklist Definition Item Document and its Version history
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public deleteDocumentDefinition(documentId: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).deleteDocumentDefinition(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Item definition by id
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public deleteItemDefinitionById(itemId: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).deleteItemDefinitionById(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets default checklists that should be applied when an office does not have a suitable default
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE'} targetType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public getChecklistDefaults(targetType: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE', options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).getChecklistDefaults(targetType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Checklist definition by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public getChecklistDefinitionById(id: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).getChecklistDefinitionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Checklist definitions
     * @param {string} [name] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public getChecklistDefinitions(name?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).getChecklistDefinitions(name, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Checklist Definition Item Document and its Version history
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public getDocumentDefinition(documentId: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).getDocumentDefinition(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Document Version
     * @param {string} versionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public getDocumentVersionDefinition(versionId: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).getDocumentVersionDefinition(versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Item definition by id
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public getItemDefinitionById(itemId: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).getItemDefinitionById(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all labels for a given Item definition
     * @param {string} itemDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public getLabelsForItemDefinition(itemDefinitionId: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).getLabelsForItemDefinition(itemDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the recently used labels of a Checklist definition
     * @param {string} checklistDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public getRecentlyUsedLabels(checklistDefinitionId: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).getRecentlyUsedLabels(checklistDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Document Version\'s S3 URL
     * @param {string} versionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public getVersionS3Url(versionId: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).getVersionS3Url(versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a label for a given Item definition
     * @param {string} itemDefinitionId 
     * @param {string} text 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public removeLabelDefinition(itemDefinitionId: string, text: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).removeLabelDefinition(itemDefinitionId, text, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search checklist templates
     * @param {Array<'NAME'>} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public search1(sortBy?: Array<'NAME'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, name?: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).search1(sortBy, sortDirection, pageNumber, pageSize, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the default checklists that should be applied when an office does not have a suitable default
     * @param {ChecklistDefaultsDto} checklistDefaultsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public updateChecklistDefaults(checklistDefaultsDto: ChecklistDefaultsDto, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).updateChecklistDefaults(checklistDefaultsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing Checklist definition
     * @param {string} checklistDefinitionId 
     * @param {ChecklistDefinitionDto} checklistDefinitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public updateChecklistDefinition(checklistDefinitionId: string, checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).updateChecklistDefinition(checklistDefinitionId, checklistDefinitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the latest Version of a Checklist Item Document
     * @param {string} documentId 
     * @param {DocumentVersionDefinitionDto} documentVersionDefinitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public updateDocumentDefinitionLatestVersion(documentId: string, documentVersionDefinitionDto: DocumentVersionDefinitionDto, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).updateDocumentDefinitionLatestVersion(documentId, documentVersionDefinitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Item definition by id
     * @param {string} itemId 
     * @param {UpdateItemDefinitionRequest} updateItemDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public updateItemDefinition(itemId: string, updateItemDefinitionRequest: UpdateItemDefinitionRequest, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).updateItemDefinition(itemId, updateItemDefinitionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a new document definition & version to a Checklist Item
     * @param {string} itemDefinitionId 
     * @param {File} file 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public uploadDocument(itemDefinitionId: string, file: File, description?: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).uploadDocument(itemDefinitionId, file, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a new Version to an existing Checklist Item Document
     * @param {string} documentId 
     * @param {File} file 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefinitionApi
     */
    public uploadDocumentVersionDefinition(documentId: string, file: File, description?: string, options?: AxiosRequestConfig) {
        return ChecklistDefinitionApiFp(this.configuration).uploadDocumentVersionDefinition(documentId, file, description, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemApi - axios parameter creator
 * @export
 */
export const ItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign file references to an item
         * @param {string} itemId 
         * @param {AddFileReferencesRequest} addFileReferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFileReferences: async (itemId: string, addFileReferencesRequest: AddFileReferencesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('addFileReferences', 'itemId', itemId)
            // verify required parameter 'addFileReferencesRequest' is not null or undefined
            assertParamExists('addFileReferences', 'addFileReferencesRequest', addFileReferencesRequest)
            const localVarPath = `/api/v1/checklists/checklist-items/{itemId}/file-references`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFileReferencesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove file references from an item
         * @param {string} itemId 
         * @param {RemoveFileReferencesRequest} removeFileReferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFileReferences: async (itemId: string, removeFileReferencesRequest: RemoveFileReferencesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('removeFileReferences', 'itemId', itemId)
            // verify required parameter 'removeFileReferencesRequest' is not null or undefined
            assertParamExists('removeFileReferences', 'removeFileReferencesRequest', removeFileReferencesRequest)
            const localVarPath = `/api/v1/checklists/checklist-items/{itemId}/file-references`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeFileReferencesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an item with the provided properties
         * @param {string} itemId 
         * @param {PatchChecklistItemRequest} patchChecklistItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatus: async (itemId: string, patchChecklistItemRequest: PatchChecklistItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('updateStatus', 'itemId', itemId)
            // verify required parameter 'patchChecklistItemRequest' is not null or undefined
            assertParamExists('updateStatus', 'patchChecklistItemRequest', patchChecklistItemRequest)
            const localVarPath = `/api/v1/checklists/checklist-items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchChecklistItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemApi - functional programming interface
 * @export
 */
export const ItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign file references to an item
         * @param {string} itemId 
         * @param {AddFileReferencesRequest} addFileReferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFileReferences(itemId: string, addFileReferencesRequest: AddFileReferencesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFileReferences(itemId, addFileReferencesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove file references from an item
         * @param {string} itemId 
         * @param {RemoveFileReferencesRequest} removeFileReferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFileReferences(itemId: string, removeFileReferencesRequest: RemoveFileReferencesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFileReferences(itemId, removeFileReferencesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an item with the provided properties
         * @param {string} itemId 
         * @param {PatchChecklistItemRequest} patchChecklistItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatus(itemId: string, patchChecklistItemRequest: PatchChecklistItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatus(itemId, patchChecklistItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemApi - factory interface
 * @export
 */
export const ItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign file references to an item
         * @param {string} itemId 
         * @param {AddFileReferencesRequest} addFileReferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFileReferences(itemId: string, addFileReferencesRequest: AddFileReferencesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addFileReferences(itemId, addFileReferencesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove file references from an item
         * @param {string} itemId 
         * @param {RemoveFileReferencesRequest} removeFileReferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFileReferences(itemId: string, removeFileReferencesRequest: RemoveFileReferencesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.removeFileReferences(itemId, removeFileReferencesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an item with the provided properties
         * @param {string} itemId 
         * @param {PatchChecklistItemRequest} patchChecklistItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatus(itemId: string, patchChecklistItemRequest: PatchChecklistItemRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateStatus(itemId, patchChecklistItemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemApi - object-oriented interface
 * @export
 * @class ItemApi
 * @extends {BaseAPI}
 */
export class ItemApi extends BaseAPI {
    /**
     * 
     * @summary Assign file references to an item
     * @param {string} itemId 
     * @param {AddFileReferencesRequest} addFileReferencesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public addFileReferences(itemId: string, addFileReferencesRequest: AddFileReferencesRequest, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).addFileReferences(itemId, addFileReferencesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove file references from an item
     * @param {string} itemId 
     * @param {RemoveFileReferencesRequest} removeFileReferencesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public removeFileReferences(itemId: string, removeFileReferencesRequest: RemoveFileReferencesRequest, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).removeFileReferences(itemId, removeFileReferencesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an item with the provided properties
     * @param {string} itemId 
     * @param {PatchChecklistItemRequest} patchChecklistItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public updateStatus(itemId: string, patchChecklistItemRequest: PatchChecklistItemRequest, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).updateStatus(itemId, patchChecklistItemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemDefinitionApi - axios parameter creator
 * @export
 */
export const ItemDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a Trigger Definition
         * @param {string} itemDefId 
         * @param {CreateTriggerDefRequest} createTriggerDefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTriggersToItemDefinition: async (itemDefId: string, createTriggerDefRequest: CreateTriggerDefRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDefId' is not null or undefined
            assertParamExists('addTriggersToItemDefinition', 'itemDefId', itemDefId)
            // verify required parameter 'createTriggerDefRequest' is not null or undefined
            assertParamExists('addTriggersToItemDefinition', 'createTriggerDefRequest', createTriggerDefRequest)
            const localVarPath = `/api/v1/item-definitions/{itemDefId}/trigger-definitions`
                .replace(`{${"itemDefId"}}`, encodeURIComponent(String(itemDefId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTriggerDefRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove specified Trigger Definition from Item Definition
         * @param {string} itemDefId 
         * @param {string} triggerDefId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTriggersFromItemDefinition: async (itemDefId: string, triggerDefId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDefId' is not null or undefined
            assertParamExists('removeTriggersFromItemDefinition', 'itemDefId', itemDefId)
            // verify required parameter 'triggerDefId' is not null or undefined
            assertParamExists('removeTriggersFromItemDefinition', 'triggerDefId', triggerDefId)
            const localVarPath = `/api/v1/item-definitions/{itemDefId}/trigger-definitions/{triggerDefId}`
                .replace(`{${"itemDefId"}}`, encodeURIComponent(String(itemDefId)))
                .replace(`{${"triggerDefId"}}`, encodeURIComponent(String(triggerDefId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemDefinitionApi - functional programming interface
 * @export
 */
export const ItemDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a Trigger Definition
         * @param {string} itemDefId 
         * @param {CreateTriggerDefRequest} createTriggerDefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTriggersToItemDefinition(itemDefId: string, createTriggerDefRequest: CreateTriggerDefRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTriggersToItemDefinition(itemDefId, createTriggerDefRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove specified Trigger Definition from Item Definition
         * @param {string} itemDefId 
         * @param {string} triggerDefId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTriggersFromItemDefinition(itemDefId: string, triggerDefId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTriggersFromItemDefinition(itemDefId, triggerDefId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemDefinitionApi - factory interface
 * @export
 */
export const ItemDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemDefinitionApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a Trigger Definition
         * @param {string} itemDefId 
         * @param {CreateTriggerDefRequest} createTriggerDefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTriggersToItemDefinition(itemDefId: string, createTriggerDefRequest: CreateTriggerDefRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addTriggersToItemDefinition(itemDefId, createTriggerDefRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove specified Trigger Definition from Item Definition
         * @param {string} itemDefId 
         * @param {string} triggerDefId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTriggersFromItemDefinition(itemDefId: string, triggerDefId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeTriggersFromItemDefinition(itemDefId, triggerDefId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemDefinitionApi - object-oriented interface
 * @export
 * @class ItemDefinitionApi
 * @extends {BaseAPI}
 */
export class ItemDefinitionApi extends BaseAPI {
    /**
     * 
     * @summary Add a Trigger Definition
     * @param {string} itemDefId 
     * @param {CreateTriggerDefRequest} createTriggerDefRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemDefinitionApi
     */
    public addTriggersToItemDefinition(itemDefId: string, createTriggerDefRequest: CreateTriggerDefRequest, options?: AxiosRequestConfig) {
        return ItemDefinitionApiFp(this.configuration).addTriggersToItemDefinition(itemDefId, createTriggerDefRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove specified Trigger Definition from Item Definition
     * @param {string} itemDefId 
     * @param {string} triggerDefId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemDefinitionApi
     */
    public removeTriggersFromItemDefinition(itemDefId: string, triggerDefId: string, options?: AxiosRequestConfig) {
        return ItemDefinitionApiFp(this.configuration).removeTriggersFromItemDefinition(itemDefId, triggerDefId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createAtFrom?: number, createAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createAtFrom !== undefined) {
                localVarQueryParameter['createAtFrom'] = createAtFrom;
            }

            if (createAtTo !== undefined) {
                localVarQueryParameter['createAtTo'] = createAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (topic?: string, key?: string, createAtFrom?: number, createAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (createAtFrom !== undefined) {
                localVarQueryParameter['createAtFrom'] = createAtFrom;
            }

            if (createAtTo !== undefined) {
                localVarQueryParameter['createAtTo'] = createAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createAtFrom?: number, createAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createAtFrom, createAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(topic?: string, key?: string, createAtFrom?: number, createAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(topic, key, createAtFrom, createAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createAtFrom?: number, createAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createAtFrom, createAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {number} [createAtFrom] 
         * @param {number} [createAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(topic?: string, key?: string, createAtFrom?: number, createAtTo?: number, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(topic, key, createAtFrom, createAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createAtFrom] 
     * @param {number} [createAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createAtFrom?: number, createAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createAtFrom, createAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {number} [createAtFrom] 
     * @param {number} [createAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(topic?: string, key?: string, createAtFrom?: number, createAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(topic, key, createAtFrom, createAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScriptConditionApi - axios parameter creator
 * @export
 */
export const ScriptConditionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Preview custom condition Js script
         * @param {ScriptConditionPreviewRequest} scriptConditionPreviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preview: async (scriptConditionPreviewRequest: ScriptConditionPreviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scriptConditionPreviewRequest' is not null or undefined
            assertParamExists('preview', 'scriptConditionPreviewRequest', scriptConditionPreviewRequest)
            const localVarPath = `/api/v1/script/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scriptConditionPreviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScriptConditionApi - functional programming interface
 * @export
 */
export const ScriptConditionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScriptConditionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Preview custom condition Js script
         * @param {ScriptConditionPreviewRequest} scriptConditionPreviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preview(scriptConditionPreviewRequest: ScriptConditionPreviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScriptConditionEvaluationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preview(scriptConditionPreviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScriptConditionApi - factory interface
 * @export
 */
export const ScriptConditionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScriptConditionApiFp(configuration)
    return {
        /**
         * 
         * @summary Preview custom condition Js script
         * @param {ScriptConditionPreviewRequest} scriptConditionPreviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preview(scriptConditionPreviewRequest: ScriptConditionPreviewRequest, options?: any): AxiosPromise<ScriptConditionEvaluationResponse> {
            return localVarFp.preview(scriptConditionPreviewRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScriptConditionApi - object-oriented interface
 * @export
 * @class ScriptConditionApi
 * @extends {BaseAPI}
 */
export class ScriptConditionApi extends BaseAPI {
    /**
     * 
     * @summary Preview custom condition Js script
     * @param {ScriptConditionPreviewRequest} scriptConditionPreviewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptConditionApi
     */
    public preview(scriptConditionPreviewRequest: ScriptConditionPreviewRequest, options?: AxiosRequestConfig) {
        return ScriptConditionApiFp(this.configuration).preview(scriptConditionPreviewRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search API
         * @param {SearchRequest} searchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (searchRequest: SearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            assertParamExists('search', 'searchRequest', searchRequest)
            const localVarPath = `/api/v1/search/universal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {SearchRequest} searchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(searchRequest: SearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(searchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {SearchRequest} searchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(searchRequest: SearchRequest, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.search(searchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @summary Search API
     * @param {SearchRequest} searchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public search(searchRequest: SearchRequest, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).search(searchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


