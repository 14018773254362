import classNames from 'classnames';
import React, { useState } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { ISelectOption } from '../../types';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

interface ControlledSingleSelectDropDownInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  options: ISelectOption[];
  placeholder?: string;
  autoComplete?: string | undefined;
  label?: string;
}

const ControlledSingleSelectDropDownInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  options,
  placeholder = 'Select',
  autoComplete,
  shouldUnregister = true,
  label,
  ...controllerProps
}: ControlledSingleSelectDropDownInputProps<TFieldValues, TName>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const {
    field: { value, onChange, name, onBlur },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    onBlur();
    setIsFocused(false);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
    setIsFocused(false);
  };

  const isShowLabel = !!label && (isFocused || !!value);

  return (
    <div>
      {isShowLabel && <ControlledInputLabelComponent label={label} />}
      <select
        name={name}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleSelectChange}
        className={classNames([
          'w-full bg-transparent rounded-lg font-inter-regular text-base px-2 py-3 leading-4 text-black appearance-none focus:outline-none focus:ring-0',
          isFocused
            ? 'border-2 border-primary-skyblue bg-primary-skyblue bg-opacity-10'
            : 'bg-transparent border border-silver',
          isFocused || value ? 'text-black' : 'text-gray-400',
        ])}
        autoComplete={autoComplete}
        data-testid='select'
      >
        {placeholder && <option value=''>{placeholder}</option>}
        {options.map((option) => (
          <option
            key={option.value}
            className='text-black'
            value={option.value}
            data-testid='options-list'
          >
            {option.label}
          </option>
        ))}
      </select>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledSingleSelectDropDownInput;
