import {
  faChevronCircleDown,
  faChevronCircleUp,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import RealLogo from '../../assets/img/logo-white.svg';
import {
  ApplicationProgressResponse,
  QuestionProgressDto,
  QuestionProgressDtoStatusEnum,
  SectionProgressDtoStatusEnum,
} from '../../openapi/atlantis';
import Step from './Step';
import VerticalStepQuestions from './VerticalStepQuestions';

interface VerticalStepProgressProps {
  title: string;
  progress: ApplicationProgressResponse;
  onNavigateToQuestion: (questionId: string) => void;
}

const VerticalStepProgress: React.FC<VerticalStepProgressProps> = ({
  title,
  progress,
  onNavigateToQuestion,
}) => {
  const [expandedStepNames, setExpandedStepNames] = useState<string[]>([]);
  const steps = progress?.sectionProgresses || [];

  const getStepProgressPercentage = (
    questionProgress: QuestionProgressDto[],
  ) => {
    const totalCompletedQuestions =
      questionProgress?.filter(
        (q) => q.status === QuestionProgressDtoStatusEnum.Completed,
      )?.length ?? 0;

    const totalQuestionsCount = questionProgress?.length ?? 0;

    return (totalCompletedQuestions / totalQuestionsCount || 0) * 100;
  };

  const toggleStepExpansion = (stepName: string) => {
    const isExpanded = expandedStepNames.includes(stepName);

    const stepsNames = isExpanded
      ? expandedStepNames.filter((name) => name !== stepName)
      : [...expandedStepNames, stepName];

    setExpandedStepNames(stepsNames);
  };

  return (
    <div className='bg-primary-navy h-screen overflow-y-scroll'>
      <img
        src={RealLogo}
        alt='placeholder'
        className='absolute lg:top-10 top-5 lg:left-10 left-5'
        width={100}
        height={35}
      />
      <p className='font-poppins-medium text-white lg:text-4xl text-2xl flex flex-row justify-center pt-28 pb-5'>
        {title}
      </p>
      <div className='max-w-lg mx-auto p-4'>
        {steps.map((step, index) => {
          const isFinal = index === steps.length - 1;

          const isCompleted =
            step.status === SectionProgressDtoStatusEnum.Completed;

          const isInProgress =
            step.status === SectionProgressDtoStatusEnum.InProgress;

          const isExpanded = expandedStepNames.includes(step.name);

          const isVisibleStepQuestions = isExpanded || isInProgress;

          const inProgressPercentage = getStepProgressPercentage(
            step.questionProgresses!,
          );

          return (
            <div key={step.name} className='flex flex-row'>
              <div className='flex flex-col items-center'>
                <Step
                  inProgressPercentage={inProgressPercentage}
                  isInProgress={isInProgress}
                  isCompleted={isCompleted}
                  isFinal={isFinal}
                  isVertical
                />
              </div>
              <div className='pl-5 pt-1 pb-5 w-full'>
                <button
                  className='flex flex-row justify-between items-center cursor-pointer w-full'
                  onClick={() => {
                    if (isCompleted) {
                      toggleStepExpansion(step.name);
                    }
                  }}
                >
                  <p
                    className={classNames([
                      'font-poppins-medium text-xl',
                      isCompleted ? 'text-aqua' : 'text-white',
                    ])}
                  >
                    {step.name}
                  </p>
                  {isCompleted && (
                    <FontAwesomeIcon
                      icon={
                        isExpanded ? faChevronCircleUp : faChevronCircleDown
                      }
                      fontSize={24}
                      className='text-aqua'
                    />
                  )}
                </button>
                {isVisibleStepQuestions && (
                  <VerticalStepQuestions
                    step={step}
                    onNavigateToQuestion={onNavigateToQuestion}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalStepProgress;
