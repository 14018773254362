import React from 'react';
import {
  BorrowerDtoStatusEnum,
  LoanDtoStatusEnum,
} from '../../openapi/atlantis';
import RenderThemeComponent from '../RenderThemeComponent';
import LoanStatusGemini from './Gemini/LoanStatusGemini';
import LoanStatusReskin from './Reskin/LoanStatusReskin';

export interface LoanStatusProps {
  status: LoanDtoStatusEnum | BorrowerDtoStatusEnum;
}

const LoanStatus: React.FC<LoanStatusProps> = (props) => {
  return (
    <RenderThemeComponent<LoanStatusProps>
      GeminiComponent={LoanStatusGemini}
      ReskinComponent={LoanStatusReskin}
      {...props}
    />
  );
};

export default LoanStatus;
