import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../types';

interface GuestRouteProps {
  children: React.ReactNode;
}

const GuestRoute: React.FC<GuestRouteProps> = ({ children }) => {
  const { authUserDetail } = useSelector((state: RootState) => state.auth);

  if (authUserDetail) {
    return <Navigate to='/' replace />;
  }

  return children;
};

export default GuestRoute;
