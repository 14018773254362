import React from 'react';
import HoldingBoxes from '../assets/img/holding-boxes.png';
import RealLogo from '../assets/img/logo.svg';

interface AuthPageImageProps {}

const AuthPageImage: React.FC<AuthPageImageProps> = () => {
  return (
    <div className='order-1 md:order-2 md:col-span-6 md:h-screen ml-4 md:ml-0 mt-4 md:mt-0'>
      <div className='md:row-span-1 md:my-auto md:hidden block mb-4'>
        <img src={RealLogo} alt='placeholder' width={70} height={35} />
      </div>
      <img
        src={HoldingBoxes}
        alt='placeholder'
        className='md:h-[89vh] object-cover w-full'
      />
      <div className='bg-primary-blue md:h-10 h-6 md:w-[30%] w-[70%] justify-end items-end flex  ml-auto mb-5 md:mb-0' />
    </div>
  );
};

export default AuthPageImage;
