import classNames from 'classnames';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { ISelectOption } from '../../types';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

export interface ControlledToggleInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  trueOption: ISelectOption;
  falseOption: ISelectOption;
}

const ControlledToggleInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  defaultValue,
  trueOption,
  falseOption,
  shouldUnregister = true,
  ...controllerProps
}: ControlledToggleInputProps<TFieldValues, TName>) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  useEffect(() => {
    if (isNil(value)) {
      onChange('false');
    }
  }, [onChange, value]);

  const isTrueOptionSelected =
    value?.toString() === trueOption.value?.toString();

  return (
    <div>
      <div>
        {!!label && <ControlledInputLabelComponent label={label} />}
        <button
          className={classNames([
            'flex',
            isTrueOptionSelected ? 'flex-row' : 'flex-row-reverse',
            'justify-around items-center rounded-full bg-transparent w-16 h-8 px-0.5',
            isTrueOptionSelected ? 'border-2' : 'border',
            isTrueOptionSelected
              ? 'border-primary-skyblue'
              : 'border-steelblue',
          ])}
          onClick={() => {
            if (isTrueOptionSelected) {
              onChange(falseOption.value.toString());
            } else {
              onChange(trueOption.value.toString());
            }
          }}
          data-testid='toggle-button'
        >
          <div className='flex-grow items-center'>
            <p
              className={classNames([
                'font-inter-medium',
                'text-sm',
                'text-black',
              ])}
              data-testid='toggle-text'
            >
              {isTrueOptionSelected ? trueOption.label : falseOption.label}
            </p>
          </div>
          <div
            className={classNames([
              'w-6 h-6 rounded-full',
              isTrueOptionSelected ? 'bg-primary-skyblue' : 'bg-steelblue',
            ])}
          />
        </button>
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledToggleInput;
