import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from '../components/ToastNotification';
import { resetToastNotification } from '../slices/ToastNotificationSlice';
import { RootState } from '../types';

export interface ToastNotificationContainerProps {}

const ToastNotificationContainer: React.FC<
  ToastNotificationContainerProps
> = () => {
  const dispatch = useDispatch();
  const { visible, toastData } = useSelector(
    (state: RootState) => state.toastNotification,
  );

  const onClose = () => dispatch(resetToastNotification());

  if (!visible || !toastData) {
    return null;
  }

  return (
    <div className='fixed right-0 bottom-0 m-4 animate-slide-in-right z-50'>
      <ToastNotification
        type={toastData.type}
        title={toastData.title}
        subtitle={toastData.subtitle}
        onClose={onClose}
      />
    </div>
  );
};

export default ToastNotificationContainer;
