import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import { merge } from 'lodash';
import { useState } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

interface ControlledPhoneNumberInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  label?: string;
}

const ControlledPhoneNumberInput = <
  TFieldValues extends FieldValues = FieldValues & string,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  placeholder,
  readonly = false,
  disabled = false,
  shouldUnregister = true,
  label,
  ...controllerProps
}: ControlledPhoneNumberInputProps<TFieldValues, TName>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const {
    field: { onChange, value, name, onBlur },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
    rules: merge(controllerProps.rules || {}, {
      validate: async (phone: string) => {
        if (phone) {
          try {
            const isValidPhoneNumber = await parsePhoneNumber(
              `+${phone.replace('+', '')}`,
            )?.isValid();

            return !isValidPhoneNumber
              ? 'Please enter a valid phone number'
              : undefined;
          } catch (e) {
            return 'Please enter a valid phone number';
          }
        }

        return undefined;
      },
    }),
  });

  const isShowLabel = !!label && (isFocused || !!value);

  const placeholderText = isFocused ? '' : placeholder;

  const variantBorderStylesMap = classNames([
    'flex-row flex-shrink items-stretch justify-between overflow-hidden border rounded-lg',
    isFocused ? 'border-black' : 'border-silver',
  ]);

  const variantViewStylesMap = classNames([
    'flex flex-row justify-between items-center',
    readonly || disabled ? 'bg-whitesmoke' : 'bg-transparent',
  ]);

  const textInputStylesMap = classNames([
    'appearance-none focus:outline-none focus:ring-0 items-center justify-center flex-grow flex-shrink font-inter-regular text-base px-2 py-3 leading-4 text-black',
  ]);

  return (
    <div>
      {isShowLabel && <ControlledInputLabelComponent label={label} />}
      <div className={variantBorderStylesMap}>
        <div data-testid={'parent-' + name} className={variantViewStylesMap}>
          <div className='pl-2'>
            <FontAwesomeIcon
              icon={faPhone}
              fontSize={18}
              className='text-primary-skyblue'
            />
          </div>
          <InputMask
            disabled={disabled || readonly}
            id={name}
            value={value}
            onChange={onChange}
            name={name}
            mask='+1 999 999-9999'
            maskChar=''
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              onBlur();
              setIsFocused(false);
            }}
            placeholder={placeholderText}
            className={textInputStylesMap}
          />
        </div>
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledPhoneNumberInput;
