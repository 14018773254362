import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FormErrorMessage from './FieldErrorMessage';

interface ControlledDatePickerInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  placeholder?: string;
  readOnly?: boolean;
  customInput?: React.ReactElement;
  datePickerConfig?: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
  >;
  icon?: React.ReactElement;
  label?: string;
}

const ControlledDatePickerInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  placeholder,
  readOnly,
  customInput,
  datePickerConfig = {},
  icon,
  shouldUnregister = true,
  label,
  ...controllerProps
}: ControlledDatePickerInputProps<TFieldValues, TName>) => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    field: { onChange, value, name, onBlur },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);

  const selectedDate =
    value && DateTime.fromISO(value).isValid
      ? DateTime.fromISO(value).toJSDate()
      : null;

  const isValidDate = value && DateTime.fromISO(value).isValid;

  const isShowLabel = !!label && (isShowDatePicker || isValidDate);

  return (
    <div className='w-full space-y-1'>
      {isShowLabel && <ControlledInputLabelComponent label={label} />}
      <div
        className={classNames([
          'w-full flex flex-row items-center justify-start overflow-hidden border rounded-lg',
          isFocused ? 'border-black' : 'border-silver',
        ])}
      >
        {icon && (
          <div
            className={classNames([
              readOnly
                ? 'border-gray-3'
                : isFocused
                ? 'border-black'
                : 'border-primary-gray',
              'pl-2',
            ])}
            data-testid='date-picker-icon'
          >
            {icon}
          </div>
        )}
        <DatePicker
          name={name}
          selected={selectedDate}
          onChange={(date: Date) => {
            if (datePickerConfig?.showTimeSelect) {
              onChange(DateTime.fromJSDate(date).toISO());
            } else {
              onChange(DateTime.fromJSDate(date).toISODate());
            }
          }}
          onFocus={() => {
            setIsFocused(true);
            setIsShowDatePicker(true);
          }}
          onBlur={() => {
            onBlur();
            setIsFocused(false);
            setIsShowDatePicker(false);
          }}
          placeholderText={placeholder}
          autoComplete='off'
          readOnly={readOnly}
          dateFormatCalendar='MMMM'
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          id={name}
          disabledKeyboardNavigation
          customInput={customInput}
          className={classNames([
            !customInput &&
              'appearance-none px-2 py-3 focus:ring-0 focus:outline-none bg-white w-full font-inter-regular text-base leading-4',
            {
              'bg-gray-50': readOnly,
            },

            readOnly
              ? 'border-gray-3'
              : isFocused
              ? 'border-black'
              : 'border-primary-gray',
          ])}
          minDate={DateTime.fromISO('1800-01-01').toJSDate()}
          maxDate={DateTime.fromISO('2200-01-01').toJSDate()}
          {...datePickerConfig}
        />
      </div>
      <FormErrorMessage message={error?.message!} />
    </div>
  );
};

export default ControlledDatePickerInput;
