import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfigDrivenContainer from '../components/ConfigDriven/ConfigDrivenContainer';
import DefaultSplash from '../components/DefaultSplash.tsx';
import {
  AcknowledgeSectionStepRequest,
  AnswerApplicationQuestionRequest,
  ApplicationApi,
  ApplicationStep,
} from '../openapi/atlantis';
import ErrorService from '../services/ErrorService.ts';
import {
  fetchApplicationProgress,
  saveCurrentQuestion,
} from '../slices/ApplicationSlice';
import { fetchLoanByBorrowerId, toggleChat } from '../slices/LoanSlice.ts';
import { useAppDispatch } from '../slices/store';
import Logger from '../utils/Logger';
import { getAtlantisConfiguration } from '../utils/OpenapiConfigurationUtils';

interface PreApprovalFormRouteProps {}

const PreApprovalFormRoute: React.FC<PreApprovalFormRouteProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { borrowerId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchBorrowerLoanDetail = useCallback(async () => {
    setIsLoading(true);

    const isBorrowerLoanFound = await dispatch(
      fetchLoanByBorrowerId(borrowerId!),
    );

    if (!isBorrowerLoanFound) {
      navigate('/');
    }

    setIsLoading(false);
  }, [borrowerId, dispatch, navigate]);

  useEffect(() => {
    fetchBorrowerLoanDetail();

    return () => {
      dispatch(toggleChat(false));
    };
  }, [dispatch, fetchBorrowerLoanDetail]);

  const onStart = async (stepId: string) => {
    const request: AcknowledgeSectionStepRequest = {
      stepId: stepId,
    };
    try {
      await new ApplicationApi(
        await getAtlantisConfiguration(),
      ).acknowledgeSection(borrowerId!, request);
    } catch (e: any) {
      Logger.error('Failed to acknowledge section:', e);
      ErrorService.notify('Failed to acknowledge section', e);
    }
  };

  const onNext = async (): Promise<ApplicationStep> => {
    try {
      const { data } = await new ApplicationApi(
        await getAtlantisConfiguration(),
      ).getNextApplicationStep(borrowerId!);

      await dispatch(saveCurrentQuestion(data));

      await dispatch(fetchApplicationProgress(borrowerId!));

      return data;
    } catch (e: any) {
      Logger.error('Failed to fetch next question:', e);
      ErrorService.notify('Failed to fetch next question', e);
      throw e;
    }
  };

  const onNavigateToQuestion = async (
    questionId: string,
  ): Promise<ApplicationStep> => {
    try {
      const { data } = await new ApplicationApi(
        await getAtlantisConfiguration(),
      ).getAnsweredApplicationStep(borrowerId!, questionId);

      await dispatch(saveCurrentQuestion(data));

      await dispatch(fetchApplicationProgress(borrowerId!));

      return data;
    } catch (e: any) {
      Logger.error('Failed to fetch question by id:', e);
      ErrorService.notify('Failed to fetch question by id', e);
      throw e;
    }
  };

  const onSubmit = async (formData: AnswerApplicationQuestionRequest) => {
    try {
      await new ApplicationApi(await getAtlantisConfiguration()).answerQuestion(
        borrowerId!,
        formData,
      );
    } catch (e: any) {
      Logger.error('Failed to submit question answer:', e);
      ErrorService.notify('Failed to submit question answer', e);
    }
  };

  if (isLoading) {
    return (
      <div className='h-screen w-screen'>
        <DefaultSplash />
      </div>
    );
  }

  return (
    <ConfigDrivenContainer
      onStart={onStart}
      onNext={onNext}
      onSubmit={onSubmit}
      onNavigateToQuestion={onNavigateToQuestion}
    />
  );
};

export default PreApprovalFormRoute;
