import React from 'react';
import {
  AnswerApplicationQuestionRequest,
  FieldObject,
  FieldObjectTypeEnum,
} from '../../../openapi/atlantis';
import ConfigDrivenConditionalFormFieldArrayInput from './ConfigDrivenConditionalFormFieldArrayInput';
import ConfigDrivenConditionalFormInput from './ConfigDrivenConditionalFormInput';

interface ConfigDrivenFormInputsProps {
  field: FieldObject;
  onSubmit: (e: AnswerApplicationQuestionRequest) => void;
  isSubmitting: boolean;
  checklistItemId: string;
}

const ConfigDrivenFormInputs: React.FC<ConfigDrivenFormInputsProps> = ({
  field,
  onSubmit,
  isSubmitting,
  checklistItemId,
}) => {
  const isFieldArray = field.type === FieldObjectTypeEnum.FieldArray;

  if (isFieldArray) {
    return (
      <ConfigDrivenConditionalFormFieldArrayInput
        field={field}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        checklistItemId={checklistItemId}
      />
    );
  }

  return (
    <ConfigDrivenConditionalFormInput
      name={field.id}
      field={field}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      checklistItemId={checklistItemId}
    />
  );
};

export default ConfigDrivenFormInputs;
