import { EventSourcePolyfill } from 'event-source-polyfill';
import { useEffect, useRef, useState } from 'react';
import { RezenObjectTypeEnum } from '../openapi/dropbox';
import {
  CommentApi,
  CommentDto,
  PostCommentBodyReaderRoleEnum,
  PostCommentParamsAuthorTypeEnum,
  QueryCommentsRs,
} from '../openapi/yada';
import ErrorService from '../services/ErrorService.ts';
import { getSSEUrl } from '../utils/ApiUtils.tsx';
import { getAuthCookie } from '../utils/AuthUtils.ts';
import Logger from '../utils/Logger.ts';
import { getYadaConfiguration } from '../utils/OpenapiConfigurationUtils';

const DEFAULT_COMMENTS_LIMIT = 10;

interface useCommentsProps {
  id: string;
}

const useComments = ({ id }: useCommentsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<CommentDto[]>([]);
  const [nextPage, setNextPage] = useState<string>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);

  const fetchComments = async (): Promise<QueryCommentsRs> => {
    const { data } = await new CommentApi(
      await getYadaConfiguration(),
    ).getCommentsByContainer(
      RezenObjectTypeEnum.Borrower,
      id,
      DEFAULT_COMMENTS_LIMIT,
      nextPage,
    );

    return data;
  };

  const handleFetch = async () => {
    if (!hasMore || isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const queryCommentRes = await fetchComments();
      setComments([
        ...(queryCommentRes.comments?.reverse() || []),
        ...comments,
      ]);
      setNextPage(queryCommentRes.nextPage!);
      setHasMore(!!queryCommentRes.nextPage);
    } catch (e: any) {
      Logger.error('Unable to fetch comments', e);
      ErrorService.notify('Unable to fetch comments', e);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  const onSubmit = async (message: string) => {
    await new CommentApi(await getYadaConfiguration()).postComment(
      RezenObjectTypeEnum.Borrower,
      id,
      PostCommentParamsAuthorTypeEnum.User,
      {
        richContent: { blocks: [{ type: 'TEXT', text: message }] },
        readerRole: PostCommentBodyReaderRoleEnum.Public,
      },
    );
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    const eventSource = new EventSourcePolyfill(getSSEUrl(id), {
      headers: { Authorization: `Bearer ${getAuthCookie()}` },
      heartbeatTimeout: 300000, // 5 min - keep alive
    });

    eventSource.onmessage = (event) => {
      if (event.data.length) {
        const newComment = JSON.parse(event.data);

        setComments((prevComments) => {
          // Check if the comment already exists in the array
          const existingCommentIndex = prevComments.findIndex(
            (comment) => comment.id === newComment.id,
          );

          // If the comment exists, update it
          if (existingCommentIndex !== -1) {
            const updatedComments = [...prevComments];
            updatedComments[existingCommentIndex] = newComment;
            return updatedComments;
          }

          // If the comment does not exist, add it to the array
          return [...prevComments, newComment];
        });

        setTimeout(() => {
          scrollToBottom();
        }, 0);
      }
    };

    eventSource.onerror = () => {
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [id]);

  return {
    comments,
    isLoading,
    onSubmit,
    handleFetchNext: handleFetch,
    hasMore,
    ref,
  };
};

export default useComments;
