import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../types';

interface ProtectedRouteProps {
  children: React.ReactNode;
  condition?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  condition = true,
}) => {
  const {
    auth: { authUserDetail },
  } = useSelector((state: RootState) => state);

  if (!authUserDetail) {
    return <Navigate to='/' replace />;
  }

  if (!condition) {
    return <Navigate to='/' replace />;
  }

  return children;
};

export default ProtectedRoute;
