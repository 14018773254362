import classNames from 'classnames';
import RealLogo from '../assets/img/splash.gif';

export interface DefaultSplashProps {
  noPadding?: boolean;
  iconSize?: 'small' | 'large' | 'default';
}

const DefaultSplash: React.FC<DefaultSplashProps> = ({ noPadding }) => {
  return (
    <div
      className={classNames('w-full h-full flex items-center justify-center', {
        'p-5': !noPadding,
      })}
      title='spinning-loader'
    >
      <img src={RealLogo} alt='' />
    </div>
  );
};

export default DefaultSplash;
