import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { ISelectOption } from '../../types';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

export interface SelectImageOption extends ISelectOption {
  image?: string;
}

export interface ControlledSingleSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  options: SelectImageOption[];
  label?: string;
}

const ControlledSingleSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  options,
  shouldUnregister = true,
  ...controllerProps
}: ControlledSingleSelectInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  return (
    <div>
      {label && <ControlledInputLabelComponent label={label!} />}
      <div className='flex flex-col'>
        {(options || []).map((option, index) => {
          const isSelected = option.value === value;
          const isLastOption = index === options.length - 1;

          return (
            <div
              key={option.label}
              className={classNames(isLastOption ? 'mb-0' : 'mb-5')}
            >
              <button
                onClick={() => {
                  if (!isSelected) {
                    onChange(option.value);
                  }
                }}
                disabled={option.disabled}
                className={classNames([
                  'flex flex-row justify-between items-center rounded-xl w-full px-4 py-3',
                  isSelected
                    ? 'border-2 border-primary-skyblue bg-primary-skyblue bg-opacity-10'
                    : 'bg-transparent border border-silver',
                ])}
                data-testid={`btn-${option.label}`}
              >
                <div className='flex flex-row items-center'>
                  {!!option.image && (
                    <img
                      src={option.image}
                      className='w-6 h-6 mr-3 object-contain'
                      alt='icon'
                      data-testid='select-image'
                    />
                  )}
                  <span>{option.label}</span>
                </div>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  fontSize={24}
                  className={
                    isSelected ? 'text-primary-skyblue' : 'text-silver'
                  }
                  data-testid='select-icon'
                />
              </button>
            </div>
          );
        })}
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledSingleSelectInput;
