interface IConfig {
  debug: boolean;
  gMapsAPIKey: string;
  googleAnalyticsMeasurementId: string;
  apiBasePath: {
    atlantis: string;
    keymaker: string;
    dropbox: string;
    sherlock: string;
    yada: string;
    yenta: string;
  };
  imageBaseUrl: {
    yentaImageBaseUrl: string;
  };
  deployVersion: string;
  bugsnag: {
    apiKey: string;
    releaseStage: string;
    deployVersion: string;
  };
  datadog: {
    applicationId: string;
    clientToken: string;
    deployVersion: string;
    sessionSampleRate: number;
    sessionReplaySampleRate: number;
  };
}

const config: IConfig = {
  debug: import.meta.env.VITE_DEBUG! === 'true',
  gMapsAPIKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY!,
  googleAnalyticsMeasurementId: import.meta.env
    .VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID!,
  apiBasePath: {
    atlantis: import.meta.env.VITE_ATLANTIS_API_BASE!,
    keymaker: import.meta.env.VITE_KEYMAKER_API_BASE!,
    dropbox: import.meta.env.VITE_DROPBOX_API_BASE!,
    sherlock: import.meta.env.VITE_SHERLOCK_API_BASE!,
    yada: import.meta.env.VITE_YADA_API_BASE!,
    yenta: import.meta.env.VITE_YENTA_API_BASE!,
  },
  imageBaseUrl: {
    yentaImageBaseUrl: import.meta.env.VITE_YENTA_IMAGE_BASE_URL!,
  },
  bugsnag: {
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY!,
    releaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE!,
    deployVersion: import.meta.env.VITE_DEPLOY_VERSION!,
  },
  datadog: {
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID!,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN!,
    deployVersion: import.meta.env.VITE_DEPLOY_VERSION!,
    sessionSampleRate: parseInt(
      import.meta.env.VITE_DATADOG_SESSION_SAMPLE_RATE!,
      10,
    ),
    sessionReplaySampleRate: parseInt(
      import.meta.env.VITE_DATADOG_SESSION_REPLAY_SAMPLE_RATE!,
      10,
    ),
  },
  deployVersion: import.meta.env.VITE_DEPLOY_VERSION!,
};

export default config;
