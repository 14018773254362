import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { ApplicationProgressResponse } from '../../openapi/atlantis';
import HorizonStepProgress from './HorizontalStepProgress';
import VerticalStepProgress from './VerticalStepProgress';

interface StepProgressContainerProps {
  progress: ApplicationProgressResponse;
  isProgressExpand: boolean;
  setIsProgressExpand: (value: boolean) => void;
  onNavigateToQuestion: (questionId: string) => void;
}

const StepProgressContainer: React.FC<StepProgressContainerProps> = ({
  progress,
  isProgressExpand,
  setIsProgressExpand,
  onNavigateToQuestion,
}) => {
  return (
    <div className='z-50'>
      <div
        className={classNames(
          'fixed lg:absolute bottom-0 bg-primary-navy w-full rounded-t-xl',
          isProgressExpand ? 'hidden' : 'block',
        )}
      >
        <HorizonStepProgress
          progress={progress!}
          onExpand={() => setIsProgressExpand(!isProgressExpand)}
        />
      </div>
      <div
        className={classNames(
          'fixed lg:absolute left-0 top-0 bg-primary-navy w-full h-screen',
          isProgressExpand ? 'block' : 'hidden',
        )}
      >
        <FontAwesomeIcon
          icon={faXmark}
          fontSize={24}
          onClick={() => setIsProgressExpand(false)}
          className='absolute top-5 md:right-10 right-5 text-gray-3 cursor-pointer'
        />
        <VerticalStepProgress
          title='Application Progress'
          progress={progress!}
          onNavigateToQuestion={async (questionId: string) => {
            await onNavigateToQuestion(questionId);

            if (isMobile) {
              setIsProgressExpand(false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default StepProgressContainer;
