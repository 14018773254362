import { FC } from 'react';
import useValidateVisibleCondition from '../../../hooks/useValidateVisibleCondition';
import {
  AnswerApplicationQuestionRequest,
  FieldObject,
} from '../../../openapi/atlantis';
import ConfigDrivenFormInput from './ConfigDrivenFormInput';

interface ConfigDrivenConditionalFormInputProps {
  name?: string;
  checklistItemId: string;
  isSubmitting: boolean;
  field: FieldObject;
  onSubmit: (e: AnswerApplicationQuestionRequest) => void;
}

const ConfigDrivenConditionalFormInput: FC<
  ConfigDrivenConditionalFormInputProps
> = ({ name, checklistItemId, isSubmitting, field, onSubmit }) => {
  const { validateFieldVisibleCondition } = useValidateVisibleCondition();
  const isVisible = validateFieldVisibleCondition(field);

  if (!isVisible) {
    return null;
  }

  return (
    <div className='mb-4'>
      <ConfigDrivenFormInput
        name={name!}
        field={field}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        checklistItemId={checklistItemId}
      />
    </div>
  );
};
export default ConfigDrivenConditionalFormInput;
