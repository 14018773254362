import { themeEnum } from '../types';

const THEME_KEY = 'theme';

const ThemeService = {
  storeTheme: (value: themeEnum) => {
    localStorage.setItem(THEME_KEY, value);
  },
  getTheme: () => {
    return localStorage.getItem(THEME_KEY);
  },
};

export default ThemeService;
