/* tslint:disable */
/* eslint-disable */
/**
 * Keymaker API
 * Keymaker Main Application
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessControlRequest
 */
export interface AccessControlRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessControlRequest
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlRequest
     */
    'resourceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlRequest
     */
    'resourceFilterSetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlRequest
     */
    'operationFilterSetId'?: string;
}
/**
 * 
 * @export
 * @interface AccessControlValue
 */
export interface AccessControlValue {
    /**
     * 
     * @type {string}
     * @memberof AccessControlValue
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlValue
     */
    'description'?: string;
    /**
     * 
     * @type {ResourceTypeValue}
     * @memberof AccessControlValue
     */
    'resourceType'?: ResourceTypeValue;
    /**
     * 
     * @type {ResourceFilterSetValue}
     * @memberof AccessControlValue
     */
    'resourceFilterSet'?: ResourceFilterSetValue;
    /**
     * 
     * @type {OperationFilterSetValue}
     * @memberof AccessControlValue
     */
    'operationFilterSet'?: OperationFilterSetValue;
}
/**
 * 
 * @export
 * @interface ApiKeyResponse
 */
export interface ApiKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyResponse
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyResponse
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiKeyResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiKeyResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiKeyResponse
     */
    'revokedAt'?: number;
}
/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface EnableMfaRequest
 */
export interface EnableMfaRequest {
    /**
     * 
     * @type {number}
     * @memberof EnableMfaRequest
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnableMfaRequest
     */
    'mfaType': EnableMfaRequestMfaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnableMfaRequest
     */
    'phoneNumber'?: string;
}

export const EnableMfaRequestMfaTypeEnum = {
    Authenticator: 'AUTHENTICATOR',
    Sms: 'SMS'
} as const;

export type EnableMfaRequestMfaTypeEnum = typeof EnableMfaRequestMfaTypeEnum[keyof typeof EnableMfaRequestMfaTypeEnum];

/**
 * 
 * @export
 * @interface GenerateApiKeyRequest
 */
export interface GenerateApiKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateApiKeyRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateApiKeyRequest
     */
    'expirationDate': string;
}
/**
 * 
 * @export
 * @interface GoogleIdentityResponse
 */
export interface GoogleIdentityResponse {
    /**
     * 
     * @type {string}
     * @memberof GoogleIdentityResponse
     */
    'googleId': string;
    /**
     * 
     * @type {string}
     * @memberof GoogleIdentityResponse
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GoogleIdentityResponse
     */
    'emailVerified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GoogleIdentityResponse
     */
    'lastLoggedInAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof GoogleIdentityResponse
     */
    'loginCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleIdentityResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleIdentityResponse
     */
    'pictureUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleIdentityResponse
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleIdentityResponse
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleIdentityResponse
     */
    'givenName'?: string;
}
/**
 * 
 * @export
 * @interface GoogleSsoRequest
 */
export interface GoogleSsoRequest {
    /**
     * 
     * @type {string}
     * @memberof GoogleSsoRequest
     */
    'googleToken': string;
}
/**
 * 
 * @export
 * @interface IdentityAvailability
 */
export interface IdentityAvailability {
    /**
     * 
     * @type {boolean}
     * @memberof IdentityAvailability
     */
    'available'?: boolean;
}
/**
 * 
 * @export
 * @interface IdentityRoleResponse
 */
export interface IdentityRoleResponse {
    /**
     * 
     * @type {string}
     * @memberof IdentityRoleResponse
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityRoleResponse
     */
    'grantedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof IdentityRoleResponse
     */
    'grantedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdentityRoleResponse
     */
    'revokedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof IdentityRoleResponse
     */
    'revokedAt'?: number;
}
/**
 * 
 * @export
 * @interface IdentitySummaryResponse
 */
export interface IdentitySummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof IdentitySummaryResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof IdentitySummaryResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {Array<IdentityRoleResponse>}
     * @memberof IdentitySummaryResponse
     */
    'activeRoles'?: Array<IdentityRoleResponse>;
    /**
     * 
     * @type {Array<IdentityRoleResponse>}
     * @memberof IdentitySummaryResponse
     */
    'revokedRoles'?: Array<IdentityRoleResponse>;
    /**
     * 
     * @type {string}
     * @memberof IdentitySummaryResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentitySummaryResponse
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof IdentitySummaryResponse
     */
    'loginCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IdentitySummaryResponse
     */
    'lastLoggedInAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdentitySummaryResponse
     */
    'mfaType'?: IdentitySummaryResponseMfaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentitySummaryResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdentitySummaryResponse
     */
    'forceMfa'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IdentitySummaryResponse
     */
    'errorMessage'?: string;
}

export const IdentitySummaryResponseMfaTypeEnum = {
    Authenticator: 'AUTHENTICATOR',
    Sms: 'SMS'
} as const;

export type IdentitySummaryResponseMfaTypeEnum = typeof IdentitySummaryResponseMfaTypeEnum[keyof typeof IdentitySummaryResponseMfaTypeEnum];

/**
 * 
 * @export
 * @interface JwtAuthenticationResponse
 */
export interface JwtAuthenticationResponse {
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    'tokenType'?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    'mfaType'?: JwtAuthenticationResponseMfaTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JwtAuthenticationResponse
     */
    'forceMfa'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    'phoneNumber'?: string;
}

export const JwtAuthenticationResponseMfaTypeEnum = {
    Authenticator: 'AUTHENTICATOR',
    Sms: 'SMS'
} as const;

export type JwtAuthenticationResponseMfaTypeEnum = typeof JwtAuthenticationResponseMfaTypeEnum[keyof typeof JwtAuthenticationResponseMfaTypeEnum];

/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'usernameOrEmail': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface MakeAdministratorRequest
 */
export interface MakeAdministratorRequest {
    /**
     * 
     * @type {string}
     * @memberof MakeAdministratorRequest
     */
    'usernameOrEmail': string;
}
/**
 * 
 * @export
 * @interface MfaVerificationRequest
 */
export interface MfaVerificationRequest {
    /**
     * 
     * @type {number}
     * @memberof MfaVerificationRequest
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface OperationFilterSetValue
 */
export interface OperationFilterSetValue {
    /**
     * 
     * @type {Array<OperationFilterValue>}
     * @memberof OperationFilterSetValue
     */
    'operationFilters'?: Array<OperationFilterValue>;
    /**
     * 
     * @type {string}
     * @memberof OperationFilterSetValue
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperationFilterSetValue
     */
    'resourceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperationFilterSetValue
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface OperationFilterValue
 */
export interface OperationFilterValue {
    /**
     * 
     * @type {string}
     * @memberof OperationFilterValue
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperationFilterValue
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperationFilterValue
     */
    'type'?: OperationFilterValueTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OperationFilterValue
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperationFilterValue
     */
    'methods'?: string;
}

export const OperationFilterValueTypeEnum = {
    Include: 'INCLUDE',
    Exclude: 'EXCLUDE'
} as const;

export type OperationFilterValueTypeEnum = typeof OperationFilterValueTypeEnum[keyof typeof OperationFilterValueTypeEnum];

/**
 * 
 * @export
 * @interface PasswordUpdateRequest
 */
export interface PasswordUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdateRequest
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdateRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface PathValue
 */
export interface PathValue {
    /**
     * 
     * @type {string}
     * @memberof PathValue
     */
    'pattern'?: string;
    /**
     * 
     * @type {string}
     * @memberof PathValue
     */
    'subDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof PathValue
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ResetEmailRequest
 */
export interface ResetEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetEmailRequest
     */
    'oldEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ResetEmailRequest
     */
    'newEmail': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'usernameOrEmail': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordResponse
     */
    'expiryDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ResourceFilterSetValue
 */
export interface ResourceFilterSetValue {
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterSetValue
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterSetValue
     */
    'resourceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterSetValue
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ResourceFilterValue>}
     * @memberof ResourceFilterSetValue
     */
    'resourceFilters'?: Array<ResourceFilterValue>;
}
/**
 * 
 * @export
 * @interface ResourceFilterValue
 */
export interface ResourceFilterValue {
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterValue
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterValue
     */
    'type'?: ResourceFilterValueTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterValue
     */
    'attributeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterValue
     */
    'attributeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterValue
     */
    'comparisonType'?: ResourceFilterValueComparisonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterValue
     */
    'relationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceFilterValue
     */
    'description'?: string;
}

export const ResourceFilterValueTypeEnum = {
    Include: 'INCLUDE',
    Exclude: 'EXCLUDE'
} as const;

export type ResourceFilterValueTypeEnum = typeof ResourceFilterValueTypeEnum[keyof typeof ResourceFilterValueTypeEnum];
export const ResourceFilterValueComparisonTypeEnum = {
    Equal: 'EQUAL',
    GreaterThan: 'GREATER_THAN',
    LessThan: 'LESS_THAN',
    Match: 'MATCH'
} as const;

export type ResourceFilterValueComparisonTypeEnum = typeof ResourceFilterValueComparisonTypeEnum[keyof typeof ResourceFilterValueComparisonTypeEnum];

/**
 * 
 * @export
 * @interface ResourceTypeValue
 */
export interface ResourceTypeValue {
    /**
     * 
     * @type {string}
     * @memberof ResourceTypeValue
     */
    'type'?: string;
    /**
     * 
     * @type {Array<PathValue>}
     * @memberof ResourceTypeValue
     */
    'paths'?: Array<PathValue>;
    /**
     * 
     * @type {string}
     * @memberof ResourceTypeValue
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface RevokeApiKeyRequest
 */
export interface RevokeApiKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof RevokeApiKeyRequest
     */
    'apiKey': string;
}
/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse {
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'authUserType'?: SignUpRequestAuthUserTypeEnum;
}

export const SignUpRequestAuthUserTypeEnum = {
    Agent: 'AGENT',
    Borrower: 'BORROWER',
    LoanOfficer: 'LOAN_OFFICER',
    LoanProcessor: 'LOAN_PROCESSOR',
    RealOriginateLoanOfficer: 'REAL_ORIGINATE_LOAN_OFFICER'
} as const;

export type SignUpRequestAuthUserTypeEnum = typeof SignUpRequestAuthUserTypeEnum[keyof typeof SignUpRequestAuthUserTypeEnum];

/**
 * 
 * @export
 * @interface UpdateEmailRequest
 */
export interface UpdateEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailRequest
     */
    'newEmail': string;
}
/**
 * 
 * @export
 * @interface UpdateExistingPasswordRequest
 */
export interface UpdateExistingPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateExistingPasswordRequest
     */
    'jwtToken': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExistingPasswordRequest
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExistingPasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface UserPrincipal
 */
export interface UserPrincipal {
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'yentaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'username'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPrincipal
     */
    'roles'?: Array<UserPrincipalRolesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPrincipal
     */
    'authRoles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'email'?: string;
}

export const UserPrincipalRolesEnum = {
    User: 'ROLE_USER',
    Admin: 'ROLE_ADMIN'
} as const;

export type UserPrincipalRolesEnum = typeof UserPrincipalRolesEnum[keyof typeof UserPrincipalRolesEnum];


/**
 * ApiKeyControllerApi - axios parameter creator
 * @export
 */
export const ApiKeyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate API key
         * @param {GenerateApiKeyRequest} generateApiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateApiKey: async (generateApiKeyRequest: GenerateApiKeyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateApiKeyRequest' is not null or undefined
            assertParamExists('generateApiKey', 'generateApiKeyRequest', generateApiKeyRequest)
            const localVarPath = `/api/v1/api-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateApiKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all API keys for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/api-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revoke API key
         * @param {RevokeApiKeyRequest} revokeApiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeApiKey: async (revokeApiKeyRequest: RevokeApiKeyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'revokeApiKeyRequest' is not null or undefined
            assertParamExists('revokeApiKey', 'revokeApiKeyRequest', revokeApiKeyRequest)
            const localVarPath = `/api/v1/api-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeApiKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiKeyControllerApi - functional programming interface
 * @export
 */
export const ApiKeyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiKeyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate API key
         * @param {GenerateApiKeyRequest} generateApiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateApiKey(generateApiKeyRequest: GenerateApiKeyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateApiKey(generateApiKeyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all API keys for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiKeys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKeyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Revoke API key
         * @param {RevokeApiKeyRequest} revokeApiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeApiKey(revokeApiKeyRequest: RevokeApiKeyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeApiKey(revokeApiKeyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiKeyControllerApi - factory interface
 * @export
 */
export const ApiKeyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiKeyControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate API key
         * @param {GenerateApiKeyRequest} generateApiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateApiKey(generateApiKeyRequest: GenerateApiKeyRequest, options?: any): AxiosPromise<ApiKeyResponse> {
            return localVarFp.generateApiKey(generateApiKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all API keys for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys(options?: any): AxiosPromise<Array<ApiKeyResponse>> {
            return localVarFp.getApiKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revoke API key
         * @param {RevokeApiKeyRequest} revokeApiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeApiKey(revokeApiKeyRequest: RevokeApiKeyRequest, options?: any): AxiosPromise<ApiKeyResponse> {
            return localVarFp.revokeApiKey(revokeApiKeyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiKeyControllerApi - object-oriented interface
 * @export
 * @class ApiKeyControllerApi
 * @extends {BaseAPI}
 */
export class ApiKeyControllerApi extends BaseAPI {
    /**
     * 
     * @summary Generate API key
     * @param {GenerateApiKeyRequest} generateApiKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyControllerApi
     */
    public generateApiKey(generateApiKeyRequest: GenerateApiKeyRequest, options?: AxiosRequestConfig) {
        return ApiKeyControllerApiFp(this.configuration).generateApiKey(generateApiKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all API keys for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyControllerApi
     */
    public getApiKeys(options?: AxiosRequestConfig) {
        return ApiKeyControllerApiFp(this.configuration).getApiKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Revoke API key
     * @param {RevokeApiKeyRequest} revokeApiKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyControllerApi
     */
    public revokeApiKey(revokeApiKeyRequest: RevokeApiKeyRequest, options?: AxiosRequestConfig) {
        return ApiKeyControllerApiFp(this.configuration).revokeApiKey(revokeApiKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login
         * @param {LoginRequest} loginRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser: async (loginRequest: LoginRequest, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('authenticateUser', 'loginRequest', loginRequest)
            const localVarPath = `/api/v1/auth/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xRealAppName != null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login by Google SSO
         * @param {GoogleSsoRequest} googleSsoRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser1: async (googleSsoRequest: GoogleSsoRequest, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleSsoRequest' is not null or undefined
            assertParamExists('authenticateUser1', 'googleSsoRequest', googleSsoRequest)
            const localVarPath = `/api/v1/auth/signin-by-google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xRealAppName != null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleSsoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change Password
         * @param {PasswordUpdateRequest} passwordUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (passwordUpdateRequest: PasswordUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordUpdateRequest' is not null or undefined
            assertParamExists('changePassword', 'passwordUpdateRequest', passwordUpdateRequest)
            const localVarPath = `/api/v1/auth/changepassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailAvailability: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('checkEmailAvailability', 'email', email)
            const localVarPath = `/api/v1/auth/checkemailavailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if username is available
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUsernameAvailability: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('checkUsernameAvailability', 'username', username)
            const localVarPath = `/api/v1/auth/checkusernameavailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        forgotpassword: async (resetPasswordRequest: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('forgotpassword', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/api/v1/auth/forgotpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all username for the given ids
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsernames: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getUsernames', 'ids', ids)
            const localVarPath = `/api/v1/auth/usernames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = Array.from(ids);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ResetPassword
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/api/v1/auth/resetpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign Out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/signout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up/Register a new user
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp: async (signUpRequest: SignUpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpRequest' is not null or undefined
            assertParamExists('signUp', 'signUpRequest', signUpRequest)
            const localVarPath = `/api/v1/auth/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Existing Password
         * @param {UpdateExistingPasswordRequest} updateExistingPasswordRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (updateExistingPasswordRequest: UpdateExistingPasswordRequest, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateExistingPasswordRequest' is not null or undefined
            assertParamExists('updatePassword', 'updateExistingPasswordRequest', updateExistingPasswordRequest)
            const localVarPath = `/api/v1/auth/updatepassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRealAppName != null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExistingPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Auth Exchange with Auth Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('verifyToken', 'token', token)
            const localVarPath = `/api/v1/auth/verify/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginRequest} loginRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUser(loginRequest: LoginRequest, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateUser(loginRequest, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login by Google SSO
         * @param {GoogleSsoRequest} googleSsoRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUser1(googleSsoRequest: GoogleSsoRequest, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateUser1(googleSsoRequest, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change Password
         * @param {PasswordUpdateRequest} passwordUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(passwordUpdateRequest: PasswordUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(passwordUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEmailAvailability(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEmailAvailability(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if username is available
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUsernameAvailability(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUsernameAvailability(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async forgotpassword(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotpassword(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all username for the given ids
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsernames(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsernames(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ResetPassword
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign Out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signOut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signOut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign up/Register a new user
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp(signUpRequest: SignUpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUp(signUpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Existing Password
         * @param {UpdateExistingPasswordRequest} updateExistingPasswordRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(updateExistingPasswordRequest: UpdateExistingPasswordRequest, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(updateExistingPasswordRequest, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Auth Exchange with Auth Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrincipal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginRequest} loginRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser(loginRequest: LoginRequest, xRealAppName?: string, options?: any): AxiosPromise<JwtAuthenticationResponse> {
            return localVarFp.authenticateUser(loginRequest, xRealAppName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login by Google SSO
         * @param {GoogleSsoRequest} googleSsoRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser1(googleSsoRequest: GoogleSsoRequest, xRealAppName?: string, options?: any): AxiosPromise<JwtAuthenticationResponse> {
            return localVarFp.authenticateUser1(googleSsoRequest, xRealAppName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change Password
         * @param {PasswordUpdateRequest} passwordUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(passwordUpdateRequest: PasswordUpdateRequest, options?: any): AxiosPromise<any> {
            return localVarFp.changePassword(passwordUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailAvailability(email: string, options?: any): AxiosPromise<IdentityAvailability> {
            return localVarFp.checkEmailAvailability(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if username is available
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUsernameAvailability(username: string, options?: any): AxiosPromise<IdentityAvailability> {
            return localVarFp.checkUsernameAvailability(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        forgotpassword(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<any> {
            return localVarFp.forgotpassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all username for the given ids
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsernames(ids: Array<string>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getUsernames(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ResetPassword
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<ResetPasswordResponse> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign Out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOut(options?: any): AxiosPromise<void> {
            return localVarFp.signOut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up/Register a new user
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(signUpRequest: SignUpRequest, options?: any): AxiosPromise<IdentitySummaryResponse> {
            return localVarFp.signUp(signUpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Existing Password
         * @param {UpdateExistingPasswordRequest} updateExistingPasswordRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(updateExistingPasswordRequest: UpdateExistingPasswordRequest, xRealAppName?: string, options?: any): AxiosPromise<any> {
            return localVarFp.updatePassword(updateExistingPasswordRequest, xRealAppName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Auth Exchange with Auth Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(token: string, options?: any): AxiosPromise<UserPrincipal> {
            return localVarFp.verifyToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @summary Login
     * @param {LoginRequest} loginRequest 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateUser(loginRequest: LoginRequest, xRealAppName?: string, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authenticateUser(loginRequest, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login by Google SSO
     * @param {GoogleSsoRequest} googleSsoRequest 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateUser1(googleSsoRequest: GoogleSsoRequest, xRealAppName?: string, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authenticateUser1(googleSsoRequest, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change Password
     * @param {PasswordUpdateRequest} passwordUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public changePassword(passwordUpdateRequest: PasswordUpdateRequest, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).changePassword(passwordUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if email is available
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public checkEmailAvailability(email: string, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).checkEmailAvailability(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if username is available
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public checkUsernameAvailability(username: string, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).checkUsernameAvailability(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ForgotPassword
     * @param {ResetPasswordRequest} resetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public forgotpassword(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).forgotpassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all username for the given ids
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public getUsernames(ids: Array<string>, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).getUsernames(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ResetPassword
     * @param {ResetPasswordRequest} resetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).resetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign Out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public signOut(options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).signOut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up/Register a new user
     * @param {SignUpRequest} signUpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public signUp(signUpRequest: SignUpRequest, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).signUp(signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Existing Password
     * @param {UpdateExistingPasswordRequest} updateExistingPasswordRequest 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public updatePassword(updateExistingPasswordRequest: UpdateExistingPasswordRequest, xRealAppName?: string, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).updatePassword(updateExistingPasswordRequest, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Auth Exchange with Auth Token
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public verifyToken(token: string, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).verifyToken(token, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthorizationControllerApi - axios parameter creator
 * @export
 */
export const AuthorizationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccessControlRequest} accessControlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessControl: async (accessControlRequest: AccessControlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessControlRequest' is not null or undefined
            assertParamExists('createAccessControl', 'accessControlRequest', accessControlRequest)
            const localVarPath = `/api/v1/authorization/access-controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessControlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OperationFilterSetValue} operationFilterSetValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperationFilterSet: async (operationFilterSetValue: OperationFilterSetValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operationFilterSetValue' is not null or undefined
            assertParamExists('createOperationFilterSet', 'operationFilterSetValue', operationFilterSetValue)
            const localVarPath = `/api/v1/authorization/operation-filter-sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operationFilterSetValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceFilterSetValue} resourceFilterSetValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceFilterSet: async (resourceFilterSetValue: ResourceFilterSetValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceFilterSetValue' is not null or undefined
            assertParamExists('createResourceFilterSet', 'resourceFilterSetValue', resourceFilterSetValue)
            const localVarPath = `/api/v1/authorization/resource-filter-sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceFilterSetValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceTypeValue} resourceTypeValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceType: async (resourceTypeValue: ResourceTypeValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceTypeValue' is not null or undefined
            assertParamExists('createResourceType', 'resourceTypeValue', resourceTypeValue)
            const localVarPath = `/api/v1/authorization/resource-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceTypeValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [roles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessControls: async (roles?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authorization/access-controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationControllerApi - functional programming interface
 * @export
 */
export const AuthorizationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AccessControlRequest} accessControlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessControl(accessControlRequest: AccessControlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessControlValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessControl(accessControlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OperationFilterSetValue} operationFilterSetValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOperationFilterSet(operationFilterSetValue: OperationFilterSetValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationFilterSetValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOperationFilterSet(operationFilterSetValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResourceFilterSetValue} resourceFilterSetValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResourceFilterSet(resourceFilterSetValue: ResourceFilterSetValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceFilterSetValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResourceFilterSet(resourceFilterSetValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResourceTypeValue} resourceTypeValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResourceType(resourceTypeValue: ResourceTypeValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResourceType(resourceTypeValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [roles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessControls(roles?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessControlValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessControls(roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorizationControllerApi - factory interface
 * @export
 */
export const AuthorizationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AccessControlRequest} accessControlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessControl(accessControlRequest: AccessControlRequest, options?: any): AxiosPromise<AccessControlValue> {
            return localVarFp.createAccessControl(accessControlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OperationFilterSetValue} operationFilterSetValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperationFilterSet(operationFilterSetValue: OperationFilterSetValue, options?: any): AxiosPromise<OperationFilterSetValue> {
            return localVarFp.createOperationFilterSet(operationFilterSetValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResourceFilterSetValue} resourceFilterSetValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceFilterSet(resourceFilterSetValue: ResourceFilterSetValue, options?: any): AxiosPromise<ResourceFilterSetValue> {
            return localVarFp.createResourceFilterSet(resourceFilterSetValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResourceTypeValue} resourceTypeValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceType(resourceTypeValue: ResourceTypeValue, options?: any): AxiosPromise<void> {
            return localVarFp.createResourceType(resourceTypeValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [roles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessControls(roles?: Array<string>, options?: any): AxiosPromise<Array<AccessControlValue>> {
            return localVarFp.getAccessControls(roles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationControllerApi - object-oriented interface
 * @export
 * @class AuthorizationControllerApi
 * @extends {BaseAPI}
 */
export class AuthorizationControllerApi extends BaseAPI {
    /**
     * 
     * @param {AccessControlRequest} accessControlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationControllerApi
     */
    public createAccessControl(accessControlRequest: AccessControlRequest, options?: AxiosRequestConfig) {
        return AuthorizationControllerApiFp(this.configuration).createAccessControl(accessControlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OperationFilterSetValue} operationFilterSetValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationControllerApi
     */
    public createOperationFilterSet(operationFilterSetValue: OperationFilterSetValue, options?: AxiosRequestConfig) {
        return AuthorizationControllerApiFp(this.configuration).createOperationFilterSet(operationFilterSetValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResourceFilterSetValue} resourceFilterSetValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationControllerApi
     */
    public createResourceFilterSet(resourceFilterSetValue: ResourceFilterSetValue, options?: AxiosRequestConfig) {
        return AuthorizationControllerApiFp(this.configuration).createResourceFilterSet(resourceFilterSetValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResourceTypeValue} resourceTypeValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationControllerApi
     */
    public createResourceType(resourceTypeValue: ResourceTypeValue, options?: AxiosRequestConfig) {
        return AuthorizationControllerApiFp(this.configuration).createResourceType(resourceTypeValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [roles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationControllerApi
     */
    public getAccessControls(roles?: Array<string>, options?: AxiosRequestConfig) {
        return AuthorizationControllerApiFp(this.configuration).getAccessControls(roles, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KeyMakerControllerApi - axios parameter creator
 * @export
 */
export const KeyMakerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiCall: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keymaker/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keymaker/myself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        protectedApiCall: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keymaker/protected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicApiCall: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keymaker/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeyMakerControllerApi - functional programming interface
 * @export
 */
export const KeyMakerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeyMakerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiCall(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiCall(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async protectedApiCall(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.protectedApiCall(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicApiCall(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicApiCall(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KeyMakerControllerApi - factory interface
 * @export
 */
export const KeyMakerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeyMakerControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiCall(options?: any): AxiosPromise<string> {
            return localVarFp.adminApiCall(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<IdentitySummaryResponse> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        protectedApiCall(options?: any): AxiosPromise<string> {
            return localVarFp.protectedApiCall(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicApiCall(options?: any): AxiosPromise<string> {
            return localVarFp.publicApiCall(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeyMakerControllerApi - object-oriented interface
 * @export
 * @class KeyMakerControllerApi
 * @extends {BaseAPI}
 */
export class KeyMakerControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyMakerControllerApi
     */
    public adminApiCall(options?: AxiosRequestConfig) {
        return KeyMakerControllerApiFp(this.configuration).adminApiCall(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyMakerControllerApi
     */
    public getCurrentUser(options?: AxiosRequestConfig) {
        return KeyMakerControllerApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyMakerControllerApi
     */
    public protectedApiCall(options?: AxiosRequestConfig) {
        return KeyMakerControllerApiFp(this.configuration).protectedApiCall(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyMakerControllerApi
     */
    public publicApiCall(options?: AxiosRequestConfig) {
        return KeyMakerControllerApiFp(this.configuration).publicApiCall(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MfaControllerApi - axios parameter creator
 * @export
 */
export const MfaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Disable/Delete multi factor authentication for self
         * @param {MfaVerificationRequest} mfaVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMfa: async (mfaVerificationRequest: MfaVerificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mfaVerificationRequest' is not null or undefined
            assertParamExists('deleteMfa', 'mfaVerificationRequest', mfaVerificationRequest)
            const localVarPath = `/api/v1/mfa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mfaVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multi factor authentication for any user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMfaByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteMfaByUserId', 'userId', userId)
            const localVarPath = `/api/v1/mfa/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable multi factor authentication
         * @param {EnableMfaRequest} enableMfaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableMfa: async (enableMfaRequest: EnableMfaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enableMfaRequest' is not null or undefined
            assertParamExists('enableMfa', 'enableMfaRequest', enableMfaRequest)
            const localVarPath = `/api/v1/mfa/enable-mfa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableMfaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable multi factor authentication and login
         * @param {EnableMfaRequest} enableMfaRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableMfaAndSignIn: async (enableMfaRequest: EnableMfaRequest, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enableMfaRequest' is not null or undefined
            assertParamExists('enableMfaAndSignIn', 'enableMfaRequest', enableMfaRequest)
            const localVarPath = `/api/v1/mfa/enable-mfa-and-signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRealAppName != null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableMfaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Authenticator QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatorQrCode: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mfa/mfa-qr-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send multi factor authentication code to phone
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMfaSms: async (phoneNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mfa/send-mfa-sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with multi factor authentication code
         * @param {MfaVerificationRequest} mfaVerificationRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInWithMfa: async (mfaVerificationRequest: MfaVerificationRequest, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mfaVerificationRequest' is not null or undefined
            assertParamExists('signInWithMfa', 'mfaVerificationRequest', mfaVerificationRequest)
            const localVarPath = `/api/v1/mfa/signin-with-mfa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRealAppName != null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mfaVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MfaControllerApi - functional programming interface
 * @export
 */
export const MfaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MfaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Disable/Delete multi factor authentication for self
         * @param {MfaVerificationRequest} mfaVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMfa(mfaVerificationRequest: MfaVerificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMfa(mfaVerificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete multi factor authentication for any user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMfaByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMfaByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable multi factor authentication
         * @param {EnableMfaRequest} enableMfaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableMfa(enableMfaRequest: EnableMfaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableMfa(enableMfaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable multi factor authentication and login
         * @param {EnableMfaRequest} enableMfaRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableMfaAndSignIn(enableMfaRequest: EnableMfaRequest, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableMfaAndSignIn(enableMfaRequest, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Authenticator QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthenticatorQrCode(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthenticatorQrCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send multi factor authentication code to phone
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMfaSms(phoneNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMfaSms(phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with multi factor authentication code
         * @param {MfaVerificationRequest} mfaVerificationRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInWithMfa(mfaVerificationRequest: MfaVerificationRequest, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInWithMfa(mfaVerificationRequest, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MfaControllerApi - factory interface
 * @export
 */
export const MfaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MfaControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Disable/Delete multi factor authentication for self
         * @param {MfaVerificationRequest} mfaVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMfa(mfaVerificationRequest: MfaVerificationRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.deleteMfa(mfaVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multi factor authentication for any user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMfaByUserId(userId: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.deleteMfaByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable multi factor authentication
         * @param {EnableMfaRequest} enableMfaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableMfa(enableMfaRequest: EnableMfaRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.enableMfa(enableMfaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable multi factor authentication and login
         * @param {EnableMfaRequest} enableMfaRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableMfaAndSignIn(enableMfaRequest: EnableMfaRequest, xRealAppName?: string, options?: any): AxiosPromise<JwtAuthenticationResponse> {
            return localVarFp.enableMfaAndSignIn(enableMfaRequest, xRealAppName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Authenticator QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatorQrCode(options?: any): AxiosPromise<string> {
            return localVarFp.getAuthenticatorQrCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send multi factor authentication code to phone
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMfaSms(phoneNumber?: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.sendMfaSms(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with multi factor authentication code
         * @param {MfaVerificationRequest} mfaVerificationRequest 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInWithMfa(mfaVerificationRequest: MfaVerificationRequest, xRealAppName?: string, options?: any): AxiosPromise<JwtAuthenticationResponse> {
            return localVarFp.signInWithMfa(mfaVerificationRequest, xRealAppName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MfaControllerApi - object-oriented interface
 * @export
 * @class MfaControllerApi
 * @extends {BaseAPI}
 */
export class MfaControllerApi extends BaseAPI {
    /**
     * 
     * @summary Disable/Delete multi factor authentication for self
     * @param {MfaVerificationRequest} mfaVerificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaControllerApi
     */
    public deleteMfa(mfaVerificationRequest: MfaVerificationRequest, options?: AxiosRequestConfig) {
        return MfaControllerApiFp(this.configuration).deleteMfa(mfaVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multi factor authentication for any user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaControllerApi
     */
    public deleteMfaByUserId(userId: string, options?: AxiosRequestConfig) {
        return MfaControllerApiFp(this.configuration).deleteMfaByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable multi factor authentication
     * @param {EnableMfaRequest} enableMfaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaControllerApi
     */
    public enableMfa(enableMfaRequest: EnableMfaRequest, options?: AxiosRequestConfig) {
        return MfaControllerApiFp(this.configuration).enableMfa(enableMfaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable multi factor authentication and login
     * @param {EnableMfaRequest} enableMfaRequest 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaControllerApi
     */
    public enableMfaAndSignIn(enableMfaRequest: EnableMfaRequest, xRealAppName?: string, options?: AxiosRequestConfig) {
        return MfaControllerApiFp(this.configuration).enableMfaAndSignIn(enableMfaRequest, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Authenticator QR code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaControllerApi
     */
    public getAuthenticatorQrCode(options?: AxiosRequestConfig) {
        return MfaControllerApiFp(this.configuration).getAuthenticatorQrCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send multi factor authentication code to phone
     * @param {string} [phoneNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaControllerApi
     */
    public sendMfaSms(phoneNumber?: string, options?: AxiosRequestConfig) {
        return MfaControllerApiFp(this.configuration).sendMfaSms(phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with multi factor authentication code
     * @param {MfaVerificationRequest} mfaVerificationRequest 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaControllerApi
     */
    public signInWithMfa(mfaVerificationRequest: MfaVerificationRequest, xRealAppName?: string, options?: AxiosRequestConfig) {
        return MfaControllerApiFp(this.configuration).signInWithMfa(mfaVerificationRequest, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleControllerApi - axios parameter creator
 * @export
 */
export const RoleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all grantor roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGrantorRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/roles/type/grantor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available roles for granting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleControllerApi - functional programming interface
 * @export
 */
export const RoleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all grantor roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllGrantorRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGrantorRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available roles for granting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleControllerApi - factory interface
 * @export
 */
export const RoleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all grantor roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGrantorRoles(options?: any): AxiosPromise<Array<RoleResponse>> {
            return localVarFp.getAllGrantorRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available roles for granting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<Array<RoleResponse>> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleControllerApi - object-oriented interface
 * @export
 * @class RoleControllerApi
 * @extends {BaseAPI}
 */
export class RoleControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get all grantor roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public getAllGrantorRoles(options?: AxiosRequestConfig) {
        return RoleControllerApiFp(this.configuration).getAllGrantorRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available roles for granting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return RoleControllerApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Link Google SSO to user
         * @param {string} userId 
         * @param {GoogleSsoRequest} googleSsoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleSsoLinkByUserId: async (userId: string, googleSsoRequest: GoogleSsoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createGoogleSsoLinkByUserId', 'userId', userId)
            // verify required parameter 'googleSsoRequest' is not null or undefined
            assertParamExists('createGoogleSsoLinkByUserId', 'googleSsoRequest', googleSsoRequest)
            const localVarPath = `/api/v1/users/{userId}/google-identity/link`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleSsoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a given user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set user record to enforce GoogleSSO
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceGooglesSso: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forceGooglesSso', 'id', id)
            const localVarPath = `/api/v1/users/{id}/force-google-sso`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set user record to enforce MFA
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceMfa: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forceMfa', 'id', id)
            const localVarPath = `/api/v1/users/{id}/force-mfa`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all linked Google SSO to user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGoogleSsoLinksByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllGoogleSsoLinksByUserId', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/google-identity/all-linked`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all internal users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInternalUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/internal-users/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s login information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentity: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIdentity', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s identity information by username or email
         * @param {string} usernameOrEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentityByUsernameOrEmail: async (usernameOrEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usernameOrEmail' is not null or undefined
            assertParamExists('getIdentityByUsernameOrEmail', 'usernameOrEmail', usernameOrEmail)
            const localVarPath = `/api/v1/users/usernameOrEmail/{usernameOrEmail}`
                .replace(`{${"usernameOrEmail"}}`, encodeURIComponent(String(usernameOrEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Grant specified role to a given user
         * @param {string} id 
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantRole: async (id: string, role: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('grantRole', 'id', id)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('grantRole', 'role', role)
            const localVarPath = `/api/v1/users/{id}/roles/{role}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Promote user to administrator
         * @param {MakeAdministratorRequest} makeAdministratorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeAdministrator: async (makeAdministratorRequest: MakeAdministratorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'makeAdministratorRequest' is not null or undefined
            assertParamExists('makeAdministrator', 'makeAdministratorRequest', makeAdministratorRequest)
            const localVarPath = `/api/v1/users/make-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(makeAdministratorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Link Google SSO to user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAllGoogleSsoLinksByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeAllGoogleSsoLinksByUserId', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/google-identity/all-linked`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set user record to remove enforcing GooglesSSO
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeForceGooglesSso: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeForceGooglesSso', 'id', id)
            const localVarPath = `/api/v1/users/{id}/force-google-sso`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set user record to remove enforcing MFA
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeForceMfa: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeForceMfa', 'id', id)
            const localVarPath = `/api/v1/users/{id}/remove-force-mfa`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Link Google SSO to user
         * @param {string} userId 
         * @param {string} googleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGoogleSsoLinkById: async (userId: string, googleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeGoogleSsoLinkById', 'userId', userId)
            // verify required parameter 'googleId' is not null or undefined
            assertParamExists('removeGoogleSsoLinkById', 'googleId', googleId)
            const localVarPath = `/api/v1/users/{userId}/google-identity/{googleId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"googleId"}}`, encodeURIComponent(String(googleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Promote user to administrator
         * @param {ResetEmailRequest} resetEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetEmail: async (resetEmailRequest: ResetEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetEmailRequest' is not null or undefined
            assertParamExists('resetEmail', 'resetEmailRequest', resetEmailRequest)
            const localVarPath = `/api/v1/users/reset-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revoke specified role for a given user
         * @param {string} id 
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeRole: async (id: string, role: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('revokeRole', 'id', id)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('revokeRole', 'role', role)
            const localVarPath = `/api/v1/users/{id}/roles/{role}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update email
         * @param {UpdateEmailRequest} updateEmailRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateEmail: async (updateEmailRequest: UpdateEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEmailRequest' is not null or undefined
            assertParamExists('updateEmail', 'updateEmailRequest', updateEmailRequest)
            const localVarPath = `/api/v1/users/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update email for a given user
         * @param {string} id 
         * @param {UpdateEmailRequest} updateEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailForUser: async (id: string, updateEmailRequest: UpdateEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmailForUser', 'id', id)
            // verify required parameter 'updateEmailRequest' is not null or undefined
            assertParamExists('updateEmailForUser', 'updateEmailRequest', updateEmailRequest)
            const localVarPath = `/api/v1/users/{id}/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Link Google SSO to user
         * @param {string} userId 
         * @param {GoogleSsoRequest} googleSsoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGoogleSsoLinkByUserId(userId: string, googleSsoRequest: GoogleSsoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGoogleSsoLinkByUserId(userId, googleSsoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a given user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set user record to enforce GoogleSSO
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceGooglesSso(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceGooglesSso(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set user record to enforce MFA
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceMfa(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceMfa(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all linked Google SSO to user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllGoogleSsoLinksByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GoogleIdentityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGoogleSsoLinksByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all internal users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInternalUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentitySummaryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInternalUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user\'s login information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdentity(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdentity(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user\'s identity information by username or email
         * @param {string} usernameOrEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdentityByUsernameOrEmail(usernameOrEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdentityByUsernameOrEmail(usernameOrEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Grant specified role to a given user
         * @param {string} id 
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantRole(id: string, role: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantRole(id, role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Promote user to administrator
         * @param {MakeAdministratorRequest} makeAdministratorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeAdministrator(makeAdministratorRequest: MakeAdministratorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeAdministrator(makeAdministratorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Link Google SSO to user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAllGoogleSsoLinksByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAllGoogleSsoLinksByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set user record to remove enforcing GooglesSSO
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeForceGooglesSso(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeForceGooglesSso(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set user record to remove enforcing MFA
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeForceMfa(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeForceMfa(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Link Google SSO to user
         * @param {string} userId 
         * @param {string} googleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeGoogleSsoLinkById(userId: string, googleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeGoogleSsoLinkById(userId, googleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Promote user to administrator
         * @param {ResetEmailRequest} resetEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetEmail(resetEmailRequest: ResetEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetEmail(resetEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Revoke specified role for a given user
         * @param {string} id 
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeRole(id: string, role: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeRole(id, role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update email
         * @param {UpdateEmailRequest} updateEmailRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateEmail(updateEmailRequest: UpdateEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmail(updateEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update email for a given user
         * @param {string} id 
         * @param {UpdateEmailRequest} updateEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailForUser(id: string, updateEmailRequest: UpdateEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailForUser(id, updateEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Link Google SSO to user
         * @param {string} userId 
         * @param {GoogleSsoRequest} googleSsoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleSsoLinkByUserId(userId: string, googleSsoRequest: GoogleSsoRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createGoogleSsoLinkByUserId(userId, googleSsoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a given user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set user record to enforce GoogleSSO
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceGooglesSso(id: string, options?: any): AxiosPromise<IdentitySummaryResponse> {
            return localVarFp.forceGooglesSso(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set user record to enforce MFA
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceMfa(id: string, options?: any): AxiosPromise<IdentitySummaryResponse> {
            return localVarFp.forceMfa(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all linked Google SSO to user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGoogleSsoLinksByUserId(userId: string, options?: any): AxiosPromise<Array<GoogleIdentityResponse>> {
            return localVarFp.getAllGoogleSsoLinksByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all internal users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInternalUsers(options?: any): AxiosPromise<Array<IdentitySummaryResponse>> {
            return localVarFp.getAllInternalUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s login information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentity(id: string, options?: any): AxiosPromise<IdentitySummaryResponse> {
            return localVarFp.getIdentity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s identity information by username or email
         * @param {string} usernameOrEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentityByUsernameOrEmail(usernameOrEmail: string, options?: any): AxiosPromise<IdentitySummaryResponse> {
            return localVarFp.getIdentityByUsernameOrEmail(usernameOrEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Grant specified role to a given user
         * @param {string} id 
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantRole(id: string, role: string, options?: any): AxiosPromise<void> {
            return localVarFp.grantRole(id, role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Promote user to administrator
         * @param {MakeAdministratorRequest} makeAdministratorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeAdministrator(makeAdministratorRequest: MakeAdministratorRequest, options?: any): AxiosPromise<void> {
            return localVarFp.makeAdministrator(makeAdministratorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Link Google SSO to user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAllGoogleSsoLinksByUserId(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeAllGoogleSsoLinksByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set user record to remove enforcing GooglesSSO
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeForceGooglesSso(id: string, options?: any): AxiosPromise<IdentitySummaryResponse> {
            return localVarFp.removeForceGooglesSso(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set user record to remove enforcing MFA
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeForceMfa(id: string, options?: any): AxiosPromise<IdentitySummaryResponse> {
            return localVarFp.removeForceMfa(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Link Google SSO to user
         * @param {string} userId 
         * @param {string} googleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGoogleSsoLinkById(userId: string, googleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeGoogleSsoLinkById(userId, googleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Promote user to administrator
         * @param {ResetEmailRequest} resetEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetEmail(resetEmailRequest: ResetEmailRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.resetEmail(resetEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revoke specified role for a given user
         * @param {string} id 
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeRole(id: string, role: string, options?: any): AxiosPromise<void> {
            return localVarFp.revokeRole(id, role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update email
         * @param {UpdateEmailRequest} updateEmailRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateEmail(updateEmailRequest: UpdateEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmail(updateEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update email for a given user
         * @param {string} id 
         * @param {UpdateEmailRequest} updateEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailForUser(id: string, updateEmailRequest: UpdateEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmailForUser(id, updateEmailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Link Google SSO to user
     * @param {string} userId 
     * @param {GoogleSsoRequest} googleSsoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createGoogleSsoLinkByUserId(userId: string, googleSsoRequest: GoogleSsoRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).createGoogleSsoLinkByUserId(userId, googleSsoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a given user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteUser(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set user record to enforce GoogleSSO
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public forceGooglesSso(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).forceGooglesSso(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set user record to enforce MFA
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public forceMfa(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).forceMfa(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all linked Google SSO to user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAllGoogleSsoLinksByUserId(userId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getAllGoogleSsoLinksByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all internal users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAllInternalUsers(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getAllInternalUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s login information
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getIdentity(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getIdentity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s identity information by username or email
     * @param {string} usernameOrEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getIdentityByUsernameOrEmail(usernameOrEmail: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getIdentityByUsernameOrEmail(usernameOrEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Grant specified role to a given user
     * @param {string} id 
     * @param {string} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public grantRole(id: string, role: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).grantRole(id, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Promote user to administrator
     * @param {MakeAdministratorRequest} makeAdministratorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public makeAdministrator(makeAdministratorRequest: MakeAdministratorRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).makeAdministrator(makeAdministratorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Link Google SSO to user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public removeAllGoogleSsoLinksByUserId(userId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).removeAllGoogleSsoLinksByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set user record to remove enforcing GooglesSSO
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public removeForceGooglesSso(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).removeForceGooglesSso(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set user record to remove enforcing MFA
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public removeForceMfa(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).removeForceMfa(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Link Google SSO to user
     * @param {string} userId 
     * @param {string} googleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public removeGoogleSsoLinkById(userId: string, googleId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).removeGoogleSsoLinkById(userId, googleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Promote user to administrator
     * @param {ResetEmailRequest} resetEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetEmail(resetEmailRequest: ResetEmailRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).resetEmail(resetEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Revoke specified role for a given user
     * @param {string} id 
     * @param {string} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public revokeRole(id: string, role: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).revokeRole(id, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update email
     * @param {UpdateEmailRequest} updateEmailRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateEmail(updateEmailRequest: UpdateEmailRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateEmail(updateEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update email for a given user
     * @param {string} id 
     * @param {UpdateEmailRequest} updateEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateEmailForUser(id: string, updateEmailRequest: UpdateEmailRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateEmailForUser(id, updateEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


