import { Action, combineReducers } from '@reduxjs/toolkit';
import { RESET_APP } from '../utils/authActions';
import ApplicationSlice from './ApplicationSlice';
import AuthSlice from './AuthSlice';
import DropboxSlice from './DropboxSlice';
import LoanSlice from './LoanSlice';
import ToastNotificationSlice from './ToastNotificationSlice';
import ThemeSlice from './ThemeSlice';

const appReducer = combineReducers({
  auth: AuthSlice,
  loan: LoanSlice,
  application: ApplicationSlice,
  dropbox: DropboxSlice,
  toastNotification: ToastNotificationSlice,
  theme: ThemeSlice,
});

const rootReducer = (state: any, action: Action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
