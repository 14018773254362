import { AxiosError } from 'axios';
import ForceReportingError from '../errors/ForceReportingError.ts';
import {
  ApplicationStep,
  ContentContentTypeEnum,
  Question,
  SectionIntro,
  SectionOutro,
} from '../openapi/atlantis';
import { TextBlock } from '../openapi/yada';
import { ApiErrorResponse } from '../types.ts';

export const isSectionIntro = (
  step: ApplicationStep,
): step is ApplicationStep & { content: SectionIntro } => {
  return step?.content?.contentType === ContentContentTypeEnum.SectionIntro;
};

export const isSectionOutro = (
  step: ApplicationStep,
): step is ApplicationStep & { content: SectionOutro } => {
  return step?.content?.contentType === ContentContentTypeEnum.SectionOutro;
};

export const isQuestion = (
  step: ApplicationStep,
): step is ApplicationStep & { content: Question } => {
  return step?.content?.contentType === ContentContentTypeEnum.Question;
};

export const isTextBlock = (obj: any): obj is TextBlock => obj.type === 'TEXT';

export const isAxiosError = (error: any): error is AxiosError =>
  error.isAxiosError === true;

export const isForceReportingError = (
  error: any,
): error is ForceReportingError => error?.forceReporting === true;

export const isAPICommonsError = (e: any): e is ApiErrorResponse =>
  !!e['com.real.commons.apierror.ApiError'];

export const getBooleanParse = (value: string | boolean): boolean => {
  const lowerCaseValue = value?.toString().toLowerCase();

  if (lowerCaseValue === 'true') {
    return true;
  }

  return false;
};
